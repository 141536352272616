import React from "react";
import Dialog from "Components/Dialog.js";
import Strings from "Resources/Strings.json";

/**
 * Registration error dialog
 *
 * @package HOPS
 * @subpackage Registration
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class RegistrationErrorDialog extends React.Component {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Dialog
				title={this.constructor.title}
				open={this.props.open}
				onClose={this.props.onClose}>
				{this.content}
			</Dialog>
		);
	}


	/**
	 * Content getter.
	 *
	 * @return {String}
	 */
	get content() {
		return (window.navigator.onLine ? this.text : Strings.welcome.error.eOffline);
	}


	/**
	 * Text getter.
	 *
	 * @return {String}
	 */
	get text() {

		/**
		 * Get error messages
		 */
		const {e401i, e404, e404i, e409i, eGeneric} = Strings.welcome.error;
		const e404r = (this.props.individualsMode ? e404i : e404);

		/**
		 * Get the appropriate error message
		 */
		if (this.props.e404) return e404r;
		else if (this.props.e401 && this.props.individualsMode) return e401i;
		else if (this.props.e409 && this.props.individualsMode) return e409i;
		else return eGeneric;

	}


	/**
	 * Title getter.
	 *
	 * @return {String}
	 */
	static get title() {
		if (window.navigator.onLine) {
			return Strings.welcome.error.label;
		}
		else return Strings.welcome.error.eOfflineTitle;
	}

}

export default RegistrationErrorDialog;
