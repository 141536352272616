import React from "react";
import Flags from "Resources/Flags.json";
import Flex from "Components/Flex.js";
import String from "Components/String.js";
import Switch from "Components/Switch.js";
import TextField from "Components/TextField.js";
import dApi from "Dispatchers/Api.js";
import dDark from "Dispatchers/Dark.js";
import dFlag from "Dispatchers/Flag.js";
import dReset from "Dispatchers/Reset.js";
import withFlags from "Hoc/withFlags.js";
import {connect} from "react-redux";

/**
 * F12 controls UI
 * 
 * @package HOPS
 * @subpackage F12
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class F12Controls extends React.PureComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * API URI
			 *
			 * @type {String}
			 */
			api: props.api

		};

	}


	/**
	 * API URI changed.
	 * 
	 * @param {String} api
	 * @return {void}
	 */
	handleApiChange = api => {
		this.setState({api: (api || null)});
	};


	/**
	 * API URI committed.
	 * 
	 * @return {void}
	 */
	handleApiCommit = () => {
		if (this.state.api !== this.props.api) {
			dReset();
			dApi(this.state.api);
		}
	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Flex gap={1.5}>
				<Flex>
					<TextField
						helperText="Note: All changes will trigger an immediate state reset. E.g. https://www.heritage-ops.org.uk."
						label="API URI"
						onBlur={this.handleApiCommit}
						onChange={this.handleApiChange}
						placeholder="(Default)"
						shrink={true}
						value={(this.state.api || "")} />
					<Switch
						checked={this.props.dark}
						label="Dark mode"
						onChange={dDark} />
					{
						Flags.map((flag, key) => (
							<Switch
								checked={this.props.hasFlag(flag.id)}
								key={key}
								label={flag.label}
								onChange={() => dFlag(flag.id)} />
						))
					}
				</Flex>
				<Flex gap={0.5}>
					<String
						color="textSecondary"
						str="Controls on this screen are for developer use only." />
					<String
						color="textSecondary"
						str="Settings displayed here are unsupported and may cause data loss when changed." />
				</Flex>
			</Flex>
		);
	}

}

export default connect(({api, dark}) => ({api, dark}))(withFlags(F12Controls));
