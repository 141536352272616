import React from "react";
import Container from "Components/Container.js";
import String from "Components/String.js";
import {Table, TableBody} from "@material-ui/core";

export default props => (
	<Container>
		<String str={props.label} variant="h5" />
		<Table>
			<TableBody>
				{props.children}
			</TableBody>
		</Table>
	</Container>
);
