import React from "react";
import Dialog from "./Dialog.js";
import Strings from "Resources/Strings.json";
import reset from "Dispatchers/Reset.js";

/**
 * "Reset app" - individuals mode
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class CardResetPrompt extends React.Component {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Dialog
				disabled={this.props.disabled}
				onClose={this.props.onClose}
				onOk={reset}
				open={this.props.open}
				title={Strings.about.app.reseti}>
				{Strings.about.app.resetPrompti}
			</Dialog>
		);
	}

}

export default CardResetPrompt;
