import React from "react";
import {Box, Typography} from "@material-ui/core";

export default () => (
	<Box mb={1}>
		<Typography	variant="caption">
			&copy; HOPS 2009 - {(new Date()).getFullYear()}.
		</Typography>
	</Box>
);
