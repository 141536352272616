import React from "react";
import {connect} from "react-redux";

/**
 * `withFlags()` HOC wrapper
 *
 * Wraps components with feature flag utility methods.
 *
 * @package HOPS
 * @subpackage Hoc
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
const withFlags = Component => {

	return connect(({flags}) => ({flags}))(class extends React.PureComponent {

		/**
		 * Render.
		 *
		 * @return {ReactNode}
		 */
		render() {
			return (
				<Component
					hasFlag={this.hasFlag}
					{...this.props} />
			);
		}


		/**
		 * Get whether we have a feature flag.
		 * 
		 * @param {String} ID
		 * @return {Boolean}
		 */
		hasFlag = id => this.props.flags.includes(id);

	});

};

export default withFlags;
