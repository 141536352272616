import Store from "App/Store.js";
import * as requesty from "ilm-requests";

/**
 * API URIs
 *
 * @type {Object}
 */
const api = {

	/**
	 * `signin_api_action`
	 *
	 * @type {String}
	 */
	action: "/signin_api_action.php",

	/**
	 * `signin_api_mobile_register`
	 *
	 * @type {String}
	 */
	card: "/signin_api_mobile_register.php",

	/**
	 * `signin_api_ddps_tree`
	 * 
	 * @type {String}
	 */
	ddpl: "/signin_api_ddps_tree.php",

	/**
	 * `signin_api_device`
	 *
	 * @type {String}
	 */
	device: "/signin_api_device.php",

	/**
	 * Lone working initiation
	 *
	 * @type {String}
	 */
	lw: "/api/tr/lw",

	/**
	 * `signin_api_notices`
	 *
	 * @type {String}
	 */
	notices: "/signin_api_notices.php",

	/**
	 * `signin_api_notices_read`
	 *
	 * @type {String}
	 */
	noticesMarkRead: "/signin_api_notices_read.php",

	/**
	 * `signin_api_record`
	 *
	 * @type {String}
	 */
	record: "/signin_api_record.php",

	/**
	 * Get app version from server
	 *
	 * This is relative to the app root, not HOPS root!
	 *
	 * @type {String}
	 */
	version: "/version.txt"

};

/**
 * URL base
 *
 * @type {String}
 */
requesty.config.urlBase = "https://www.heritage-ops.org.uk";

/**
 * Get out real current URI base.
 */
const base = () => (Store.getState().api || "https://www.heritage-ops.org.uk");

/**
 * Hack about with Requesty
 *
 * Override the `req()` method we use so we can dynamically 
 * set the API URI base using the F12 value in our state.
 */
const req = requesty.main.req.bind(requesty.main);
requesty.main.req = (url, request={}, config=null) => {
	requesty.config.urlBase = base();
	return req(url, request, config);
};

export {api, base};
export default requesty;
