import React from "react";
import Button from "Components/Button.js";
import String from "Components/String.js";
import HeroButtonIcon from "./HeroButtonIcon.js";
import rem from "Helpers/Rem.js";
import * as Theme from "App/Theme.js";
import scss from "./HeroButton.module.scss";

/**
 * Hero button
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class HeroButton extends React.Component {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Button
				bold={this.props.bold}
				className={this.className}
				disabled={this.props.disabled}
				endIcon={(this.props.caption ? this.caption : null)}
				label={this.props.label}
				onClick={this.props.onClick}
				size="large"
				startIcon={this.icon}
				style={{...this.style, ...this.props.style}}
				value={this.props.value}
				variant="outlined" />
		);
	}


	/**
	 * Get caption component.
	 * 
	 * @return {String}
	 */
	get caption() {
		return (
			<String
				color={this.props.captionColor}
				str={this.props.caption}
				style={this.constructor.styleCaption} />
		);
	}


	/**
	 * Get CSS `className` string.
	 * 
	 * @return {String}
	 */
	get className() {
		const classes = [scss.HeroButton];
		if (this.props.icon) classes.push(scss.HeroButtonIcon);
		if (this.props.suggestion) classes.push(scss.HeroButtonSuggestion);
		return classes.join(" ");
	}


	/**
	 * Get icon component.
	 *
	 * @return {ReactNode|undefined}
	 */
	get icon() {
		if (this.props.icon) {
			return (
				<HeroButtonIcon
					centred={this.props.heroIcon}
					icon={this.props.icon}
					large={this.props.heroIcon}
					small={this.props.small} />
			);
		}
		else return undefined;
	}


	/**
	 * Styles.
	 * 
	 * @return {Object}
	 */
	get style() {
		return {
			borderColor: this.theme.palette.primary.main,
			borderWidth: "0.3rem",
			color: this.theme.palette.primary.main,
			fontSize: (!this.props.small ? "3.6rem" : "2.4rem"),
			height: (!this.props.small ? "21rem" : "18rem"),
			marginTop: "auto",
			maxWidth: "100%",
			opacity: (this.props.disabled ? 0.5 : 1),
			overflow: "hidden",
			textTransform: "none",
			transition: "opacity 0.2s ease-out",
			width: (!this.props.small ? "27rem" : "21rem")
		};
	}


	/**
	 * Get our theme object.
	 * 
	 * @return {Object}
	 */
	get theme() {
		return (this.props.theme || Theme.app);
	}


	/**
	 * Caption styles.
	 * 
	 * @type {Object}
	 */
	static styleCaption = {
		bottom: rem(0.5),
		fontSize: "3rem",
		left: 0,
		position: "absolute",
		textAlign: "center",
		width: "100%"
	};

}

export default HeroButton;
