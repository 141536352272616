import React from "react";
import AboutSection from "./AboutSection.js";
import Button from "Components/Button.js";
import LabelTable from "Components/LabelTable.js";
import String from "Components/String.js";
import Strings from "Resources/Strings.json";
import SyncService from "Services/SyncService.js";
import strftime from "strftime";
import withTaskStatus from "Hoc/withTaskStatus.js";
import {connect} from "react-redux";
import {TableRow, TableCell} from "@material-ui/core";

/**
 * About upload section
 *
 * @package HOPS
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class AboutUpload extends React.Component {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<AboutSection label={Strings.about.sync.title}>
				<TableRow>
					<TableCell colSpan={2}>
						<Button
							disabled={this.props.activeTasks}
							label={Strings.about.sync.upload}
							onClick={this.constructor.sync}
							variant="outlined" />
					</TableCell>
				</TableRow>
				<TableRow>
					<LabelTable>{Strings.about.sync.queue}</LabelTable>
					<TableCell>
						<String str={this.props.sync.length} />
					</TableCell>
				</TableRow>
				<TableRow>
					<LabelTable>{Strings.about.sync.last}</LabelTable>
					<TableCell>
						<String str={this.syncTime} />
					</TableCell>
				</TableRow>
			</AboutSection>
		);
	}


	/**
	 * Format last sync time string.
	 *
	 * @return {String}
	 */
	get syncTime() {
		const datetime = new Date((this.props.syncTime * 1000));
		if (this.props.syncTime === null) return Strings.about.sync.never;
		else return strftime(Strings.about.sync.strftime, datetime);
	}


	/**
	 * Force a sync.
	 *
	 * @return {void}
	 */
	static sync() {
		SyncService.sync();
	}


	/**
	 * Redux state.
	 *
	 * @param {Object} state
	 * @return {Object}
	 */
	static mapStateToProps(state) {
		return {
			sync: state.sync,
			syncTime: state.syncTime
		};
	}

}

export default connect(AboutUpload.mapStateToProps)(withTaskStatus(AboutUpload));
