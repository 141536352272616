import React from "react";
import DialogBase from "Components/DialogBase.js";
import {DialogContentText} from "@material-ui/core";

/**
 * Dialog
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Dialog extends React.Component {

	/**
	 * Render
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<DialogBase
				disabled={this.props.disabled}
				onClose={this.props.onClose}
				onOk={this.props.onOk}
				open={this.props.open}
				title={this.props.title}>
				{
					this.props.children.map((c, i) => (
						<DialogContentText key={i}>
							{c}
						</DialogContentText>
					))
				}
			</DialogBase>
		);
	}

}

export default Dialog;
