import React from "react";
import String from "Components/String.js";
import * as mui from "@material-ui/core";
import scss from "./Details.module.scss";

/**
 * Details component
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Opeartions Processing Limited
 */
class Details extends React.Component {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<mui.Accordion
				expanded={this.props.open}
				onChange={(!this.props.disabled ? this.props.onToggle : null)}
				style={this.constructor.styles}>
				<mui.AccordionSummary
					classes={this.constructor.classesSummary}
					style={this.stylesSummary}>
					<String str={this.props.label} variant="h6" />
					{(this.props.caption ? this.renderCaption() : null)}
				</mui.AccordionSummary>
				<mui.AccordionDetails
					classes={this.constructor.classesDetails}>
					{this.props.children}
				</mui.AccordionDetails>
				<mui.AccordionActions
					classes={this.constructor.classesActions}>
					{this.props.ActionPanel}
				</mui.AccordionActions>
			</mui.Accordion>
		);
	}


	/**
	 * Render caption.
	 * 
	 * @return {ReactNode}
	 */
	renderCaption() {
		return (
			<String
				caption={true}
				str={this.props.caption}
				style={this.constructor.stylesCaption} />
		);
	}


	/**
	 * Summary styles.
	 * 
	 * @return {Object}
	 */
	get stylesSummary() {
		return {
			cursor: (this.props.disabled ? "default" : null)
		};
	}


	/**
	 * Action classes.
	 * 
	 * @type {Object}
	 */
	static classesActions = {
		root: scss.DetailsActions
	};

	/**
	 * Summary classes.
	 * 
	 * @type {Object}
	 */
	static classesSummary = {
		root: scss.DetailsSummary,
		content: scss.DetailsSummaryContent
	};

	/**
	 * Details classes.
	 * 
	 * @type {Object}
	 */
	static classesDetails = {
		root: scss.DetailsContent
	};

	/**
	 * Styles.
	 * 
	 * @type {Object}
	 */
	static styles = {width: "100%"};

	/**
	 * Caption styles.
	 * 
	 * @type {Object}
	 */
	static stylesCaption = {marginLeft: "auto"};

}

export default Details;
