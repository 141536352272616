import React from "react";
import Anims from "Resources/Anims.json";
import {CheckCircle} from "@material-ui/icons";

const PERMISSIONS_GRANTED_CHECKMARK_STYLES = {animation: Anims.pop};

export default () => (
	<CheckCircle
		color="primary"
		style={PERMISSIONS_GRANTED_CHECKMARK_STYLES} />
);
