import React from "react";
import AboutRefreshButton from "./AboutRefreshButton.js";
import AboutSection from "./AboutSection.js";
import LabelTable from "Components/LabelTable.js";
import String from "Components/String.js";
import Strings from "Resources/Strings.json";
import withRegistration from "Hoc/withRegistration.js";
import {TableRow, TableCell} from "@material-ui/core";

/**
 * About device section
 *
 * @package HOPS
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class AboutDevice extends React.Component {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<AboutSection label={Strings.about.device.title}>
				<TableRow>
					<TableCell colSpan={2}>
						<AboutRefreshButton />
					</TableCell>
				</TableRow>
				<TableRow>
					<LabelTable>{Strings.about.device.device}</LabelTable>
					<TableCell>
						<String str={this.props.registrations[0].name} />
					</TableCell>
				</TableRow>
				<TableRow>
					<LabelTable>{Strings.about.device.organisation}</LabelTable>
					<TableCell>
						<String str={this.props.registrations[0].organisation} />
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell colSpan={2}>
						<String str={Strings.about.device.help} />
					</TableCell>
				</TableRow>
			</AboutSection>
		);
	}

}

export default withRegistration(AboutDevice);
