import React from "react";
import AlertMui from "@material-ui/lab/Alert";
import Flex from "Components/Flex.js";
import String from "Components/String.js";
import scss from "./Alert.module.scss";

/**
 * Alert component
 *
 * Abstraction of Material UI `Alert`.
 * 
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Alert extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<AlertMui className={scss.root} severity={this.props.severity}>
				<Flex gap={0.5}>
					<String bold={true} str={this.props.title} />
					{(this.props.str && <String str={this.props.str} />)}
					{this.props.children}
				</Flex>
			</AlertMui>
		);
	}

}

export default Alert;
