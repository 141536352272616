import React from "react";

/**
 * Async stateful component
 *
 * A React component base that ignores state changes if unmounted.
 *
 * @package HOPS
 * @subpackage Includes
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class AsyncStatefulComponent extends React.Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Mounted
		 *
		 * @type {Boolean}
		 */
		this.mounted = true;

	}


	/**
	 * Component is unmounting.
	 *
	 * @return {void}
	 */
	componentWillUnmount() {
		this.mounted = false;
	}


	/**
	 * `setState()` override
	 *
	 * We only want to set the state if we are still mounted.
	 *
	 * @param {Object} state
	 * @return {void}
	 */
	setState(state) {
		if (this.mounted) super.setState(state);
	}

}

export default AsyncStatefulComponent;
