import React from "react";
import Button from "Components/Button.js";
import Details from "Components/Details.js";
import HopsService from "Services/HopsService.js";
import RegisterService from "Services/RegisterService.js";
import Strings from "Resources/Strings.json";
import day from "dayjs";
import strftime from "strftime";
import {withSnackbar} from "notistack";
import {CircularProgress} from "@material-ui/core";
import {CheckCircle as Checkmark} from "@material-ui/icons";
import {String} from "@heron-web/material";

/**
 * Notice component
 *
 * A notice for display in the `Notices` view.
 *
 * Please refer to the source for details.
 *
 * @package HOPS
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class NoticesNotice extends React.Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Acknowledged?
			 * 
			 * @type {Boolean}
			 */
			acknowledged: false,

			/**
			 * Acknowledging?
			 *
			 * @type {Boolean}
			 */
			acknowledging: false,

			/**
			 * Expanded?
			 *
			 * @type {Boolean}
			 */
			expanded: true

		};

		/**
		 * Method binds
		 */
		this.acknowledge = this.acknowledge.bind(this);
		this.toggleAcknowledged = this.toggleAcknowledged.bind(this);

	}


	/**
	 * Acknowledgement.
	 * 
	 * @return {void}
	 */
	acknowledge() {

		/**
		 * Acknowledging
		 */
		this.setState({acknowledging: true});

		/**
		 * Mark notice read
		 */
		if (!Number.isNaN(parseInt(this.props.notice.id))) {

			/**
			 * Card ID
			 */
			let card = RegisterService.pendingRecord?.record.card;

			/**
			 * Card may be invalid
			 */
			if (RegisterService.pendingRecordDeviceId) {
				card = null;
			}

			/**
			 * Mark the notice read
			 */
			HopsService.markNoticeRead(card, this.props.notice.id).then(() => {
				/*
				 * We always silently ignore errors
				 * HPS specification - refer to !40.
				 */
			}).catch(() => {
				/*
				 * We always silently ignore errors
				 * HPS specification - refer to !40.
				 */
			}).finally(() => this.acknowledgeComplete());

		}
		else this.acknowledgeComplete();

	}


	/**
	 * Acknowledgement complete.
	 * 
	 * @return {void}
	 */
	acknowledgeComplete() {
		this.props.onAcknowledge(this.props.notice.id);
		this.setState({acknowledged: true, acknowledging: false, expanded: false});
	}


	/**
	 * Toggle the acknowledged status.
	 * 
	 * @return {void}
	 */
	toggleAcknowledged() {
		this.setState({expanded: !this.state.expanded});
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Details
				ActionPanel={this.renderActions()}
				caption={this.date}
				disabled={!this.state.acknowledged}
				label={this.props.notice.headline}
				open={this.state.expanded}
				onToggle={this.toggleAcknowledged}>
				<String str={this.props.notice.body} />
			</Details>
		);
	}


	/**
	 * Render actions.
	 *
	 * @return {ReactNode}
	 */
	renderActions() {
		if (this.props.acknowledged) {
			return <Checkmark color="primary" fontSize="large" />;
		}
		else if (!this.state.acknowledging) {
			return <Button onClick={this.acknowledge} label={Strings.notices.acknowledge} />;
		}
		else return <CircularProgress disableShrink={true} size={30} />;
	}


	/**
	 * Get our date string.
	 * 
	 * @return {String}
	 */
	get date() {
		const strf = Strings.notices.datestrf;
		return strftime(strf, (new day(this.props.notice.date)).toDate());
	}

}

export default withSnackbar(NoticesNotice);
