import React from "react";
import Actions from "Resources/Actions.json";
import AsyncStatefulComponent from "Includes/AsyncStatefulComponent.js";
import Button from "Components/Button.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "Components/Container.js";
import Divider from "@material-ui/core/Divider";
import DdplSelection from "./DdplSelection.js";
import Flex from "Components/Flex.js";
import HopsService from "Services/HopsService.js";
import Paragraph from "Components/Paragraph.js";
import RegisterService from "Services/RegisterService.js";
import String from "Components/String.js";
import Strings from "Resources/Strings.json";
import UserMessageInput from "./UserMessageInput.js";
import View from "App/View.js";
import withRegistration from "Hoc/withRegistration.js";
import withSnack from "Hoc/withSnack.js";
import {withRouter} from "react-router-dom";

/**
 * DDPL selection screen
 *
 * @package HOPS
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Ddpl extends AsyncStatefulComponent {

	/**
	 * Constructor.
	 * 
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * DDPL tree
			 *
			 * @type {Object|null}
			 */
			ddpl: undefined,

			/**
			 * Error loading DDPL tree?
			 * 
			 * @type {Boolean}
			 */
			error: false,

			/**
			 * User message text
			 * 
			 * @type {String|null}
			 */
			userMessage: null

		};

	}


	/**
	 * Component mounted.
	 *
	 * We need to load the DDPL tree.
	 */
	componentDidMount() {

		/**
		 * We must be transacting
		 */
		if (!RegisterService.pendingRecord?.record) {
			this.handleNoPendingRecord();
		}

		/**
		 * Get the tree
		 */
		this.updateDdpl();

	}


	/**
	 * Component updated.
	 *
	 * When DDPL is empty, we handle the transaction now (skip to next stage).
	 *
	 * @param {Object} prevProps
	 * @param {Object} prevState
	 * @return {void}
	 */
	componentDidUpdate(prevProps, prevState) {
		if (prevState.ddpl !== this.state.ddpl) {
			if (!this.state.ddpl || (!this.state.ddpl?.departments?.length && !this.state.ddpl?.sites?.length)) {
				const r = RegisterService.pendingRecord;
				if (!r) this.handleNoPendingRecord();
				r.record.ddpl = {department: null, project: null, site: null};
				RegisterService.transact(r);
			}
		}
	}


	/**
	 * Reset and display a warning when there's no pending register record.
	 *
	 * @return {void}
	 */
	handleNoPendingRecord() {
		this.props.history.push("/");
		this.props.snack("No record available.", "error");
	}


	/**
	 * Cancelled (after error state).
	 *
	 * @return {void}
	 */
	handleCancel = () => {
		this.props.history.push("/");
	};


	/**
	 * User message text changed.
	 *
	 * @param {String} userMessage
	 * @return {void}
	 */
	handleChangeUserMessage = userMessage => {
		this.setState({userMessage: (userMessage ? userMessage.replace(/\n/g, "") : null)});
	};


	/**
	 * Submitting.
	 *
	 * We update the pending record and transact again.
	 *
	 * @param {Object} ddpl DDPL data (`DdplSelection`)
	 * @return {void}
	 */
	handleSubmit = ddpl => {

		/**
		 * Get the record
		 */
		const record = RegisterService.pendingRecord;

		/**
		 * No record available now
		 */
		if (!record?.record) {
			this.handleNoPendingRecord();
			return;
		}

		/**
		 * Mutate the record with the DDPL data
		 */
		const updatedRecord = {
			...record,
			record: {
				...record.record,
				...ddpl,
				message: this.state.userMessage
			}
		};

		/**
		 * Transact the data
		 */
		RegisterService.transact(updatedRecord);

	};


	/**
	 * Update the DDPL tree in our state.
	 * 
	 * @return {void}
	 */
	updateDdpl = () => {

		/**
		 * Loading
		 */
		this.setState({error: false});

		/**
		 * Card + device
		 */
		const card = RegisterService.pendingRecord?.record.card;
		const device = RegisterService.pendingRecordDeviceId;

		/**
		 * Get the DDPL tree
		 */
		HopsService.getDdpl((device || card), (device !== null)).then(ddpl => {
			this.setState({ddpl});
		}).catch(() => {
			this.setState({error: true});
		});

	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<View style={this.constructor.styles}>
				{(this.state.error ? this.renderError() : null)}
				{((!this.state.ddpl && !this.state.error) ? <CircularProgress disableShrink={true} /> : null)}
				{(this.ready ? this.renderMain() : null)}
				{(this.shouldRenderUserMessage && this.renderUserMessage())}
			</View>
		);
	}


	/**
	 * Render main (DDPL tree available).
	 * 
	 * @return {ReactNode}
	 */
	renderMain() {
		return (
			<Container fullWidth={true} gap={2}>
				<DdplSelection
					activeCardId={RegisterService.pendingRecord?.record.card}
					ddpl={this.state.ddpl}
					onSubmit={this.handleSubmit} />
				<String
					centre={true}
					str={Strings.ddpl.caption}
					variant="caption" />
			</Container>
		);
	}


	/**
	 * Render the user message input section.
	 * 
	 * @return {ReactNode}
	 */
	renderUserMessage() {
		return (
			<Flex fullWidth={true} gap={2} mt={1}>
				<Divider />
				<UserMessageInput
					message={this.state.userMessage}
					onChange={this.handleChangeUserMessage} />
			</Flex>
		);
	}


	/**
	 * Render the error state.
	 *
	 * @return {ReactNode}
	 */
	renderError() {
		return (
			<Container>
				<Paragraph p={Strings.ddpl.error} />
				<Button
					label="Retry"
					onClick={this.updateDdpl} />
				<Button
					label="Cancel"
					onClick={this.handleCancel}
					variant="outlined" />
			</Container>
		);
	}


	/**
	 * Get whether we're ready to render the main state.
	 * 
	 * The DDPL tree must be available and not errored.
	 * 
	 * @return {Boolean}
	 */
	get ready() {
		return (this.state.ddpl && !this.state.error);
	}


	/**
	 * Get whether to render the user message input.
	 * 
	 * @return {Boolean}
	 */
	get shouldRenderUserMessage() {
		const record = RegisterService.pendingRecord;
		return (this.ready && (record?.record.action !== Actions.record.out) && record?.record.card_scanned && record?.record.card_confirmed && this.props.isSiteMode);
	}


	/**
	 * Styles.
	 * 
	 * @type {Object}
	 */
	static styles = {
		alignSelf: "flex-start",
		maxWidth: "100%",
		width: "64rem"
	};

}

export default withRegistration(withRouter(withSnack(Ddpl)));
