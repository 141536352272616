import history from "Shims/History.js";

/**
 * Navigator
 *
 * @package HOPS
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Navigator {

	/**
	 * Go home.
	 *
	 * @return {void}
	 */
	static home() {
		history.push("/");
	}

}

export default Navigator;
