import React from "react";
import CardService from "Services/CardService.js";
import Dialog from "Components/Dialog.js";
import Strings from "Resources/Strings.json";
import dUnregister from "Dispatchers/Unregister.js";
import withSnack from "Hoc/withSnack.js";
import withTaskStatus from "Hoc/withTaskStatus.js";

/**
 * Remove card dialog
 *
 * @package HOPS
 * @subpackage Cards
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class RemoveCardDialog extends React.Component {

	/**
	 * Constructor.
	 * 
	 * @param {Object} props
	 */
	constructor(props) {
		super(props);

		/**
		 * Method binds
		 */
		this.handleRemove = this.handleRemove.bind(this);

	}


	/**
	 * Remove the card.
	 * 
	 * @return {void}
	 */
	handleRemove() {
		dUnregister(this.props.card.id);
		this.props.snack(Strings.about.cards.removed, "success");
		this.props.onClose();
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Dialog
				disabled={this.props.activeTasks}
				onClose={this.props.onClose}
				onOk={this.handleRemove}
				open={this.props.open}
				title={`Remove card ${this.cardIdUi}`}>
				{Strings.removeCardWarning}
			</Dialog>
		);
	}


	/**
	 * Get card ID for UI.
	 * 
	 * @return {String}
	 */
	get cardIdUi() {
		return CardService.getUiCardId(this.props.card?.id);
	}

}

export default withSnack(withTaskStatus(RemoveCardDialog));
