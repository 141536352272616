import React from "react";
import Container from "Components/Container.js";
import String from "Components/String.js";
import Theme from "Resources/Theme.json";

/**
 * View component
 *
 * @package HOPS
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class View extends React.Component {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Container
				alignItems={this.props.alignItems}
				centred={!this.props.notCentred}
				gap={this.props.gap}
				my={2}
				px={1}
				style={this.styles}>
				{(this.props.label ? this.label : null)}
				{this.props.children}
			</Container>
		);
	}


	/**
	 * Heading label.
	 *
	 * @return {ReactNode}
	 */
	get label() {
		return (
			<Container px={1}>
				<String centre={true} str={this.props.label} variant="h4" />
			</Container>
		);
	}


	/**
	 * Styles.
	 *
	 * @return {Object}
	 */
	get styles() {
		return {
			alignSelf: (this.props.top ? "flex-start" : null),
			maxWidth: (!this.props.fullWidth ? Theme.Hops.mainWidth : null),
			width: "100vw",
			...this.props.style
		};
	}

}

export default View;
