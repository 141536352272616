import React from "react";
import Alert from "Components/Alert.js";
import AsyncStatefulComponent from "Includes/AsyncStatefulComponent.js";
import Container from "Components/Container.js";
import HopsService from "Services/HopsService.js";
import Loader from "Components/Loader.js";
import SiteScreenUser from "./SiteScreenUser.js";
import String from "Components/String.js";
import Strings from "Resources/Strings.json";
import dayjs from "dayjs";
import withRegistration from "Hoc/withRegistration.js";
import {connect} from "react-redux";

/**
 * Site screen
 *
 * When in site mode, this shows the users 
 * assigned to the site with their statuses.
 * 
 * @package HOPS
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class SiteScreen extends AsyncStatefulComponent {

	/**
	 * Refresh interval ID
	 *
	 * @type {Integer|null}
	 */
	interval = null;

	/**
	 * Refreshing data?
	 *
	 * @type {Boolean}
	 */
	refreshing = false;

	/**
	 * State
	 *
	 * @type {Object}
	 */
	state = {

		/**
		 * Data (site user list API)
		 *
		 * @type {Object|null}
		 */
		data: null,

		/**
		 * Device online?
		 *
		 * @type {Boolean}
		 */
		online: navigator.onLine,

		/**
		 * Data last updated time
		 *
		 * @type {Integer|null}
		 */
		updateTime: null

	};


	/**
	 * Component mounted.
	 *
	 * We load initial data and setup the refresh interval.
	 * 
	 * @return {void}
	 */
	componentDidMount() {

		this.refresh();
		this.interval = setInterval(this.refresh, 60000);

		window.addEventListener("offline", this.handleOffline);
		window.addEventListener("online", this.handleOnline);

	}


	/**
	 * Component updated.
	 *
	 * We refresh when a sync completes.
	 *
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (prevProps.syncTime !== this.props.syncTime) {
			this.refresh();
		}
	}


	/**
	 * Component unmounting.
	 *
	 * Clear the refresh interval.
	 * 
	 * @return {void}
	 */
	componentWillUnmount() {

		if (this.interval) {
			clearInterval(this.interval);
		}

		window.removeEventListener("offline", this.handleOffline);
		window.removeEventListener("online", this.handleOnline);

	}


	/**
	 * We are now offline.
	 * 
	 * @return {void}
	 */
	handleOffline = () => {
		this.setState({online: false});
	};


	/**
	 * We are now online.
	 *
	 * @return {void}
	 */
	handleOnline = () => {
		this.refresh();
		this.setState({online: true});
	};


	/**
	 * Refresh the user data.
	 * 
	 * @return {void}
	 */
	refresh = () => {

		/**
		 * Already refreshing
		 */
		if (this.refreshing) return;

		/**
		 * Get our device ID
		 */
		const deviceId = this.props.activeRegistration.id;

		/**
		 * Get the data
		 */
		HopsService.getDeviceUserList(deviceId).then(data => {
			this.setState({data, updateTime: Date.now()});
		}).catch(() => {

			/**
			 * We're not concerned about errors
			 *
			 * First load = keep loading, try again on the interval.
			 *
			 * Once we have data and are refreshing, just keep the old 
			 * data visible for the time being and try again on the interval.
			 */

		});

	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		if (this.state.data) return this.renderMain();
		else if (!this.state.online) return this.renderOffline();
		else return <Loader />;
	}


	/**
	 * Render main UI.
	 * 
	 * @return {ReactNode}
	 */
	renderMain() {
		return <>
			{(!this.state.online && this.renderOffline())}
			<Container fullWidth={true} gap={2} style={this.constructor.styles}>
				{
					this.state.data.map((record, key) => (
						<SiteScreenUser
							key={key}
							record={record}
							registration={this.props.activeRegistration} />
					))
				}
			</Container>
			<String str={`Updated at ${this.dataUpdateTime}.`} variant="body2" />
		</>;
	}


	/**
	 * Render the offline banner.
	 * 
	 * @return {ReactNode}
	 */
	renderOffline() {
		return (
			<Alert
				severity="error"
				str={(this.state.data ? Strings.siteScreen.offline.caption : Strings.siteScreen.offline.captionNoData)}
				title={Strings.siteScreen.offline.title}>
				{(this.state.data && <String str={`The records shown below were loaded at ${this.dataUpdateTime}.`} />)}
				{(!this.state.data && <String str={Strings.siteScreen.offline.captionNoDataHelp} />)}
			</Alert>
		);
	}


	/**
	 * Get the time the data was last updated for UI display.
	 * 
	 * @return {String}
	 */
	get dataUpdateTime() {
		return (new dayjs(this.state.updateTime)).format("HH:mm");
	}


	/**
	 * Styles.
	 * 
	 * @type {Object}
	 */
	static styles = {
		gridTemplateColumns: "repeat(auto-fit, 30rem)",
		justifyContent: "center"
	};

}

export default connect(({syncTime}) => ({syncTime}))(withRegistration(SiteScreen));
