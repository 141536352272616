import React from "react";
import AsyncStatefulComponent from "Includes/AsyncStatefulComponent.js";
import AboutSection from "./AboutSection.js";
import Admin from "Components/Admin.js";
import CameraService from "Services/CameraService.js";
import Container from "Components/Container.js";
import LabelTable from "Components/LabelTable.js";
import Link from "Components/Link.js";
import Select from "Components/Select.js";
import String from "Components/String.js";
import Strings from "Resources/Strings.json";
import Switch from "Components/Switch.js";
import dispatchGeneric from "Dispatchers/Generic.js";
import {connect} from "react-redux";
import {CircularProgress, MenuItem, TableRow, TableCell} from "@material-ui/core";

/**
 * About device settings section
 *
 * @package HOPS
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class AboutDeviceSettings extends AsyncStatefulComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Authentication visible
			 *
			 * @type {Boolean}
			 */
			auth: false,

			/**
			 * Camera devices
			 *
			 * @type {Array}
			 */
			cameras: null,

			/**
			 * Camera devices loaded
			 *
			 * @type {Boolean}
			 */
			camerasLoaded: false,

			/**
			 * Controls enabled?
			 * 
			 * @type {Boolean}
			 */
			enabled: false

		};

		/**
		 * Method binds
		 */
		this.handleAuth = this.handleAuth.bind(this);
		this.handleAuthToggle = this.handleAuthToggle.bind(this);
		this.handleCameraMirrorChanged = this.handleCameraMirrorChanged.bind(this);
		this.handleTouchKbdChanged = this.handleTouchKbdChanged.bind(this);

	}


	/**
	 * Component mounted.
	 *
	 * We have to get the camera list.
	 * 
	 * @return {void}
	 */
	componentDidMount() {
		CameraService.getCameras().then(cameras => {
			this.setState({cameras});
		}).catch(() => {
			this.setState({cameras: null});
		}).finally(() => this.setState({camerasLoaded: true}));
	}


	/**
	 * Authenticated.
	 * 
	 * @return {void}
	 */
	handleAuth() {
		this.handleAuthToggle();
		this.setState({enabled: true});
	}


	/**
	 * Authentication dialog visibility toggled.
	 * 
	 * @return {void}
	 */
	handleAuthToggle() {
		this.setState({auth: !this.state.auth});
	}


	/**
	 * Camera mirroring setting changed.
	 * 
	 * @param {Boolean} mirror
	 * @return {void}
	 */
	handleCameraMirrorChanged() {
		dispatchGeneric({cameraMirror: !this.props.cameraMirror});
	}


	/**
	 * Touch keyboard setting toggle changed.
	 * 
	 * @return {void}
	 */
	handleTouchKbdChanged() {
		dispatchGeneric({touchKeyboard: !this.props.touchKeyboard});
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<AboutSection label={Strings.about.settings.title}>
				<TableRow>
					<LabelTable>{Strings.about.settings.camera}</LabelTable>
					<TableCell>
						<Container style={this.constructor.camerasStyles}>
							{this.renderCameras()}
						</Container>
					</TableCell>
				</TableRow>
				<TableRow>
					<LabelTable>{Strings.about.settings.cameraMirror}</LabelTable>
					<TableCell>
						<Switch
							checked={this.props.cameraMirror}
							disabled={!this.state.enabled}
							onChange={this.handleCameraMirrorChanged} />
					</TableCell>
				</TableRow>
				<TableRow>
					<LabelTable>{Strings.about.settings.touchKeyboard}</LabelTable>
					<TableCell>
						<Switch
							checked={this.props.touchKeyboard}
							disabled={!this.state.enabled}
							onChange={this.handleTouchKbdChanged} />
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell colSpan={2}>
						<Container style={this.constructor.footerStyles}>
							{this.renderFooter()}
						</Container>
					</TableCell>
				</TableRow>
				<Admin
					active={this.state.auth}
					onAuth={this.handleAuth}
					onCancel={this.handleAuthToggle} />
			</AboutSection>
		);
	}


	/**
	 * Render the camera picker.
	 *
	 * @return {ReactNode}
	 */
	renderCameras() {
		if (!this.state.camerasLoaded) {
			return <CircularProgress disableShrink={true} size={20} />;
		}
		else if (!this.state.cameras) {
			return <String str={Strings.about.settings.camerr} />;
		}
		else if (!this.state.cameras?.length) {
			return <String str={Strings.about.settings.camerrEmpty} />;
		}
		else return this.renderCamerasSelect();
	}


	/**
	 * Render the camera picker select.
	 *
	 * @return {ReactNode}
	 */
	renderCamerasSelect() {
		return (
			<Select
				disabled={!this.state.enabled}
				onChange={this.constructor.onCamera}
				value={this.selectedCamera}>
				{this.renderCamerasSelectOptions()}
			</Select>
		);
	}


	/**
	 * Render options for the camera picker.
	 *
	 * @return {ReactNode}
	 */
	renderCamerasSelectOptions() {
		return this.state.cameras.map((c, k) => {
			return this.constructor.renderCameraSelectOption(c, k);
		});
	}


	/**
	 * Render the footer.
	 * 
	 * @return {ReactNode}
	 */
	renderFooter() {
		if (this.state.enabled) return <String str={Strings.about.settings.help} />;
		else return <div><Link label="Change settings..." onClick={this.handleAuthToggle} /></div>;
	}


	/**
	 * Get the selected camera (`undefined` if none).
	 *
	 * @return {String|Integer|null}
	 */
	get selectedCamera() {
		const cameras = (this.state.cameras || [{}]);
		const camera = (cameras[0].label ? cameras[0].deviceId : 0);
		return (this.props.camera || camera);
	}


	/**
	 * Camera ID changed.
	 * 
	 * @param {String|Integer} camera
	 * @return {void}
	 */
	static onCamera(camera) {
		dispatchGeneric({camera});
	}


	/**
	 * Render an option for the camera picker.
	 *
	 * @return {ReactNode}
	 */
	static renderCameraSelectOption(c, key) {
		return (
			<MenuItem key={key} value={(c.label ? c.deviceId : key)}>
				{(c.label || `Camera ${(key + 1)}`)}
			</MenuItem>
		);
	}


	/**
	 * Styles for camera selection container.
	 * 
	 * @type {Object}
	 */
	static camerasStyles = {height: "3rem"};

	/**
	 * Styles for footer container.
	 * 
	 * @type {Object}
	 */
	static footerStyles = {height: "2rem"};

	/**
	 * Map state to props.
	 *
	 * @param {Object} state
	 * @return {Object}
	 */
	static mapStateToProps(state) {
		return {
			camera: state.camera,
			cameraMirror: state.cameraMirror,
			touchKeyboard: state.touchKeyboard
		};
	}

}

export default connect(AboutDeviceSettings.mapStateToProps)(AboutDeviceSettings);
