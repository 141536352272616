import React from "react";
import AboutApp from "./AboutApp.js";
import AboutCards from "./AboutCards.js";
import AboutDevice from "./AboutDevice.js";
import AboutDeviceSettings from "./AboutDeviceSettings.js";
import AboutUpload from "./AboutUpload.js";
import View from "App/View.js";
import dRecordPending from "Dispatchers/RecordPending.js";
import withRegistration from "Hoc/withRegistration.js";

/**
 * About view
 *
 * @package HOPS
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class About extends React.Component {

	/**
	 * Component mounted.
	 *
	 * @return {void]
	 */
	componentDidMount() {

		/**
		 * Clear any pending record
		 *
		 * Prevents users from pressing back button to surface 
		 * any previous cancelled/time-out transaction!
		 */
		dRecordPending(null);

	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<View gap={3} notCentred={true} top={true}>
				<AboutUpload />
				{(!this.props.isDevice ? <AboutCards /> : null)}
				{(this.props.isDevice ? <AboutDevice /> : null)}
				{(this.props.isDevice ? <AboutDeviceSettings /> : null)}
				<AboutApp />
			</View>
		);
	}

}

export default withRegistration(About);
