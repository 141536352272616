import React from "react";
import CardService from "Services/CardService.js";
import RemoveCardDialog from "Cards/RemoveCardDialog.js";
import String from "Components/String.js";
import rem from "Helpers/Rem.js";
import withRegistration from "Hoc/withRegistration.js";
import withTaskStatus from "Hoc/withTaskStatus.js";
import {IconButton, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {Clear as DeleteIcon} from "@material-ui/icons";

/**
 * About cards table
 *
 * @package HOPS
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class AboutCardsTable extends React.Component {

	/**
	 * State
	 * 
	 * @type {Object}
	 */
	state = {

		/**
		 * Active card
		 * 
		 * @type {Object|null}
		 */
		active: null,

		/**
		 * Removal dialog active
		 * 
		 * @type {Boolean}
		 */
		removal: false

	};


	/**
	 * Removing a card.
	 *
	 * @type {Object} card
	 * @return {void}
	 */
	handleRemoveCard = card => {
		this.setState({active: card, removal: true});
	};


	/**
	 * Closed remove card dialog.
	 * 
	 * @return {void}
	 */
	handleRemoveCardClose = () => {
		this.setState({removal: false});
	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<React.Fragment>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>
								<String
									bold={true}
									str="Card ID" />
							</TableCell>
							<TableCell>
								<String
									bold={true}
									str="HOPS Organisation" />
							</TableCell>
							<TableCell />
						</TableRow>
					</TableHead>
					<TableBody>
						{
							this.props.registrations.map((card, key) => (
								<TableRow key={key}>
									<TableCell>
										<String str={CardService.getUiCardId(card.id)} />
									</TableCell>
									<TableCell>
										<String str={card.organisation} />
									</TableCell>
									<TableCell>
										{this.renderCardDeleteIcon(card)}
									</TableCell>
								</TableRow>
							))
						}
					</TableBody>
				</Table>
				<RemoveCardDialog
					card={this.state.active}
					onClose={this.handleRemoveCardClose}
					open={(this.state.removal)} />
			</React.Fragment>
		);
	}


	/**
	 * Render the delete icon for a card.
	 *
	 * @param {Object} card Registration object
	 * @return {ReactNode}
	 */
	renderCardDeleteIcon(card) {
		return (
			<IconButton
				disabled={this.props.activeTasks}
				icon={this.constructor.deleteIconInstance}
				onClick={() => this.handleRemoveCard(card)}
				size="small"
				title="Remove">
				{this.constructor.deleteIconInstance}
			</IconButton>
		);
	}


	/**
	 * Delete icon.
	 * 
	 * @type {ReactNode}
	 */
	static deleteIconInstance = (
		<DeleteIcon
			color="primary"
			style={{minWidth: rem(1.5)}} />
	);

}

export default withRegistration(withTaskStatus(AboutCardsTable));
