import React from "react";
import Navigator from "App/Navigator.js";
import {IconButton} from "@material-ui/core";
import {Home as HomeIcon} from "@material-ui/icons";

/**
 * Home icon button
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class IconButtonHome extends React.Component {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<IconButton color="secondary" onClick={Navigator.home}>
				<HomeIcon />
			</IconButton>
		);
	}

}

export default IconButtonHome;
