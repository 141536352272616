import React from "react";
import Actions from "Resources/Actions.json";
import AsyncStatefulComponent from "Includes/AsyncStatefulComponent.js";
import Navigator from "App/Navigator.js";
import View from "App/View.js";
import Button from "Components/Button.js";
import Container from "Components/Container.js";
import HeroButton from "Components/HeroButton.js";
import String from "Components/String.js";
import Strings from "Resources/Strings.json";
import HopsService from "Services/HopsService.js";
import Registrations from "Resources/Registrations.json";
import withMobile from "Hoc/withMobile.js";
import withRegistration from "Hoc/withRegistration.js";
import {SignIn as SignInTheme, SignOut as SignOutTheme} from "App/Theme.js";
import {withRouter} from "react-router-dom";
import {withSnackbar} from "notistack";
import {CircularProgress, ThemeProvider} from "@material-ui/core";
import {PersonAddDisabled as SignOffIcon} from "@material-ui/icons";
import {PersonAdd as SignOnIcon} from "@material-ui/icons";

/**
 * Checkpoint actions screen
 *
 * @package HOPS
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class CheckpointActions extends AsyncStatefulComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Suggested action
			 *
			 * @type {Integer}
			 */
			action: null,

			/**
			 * View loaded
			 *
			 * @type {Boolean}
			 */
			loaded: false,

			/**
			 * User's human name for display
			 *
			 * @type {String}
			 */
			user: null

		};

		/**
		 * Method binds
		 */
		this.onAction = this.onAction.bind(this);

	}


	/**
	 * Component mounted; try to get suggested action.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		if (this.props.suggest) {
			this.updateSuggestion();
		}
		else this.setState({loaded: true});
	}


	/**
	 * Component updated; refresh suggestion when needed.
	 *
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (prevProps.disabled !== this.props.disabled) {
			if (!this.props.disabled && this.props.suggest) {
				this.updateSuggestion();
			}
		}
	}


	/**
	 * Action selected.
	 *
	 * Invokes the `onAction(...)` prop callback.
	 *
	 * The callback is passed an object with `action` (selected 
	 * action, either `1` (in) or `2` (out)), `confirmed` (whether 
	 * we confirmed/suggested the user input via HOPS) and `user` (this 
	 * is the user card ID/manual input supplied via the `user` prop).
	 *
	 * We also supply `userName` which will be the user's real name 
	 * assigned in HOPS, if `confirmed` is true and we got a suggestion.
	 *
	 * @param {Integer} action
	 * @return {void}
	 */
	onAction(action) {

		/**
		 * State
		 */
		const id = !this.isDevice;
		const suggest = (this.state.action !== null);

		/**
		 * Record
		 */
		const card_confirmed = (id || suggest);
		const card_scanned = (!id && this.props.suggest);

		/**
		 * Action
		 */
		const record = {
			action,
			card: (this.isDevice ? this.props.user : this.props.registration.id),
			card_scanned,
			card_confirmed,
			mode: (this.isDevice ? Actions.mode.device : Actions.mode.card)
		};

		/**
		 * Device ID
		 */
		if (this.isDevice) record.device = this.props.registration.id;

		/**
		 * We've actioned!
		 */
		this.props.onAction(record, this.user);

	}


	/**
	 * Update the suggested action.
	 *
	 * @return {void}
	 */
	updateSuggestion() {

		/**
		 * User + device
		 */
		let device = null;
		const user = (this.isDevice ? this.props.user : this.props.registration.id);

		/**
		 * Device ID
		 */
		if (this.isDevice) device = this.props.registration.id;

		/**
		 * Get the suggested action
		 */
		HopsService.getAction(device, user).then(action => {
			this.setState({...action, loaded: true});
		}).catch(e => {
			if (e.status && (e.status >= 400) && (e.status < 500)) {
				if (this.props.onError) this.props.onError();
			}
			this.setState({loaded: true});
		});

	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (this.state.loaded ? this.view : <CircularProgress disableShrink={true} />);
	}


	/**
	 * Render the buttons.
	 * 
	 * @return {ReactNode}
	 */
	get buttons() {
		return (
			<Container>
				<Button
					label="Cancel"
					onClick={Navigator.home}
					variant="outlined" />
				<Button
					label="Change Work Area"
					onClick={this.onAction}
					value={Actions.record.location} />
			</Container>
		);
	}


	/**
	 * Get label for the view.
	 *
	 * @return {ReactNode}
	 */
	get label() {
		return (
			<div>
				<span>Hello</span>
				<span>{(this.user ? <React.Fragment>&nbsp;</React.Fragment> : "")}</span>
				<span>{(this.user ? <b>{this.user}</b> : "")}</span>
			</div>
		);
	}


	/**
	 * Get the view definition.
	 *
	 * @return {ReactNode}
	 */
	get view() {
		return (
			<View gap={4} label={this.label}>
				<Container>
					<Container columnar={!this.props.isMobile} gap={2}>
						<ThemeProvider theme={SignInTheme}>
							<HeroButton
								disabled={this.props.disabled}
								icon={SignOnIcon}
								label="Sign On"
								onClick={this.onAction}
								suggestion={(this.state.action === 1)}
								theme={SignInTheme}
								value={Actions.record.in} />
						</ThemeProvider>
						<ThemeProvider theme={SignOutTheme}>
							<HeroButton
								disabled={this.props.disabled}
								icon={SignOffIcon}
								label="Sign Off"
								onClick={this.onAction}
								suggestion={(this.state.action === 0)}
								theme={SignOutTheme}
								value={Actions.record.out} />
						</ThemeProvider>
					</Container>
				</Container>

				<String centre={true} str={this.help} />
				{this.buttons}
			</View>
		);
	}


	/**
	 * Get whether the active registration is a device registration.
	 * 
	 * @return {Boolean}
	 */
	get isDevice() {
		return (this.props.registration.kind === Registrations.device);
	}


	/**
	 * Get the help text.
	 * 
	 * @return {String}
	 */
	get help() {
		return (this.isDevice ? Strings.checkpoint.actions.help : Strings.checkpoint.actions.helpi);
	}


	/**
	 * Get the user's name.
	 * 
	 * @return {String}
	 */
	get user() {
		if (this.isDevice) return this.state.user;
		else return this.props.registration.name;
	}

}

export default withRegistration(withMobile(withRouter(withSnackbar(CheckpointActions))));
