import React from "react";
import Button from "Components/Button.js";
import Container from "Components/Container.js";
import DdplSuggestionBanner from "./DdplSuggestionBanner.js";
import Flex from "Components/Flex.js";
import ListHeader from "./DdplSelectionListHeading.js";
import String from "Components/String.js";
import Styles from "Resources/Theme.json";
import rem from "Helpers/Rem.js";
import withMobile from "Hoc/withMobile.js";
import withRegistration from "Hoc/withRegistration.js";
import scss from "./DdplSelection.module.scss";
import {List, ListItem, ListItemText, Stepper, Step, StepButton} from "@material-ui/core";

/**
 * DDPL selection interface
 *
 * @package HOPS
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class DdplSelection extends React.Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Active step
			 *
			 * @type {Integer}
			 */
			step: (this.hasDepartments ? 0 : (this.hasSites ? 2 : null)),

			/**
			 * Selected department ID
			 *
			 * @type {Integer|null}
			 */
			department: null,

			/**
			 * Selected project ID
			 *
			 * @type {Integer|null}
			 */
			project: null,

			/**
			 * Selected site ID
			 *
			 * @type {Integer|null}
			 */
			site: this.initialSiteSelection

		};

		/**
		 * Method binds
		 */
		this.handleBack = this.handleBack.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);

		/**
		 * Bound values
		 */
		this.selectDepartments = () => this.selectStep(0);
		this.selectProjects = () => this.selectStep(1);
		this.selectSites = () => this.selectStep(2);

	}


	/**
	 * Back button clicked.
	 * 
	 * @return {void}
	 */
	handleBack() {
		switch (this.state.step) {
			case 2:
				if ((this.state.project === true) && !this.hasProjects) {
					this.selectStep(0);
				}
				else this.selectStep(1);
				break;
			default:
				this.selectStep(0);
				break;
		}
	}


	/**
	 * Submitting.
	 * 
	 * @return {void}
	 */
	handleSubmit() {

		/**
		 * DDPL object
		 */
		const ddpl = {ddpl: true};

		/**
		 * DDPL (temp) object
		 */
		const ddpl_temp = {
			department: this.state.department,
			project: this.state.project,
			site: this.state.site
		};

		/**
		 * Create our keys
		 */
		Object.keys(ddpl_temp).forEach(key => {
			ddpl[key] = ((ddpl_temp[key] !== true) ? ddpl_temp[key] : null);
		});

		/**
		 * Pass data up.
		 */
		this.props.onSubmit(ddpl);

	}


	/**
	 * An item was clicked.
	 *
	 * @param {mixed} item Data item of the item which was clicked
	 * @param {Boolean} allowSubmit optional Allow immediate submission (`false`)
	 * @return {void}
	 */
	handleItemClicked(item, allowSubmit=false) {

		const department = item.id;
		const projects = this.getProjects(department);
		const project = (projects?.length ? null : true);
		const s = (projects?.length ? 1 : (this.hasSites ? 2 : 0));

		switch (this.state.step) {

			/**
			 * Site selected
			 */
			case 2:
				this.setState({site: item.id});
				break;

			/**
			 * Project selected
			 */
			case 1:
				this.setState({project: item.id});
				if (this.hasSites) this.selectStep(2);
				break;

			/**
			 * Department selected
			 */
			default:
				this.setState(
					{department, project},
					() => {
						if (s) this.selectStep(s);
						else if (allowSubmit) this.handleSubmit();
					}
				);
				break;

		}

	}


	/**
	 * Change the selected step.
	 *
	 * @param {Integer} step
	 * @return {void}
	 */
	selectStep(step) {
		this.setState({step});
		window.scrollTo({top: 0});
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Container gap={2} style={this.constructor.styles}>
				<Stepper
					activeStep={this.state.step}
					connector={null}
					alternativeLabel={true}
					style={this.constructor.stepperStyles}>
					{(this.hasDepartments ? this.renderDeptsStep() : null)}
					{(this.hasDepartments ? this.renderProjectsStep() : null)}
					{(this.hasSites ? this.renderSitesStep() : null)}
				</Stepper>
				{(this.shouldRenderDeptSuggestionBanner && this.suggestedDept && this.renderDeptSuggestionBanner())}
				<Container gap={2}>{this.renderLists()}</Container>
				{(this.shouldRenderOkButton ? this.renderOkButton() : null)}
				<Container centred={true}>
					<Button
						disabled={(this.state.step === 0)}
						label="Back"
						onClick={this.handleBack}
						variant="outlined" />
				</Container>
			</Container>
		);
	}


	/**
	 * Render the department suggestion banner.
	 * 
	 * @return {ReactNode}
	 */
	renderDeptSuggestionBanner() {
		return (
			<Flex mt={-1.5}>
				<DdplSuggestionBanner
					buttonLabel={this.props.ddpl.suggestion?.text}
					onSelect={() => this.handleItemClicked(this.suggestedDept, true)} />
			</Flex>
		);
	}


	/**
	 * Render our content lists.
	 * 
	 * @return {ReactNode}
	 */
	renderLists() {

		const content = this.getListContent();

		if (this.shouldRenderNoneOption) {
			content.push(this.constructor.noneOption);
		}

		return this.renderList(content, this.title);

	}


	/**
	 * Render a list.
	 * 
	 * @param {Array} content Content items to render
	 * @param {String} heading Heading text
	 * @param {mixed} key optional React node key
	 * @param {Boolean} columns optional Use columnar layout (`true`)
	 * @param {Object} style optional Container styles
	 * @return {ReactNode}
	 */
	renderList(content, heading, key=null, columns=true, style=null) {
		return (
			<Container gap={0} key={key} style={style}>
				{(heading ? <ListHeader heading={heading} /> : null)}
				<List className={(columns ? scss.List : null)}>
					{this.renderListContent(content)}
				</List>
			</Container>
		);
	}


	/**
	 * Render a list's content.
	 *
	 * @param {Array} content Content items to render
	 * @return {ReactNode}
	 */
	renderListContent(content) {
		return content.map((item, key) => {
			return (
				<ListItem
					button={true}
					divider={(key !== content.length)}
					color="primary"
					onClick={() => this.handleItemClicked(item)}
					selected={this.isItemSelected(item)}
					key={key}>
					<Container centred={true} fullWidth={true} gap={0.5}>
						<ListItemText>
							<String
								bold={((this.state.step === 2) && this.props.isSiteMode && (parseInt(item.id) === this.props.activeRegistration?.site?.id))}
								centre={true}
								lines={2}
								str={(item.name || item)}
								style={{opacity: ((item.id === true) ? 0.6 : 1)}} />
						</ListItemText>
					</Container>
				</ListItem>
			);
		});
	}


	/**
	 * Render the departments step.
	 * 
	 * @return {ReactNode}
	 */
	renderDeptsStep() {
		return (
			<Step>
				<StepButton
					active={true}
					completed={this.departmentButtonCompleted}
					disabled={this.departmentButtonDisabled}
					onClick={this.selectDepartments}>
					{this.renderStepLabel(0, "Department")}
				</StepButton>
			</Step>
		);
	}


	/**
	 * Render the projects step.
	 * 
	 * @return {ReactNode}
	 */
	renderProjectsStep() {
		return (
			<Step>
				<StepButton
					active={this.projectButtonActive}
					completed={this.projectButtonCompleted}
					disabled={this.projectButtonDisabled}
					onClick={this.selectProjects}
					style={this.projectButtonStyles}>
					{this.renderStepLabel(1, "Project")}
				</StepButton>
			</Step>
		);
	}


	/**
	 * Render the sites step.
	 * 
	 * @return {ReactNode}
	 */
	renderSitesStep() {
		return (
			<Step>
				<StepButton
					active={this.siteButtonActive}
					completed={this.siteButtonCompleted}
					disabled={this.siteButtonDisabled}
					onClick={this.selectSites}>
					{this.renderStepLabel(2, "Site")}
				</StepButton>
			</Step>
		);
	}


	/**
	 * Render the OK button.
	 * 
	 * @return {ReactNode}
	 */
	renderOkButton() {
		return (
			<Container centred={true} mb={-1}>
				<Button label="OK" onClick={this.handleSubmit} />
			</Container>
		);
	}


	/**
	 * Render the label for a step.
	 * 
	 * @param {Integer} step Step ID
	 * @param {String} label Label text
	 * @return {ReactNode}
	 */
	renderStepLabel(step, label) {
		return (
			<String
				bold={(this.state.step === step)}
				str={label}
				variant="caption" />
		);
	}


	/**
	 * Get array of all our discipline names.
	 * 
	 * @return {Array}
	 */
	getDisciplines() {
		const disps = (this.props.ddpl.departments?.map(dept => dept.discipline) || []);
		return disps.filter((v, i, a) => (a.indexOf(v) === i));
	}


	/**
	 * Get all departments for a given discipline name.
	 * 
	 * @return {Array}
	 */
	getDisciplineDepartments(disp) {
		return (this.props.ddpl.departments?.filter(dept => (dept.discipline === disp)) || []);
	}


	/**
	 * Get all available departments.
	 *
	 * @return {Array}
	 */
	getDepartments() {
		return (this.props.ddpl.departments || []);
	}


	/**
	 * Get the projects from within our DDPL tree.
	 * 
	 * @return {Array}
	 */
	getProjects(dep) {
		if (!dep) dep = this.state.department;
		return (this.props.ddpl.projects?.filter(p => (p.department === dep)) || []);
	}


	/**
	 * Ge tthe contents to display within the list based on our step.
	 * 
	 * @return {Array}
	 */
	getListContent() {
		switch (this.state.step) {
			case 2:
				return this.props.ddpl.sites;
			case 1:
				return this.getProjects();
			default:
				return this.getDisciplines().map(d => this.getDisciplineDepartments(d)).flat();
		}
	}


	/**
	 * Get whether a given content item should be marked as selected.
	 *
	 * @param {Object} item List data item
	 * @return {Boolean}
	 */
	isItemSelected(item) {
		switch (this.state.step) {
			case 2:
				return (parseInt(this.state.site) === parseInt(item.id));
			case 1:
				return (parseInt(this.state.project) === parseInt(item.id));
			default:
				return (parseInt(this.state.department) === parseInt(item.id));
		}
	}


	/**
	 * Get the active department object from the tree.
	 * 
	 * @return {Object|null}
	 */
	get department() {
		const dep = this.state.department;
		return this.props.ddpl.departments?.filter(d => (d.id === dep))[0];
	}


	/**
	 * Get whether the department project should be marked as completed.
	 * 
	 * @return {Boolean}
	 */
	get departmentButtonCompleted() {
		return (!!this.state.department);
	}


	/**
	 * Get the initial value of the selected site in state.
	 *
	 * @return {Integer|null}
	 */
	get initialSiteSelection() {
		const regSite = this.props.activeRegistration.site?.id;
		if (regSite && this.props.isSiteMode && this.props.ddpl?.sites?.map(s => parseInt(s?.id))?.includes(regSite)) {
			return regSite;
		}
		else return null;
	}


	/**
	 * Get whether the project button should be marked as active.
	 * 
	 * @return {Boolean}
	 */
	get projectButtonActive() {
		return (!!this.state.department);
	}


	/**
	 * Get whether the project button should be marked as completed.
	 * 
	 * @return {Boolean}
	 */
	get projectButtonCompleted() {
		return (!!this.state.project);
	}


	/**
	 * Get whether to disable the project button.
	 * 
	 * @return {Boolean}
	 */
	get projectButtonDisabled() {
		if (!this.state.department) return true;
		else if (!this.getProjects()?.length) return true;
		else return false;
	}


	/**
	 * Get style overrides for the project button.
	 * 
	 * @return {Object|null}
	 */
	get projectButtonStyles() {
		if (!this.projectButtonDisabled) return null;
		else if (!this.state.department) return null;
		else return this.constructor.stepDisabledStyles;
	}


	/**
	 * Get whether to mark the site button as active.
	 * 
	 * @return {Boolean}
	 */
	get siteButtonActive() {
		if (this.state.project) return true;
		else if (this.state.step === 2) return true;
		else if (this.state.site) return true;
		else return false;
	}


	/**
	 * Get whether to mark the site button as completed.
	 * 
	 * @return {Boolean}
	 */
	get siteButtonCompleted() {
		return (!!this.state.site);
	}


	/**
	 * Get whether the site button should be disabled.
	 * 
	 * @return {Boolean}
	 */
	get siteButtonDisabled() {
		return !this.siteButtonActive;
	}


	/**
	 * Get the suggested department object.
	 * 
	 * @return {Object|undefined}
	 */
	get suggestedDept() {
		return this.getDepartments()?.find(d => (d?.id === this.props.ddpl.suggestion?.department));
	}


	/**
	 * Get whether we have departments available to select.
	 * 
	 * @return {Boolean}
	 */
	get hasDepartments() {
		return (!!this.props.ddpl.departments?.length);
	}


	/**
	 * Get whether we have projects available to select.
	 * 
	 * @return {Boolean}
	 */
	get hasProjects() {
		return (!!this.getProjects()?.length);
	}


	/**
	 * Get whether we have sites available to select.
	 * 
	 * @return {Boolean}
	 */
	get hasSites() {
		return (!!this.props.ddpl.sites?.length);
	}


	/**
	 * Get whether to render the department suggestion banner.
	 * 
	 * @return {Boolean}
	 */
	get shouldRenderDeptSuggestionBanner() {
		return ((this.state.step === 0) && this.props.ddpl.suggestion);
	}


	/**
	 * Get whether to render the "none" option.
	 *
	 * - Department: Use org-level option.
	 * - Project: Use project's department's option.
	 * - Site: Never allowed.
	 * 
	 * @return {Boolean}
	 */
	get shouldRenderNoneOption() {
		switch (this.state.step) {
			case 0:
				return this.props.activeRegistration?.ddplNone;
			case 1:
				return !!this.department?.select_project_none;
			default:
				return false;
		}
	}


	/**
	 * Get whether we should render the OK button.
	 * 
	 * @return {Boolean}
	 */
	get shouldRenderOkButton() {
		const department = this.state.department;
		const project = this.state.project;
		const site = (this.state.site || !this.hasSites);
		const dps = (department && project && site);
		const siteOnly = (!this.hasDepartments && site);
		return (dps || siteOnly);
	}


	/**
	 * Get the list title.
	 *
	 * @return {String}
	 */
	get title() {
		switch (this.state.step) {
			case 2:
				return "Site";
			case 1:
				return this.department?.name;
			default:
				return "Department";
		}
	}


	/**
	 * "None" option
	 * 
	 * @type {Object}
	 */
	static noneOption = {name: "(None)", id: true};

	/**
	 * List heading styles
	 * 
	 * @type {Object}
	 */
	static listHeadingStyles = {
		background: "var(--base-color)",
		position: "sticky",
		top: "15rem",
		zIndex: 1000
	};

	/**
	 * Styles for disabled step buttons.
	 * 
	 * @type {Object}
	 */
	static stepDisabledStyles = {
		opacity: 0.5,
		filter: "grayscale(1)"
	};

	/**
	 * Styles for the main stepper.
	 * 
	 * @type {Object}
	 */
	static stepperStyles = {
		background: "var(--base-color)",
		display: "grid",
		gap: rem(),
		gridAutoColumns: "1fr",
		gridAutoFlow: "column",
		position: "sticky",
		top: Styles.Hops.barHeight,
		zIndex: 100
	};

}

export default withMobile(withRegistration(DdplSelection));
