import React from "react";
import AsyncStatefulComponent from "Includes/AsyncStatefulComponent.js";
import TouchKeyboard from "./TouchKeyboard.js";
import TextFieldTouchKeyboardIcon from "./TextFieldTouchKeyboardIcon.js";
import {connect} from "react-redux";
import {TextField as MuiTextField} from "@material-ui/core";

/**
 * Text field
 *
 * A wrapper around Material UI's `TextField` with a simplified 
 * interface and support for our integrated touch keyboard.
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class TextField extends AsyncStatefulComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Focused?
			 * 
			 * @type {Boolean}
			 */
			focused: false,

			/**
			 * `window.scrollY` prior to focusing
			 *
			 * Used to reset scroll position on blur with touch keyboard.
			 * 
			 * @type {Integer|null}
			 */
			prefocusWindowScrollY: null,

			/**
			 * Touch keyboard forced to be visible?
			 * 
			 * @type {Boolean}
			 */
			touchKeyboard: false

		};

		/**
		 * Ref
		 */
		this.inputRef = React.createRef();

		/**
		 * Keyboard offset spacing component
		 */
		this.keyboardOffset = document.createElement("div");

		/**
		 * Touch keyboard icon
		 */
		this.touchKeyboardIcon = (
			<TextFieldTouchKeyboardIcon
				onClick={this.handleShowTouchKeyboard.bind(this)} />
		);

		/**
		 * Method binds
		 */
		this.handleBlur = this.handleBlur.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChangeKeyboard = this.handleChangeKeyboard.bind(this);
		this.handleFocus = this.handleFocus.bind(this);
		this.handleFocusTouch = this.handleFocusTouch.bind(this);

	}


	/**
	 * Component mounted.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		setTimeout(() => {
			const v = this.ref?.current?.value;
			if (v && (v !== this.props.value)) {
				if (this.props.onChange) {
					this.props.onChange(v);
				}
			}
		}, 250);
	}


	/**
	 * Blurred.
	 *
	 * @return {void}
	 */
	handleBlur() {

		this.setState({focused: false, touchKeyboard: false});

		if (this.state.prefocusWindowScrollY !== null) {
			window.scrollTo({top: this.state.prefocusWindowScrollY});
			this.setState({prefocusWindowScrollY: null});
		}

		if (this.keyboardOffset.parentNode) {
			this.keyboardOffset.parentNode.removeChild(this.keyboardOffset);
		}

		if (this.props.onBlur) this.props.onBlur();

	}


	/**
	 * Value changed.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	handleChange(e) {
		this.props.onChange(e.target.value);
	}


	/**
	 * Handle change due to touch keyboard value.
	 * 
	 * @return {void}
	 */
	handleChangeKeyboard(v, caret) {
		this.props.onChange(v);
		if (caret && this.ref?.current) {
			this.ref.current.setSelectionRange(caret, caret);
		}
	}


	/**
	 * Focused.
	 * 
	 * @return {void}
	 */
	handleFocus() {
		this.setState({focused: true}, this.handleFocusTouch);
	}


	/**
	 * Focused - extra handler when touch keyboard enabled.
	 * 
	 * @return {void}
	 */
	handleFocusTouch() {

		if (this.ref?.current && this.touchKeyboard) {

			const {top, height} = this.ref.current.getBoundingClientRect();
			const offset = this.constructor.touchKeyboardHeightOffset;

			if ((top + height) > (window.innerHeight - offset)) {

				if (!this.props.keyboardOffset) {
					const scroll = (window.innerHeight - offset - height);
					this.setState({prefocusWindowScrollY: window.scrollY});
					window.scrollTo({top: scroll});
				}
				else {
					// Material: FormControl > InputBase > Input (our `ref`)
					const offset = `${this.props.keyboardOffset}rem`;
					this.keyboardOffset.style.height = offset;
					this.ref.current.parentNode.parentNode.appendChild(this.keyboardOffset);
					this.ref.current.parentNode.parentNode.scrollIntoView();
				}

			}
		}

	}


	/**
	 * Make the touch keyboard force visible.
	 * 
	 * @return {void}
	 */
	handleShowTouchKeyboard() {
		if (this.ref) this.ref.current.focus();
		this.setState({touchKeyboard: true});
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<React.Fragment>
				<MuiTextField
					autoComplete={((this.props.autoComplete === false) ? "off" : undefined)}
					autoFocus={this.props.autoFocus}
					color={(this.props.color || "primary")}
					disabled={this.props.disabled}
					error={this.props.error}
					fullWidth={this.props.fullWidth}
					helperText={this.props.helperText}
					inputProps={this.props.inputProps}
					InputLabelProps={this.InputLabelProps}
					InputProps={this.InputProps}
					inputRef={this.ref}
					label={this.props.label}
					margin={(this.props.dense ? "dense" : undefined)}
					multiline={this.props.multiline}
					onBlur={this.handleBlur}
					onChange={this.handleChange}
					onFocus={this.handleFocus}
					placeholder={(this.props.placeholder || (this.props.placeholderLabel && this.props.label) || undefined)}
					required={this.props.required}
					rows={this.props.rows}
					style={this.props.style}
					type={this.props.type}
					value={(this.props.value || "")}
					variant={this.props.variant} />
				{(this.touchKeyboard ? this.renderTouchKeyboard() : null)}
			</React.Fragment>
		);
	}


	/**
	 * Render the touch keyboard.
	 * 
	 * @return {ReactNode}
	 */
	renderTouchKeyboard() {
		return (
			<TouchKeyboard
				onChange={this.handleChangeKeyboard}
				onEnter={this.props.onEnter}
				value={this.props.value} />
		);
	}


	/**
	 * Get our `endAdornment` for Material UI.
	 * 
	 * @return {ReactNode}
	 */
	get endAdornment() {
		const keyboard = [
			this.props.enableTouchKeyboard,
			!this.props.touchKeyboard,
			!this.state.touchKeyboard,
			!this.props.disabled
		];
		return (keyboard.every(k => k) ? this.touchKeyboardIcon : null);
	}


	/**
	 * Get our input ref.
	 * 
	 * @return {ReactRef}
	 */
	get ref() {
		return (this.props.inputRef || this.inputRef);
	}


	/**
	 * Get whether to render the touch keyboard.
	 * 
	 * @return {Boolean}
	 */
	get touchKeyboard() {
		if (!this.state.focused) return false;
		else return (this.props.touchKeyboard || this.state.touchKeyboard);
	}


	/**
	 * Get our `InputLabelProps` for Material UI.
	 * 
	 * @return {Object}
	 */
	get InputLabelProps() {
		return {shrink: this.props.shrink, ...this.props.InputLabelProps};
	}


	/**
	 * Get our `InputProps` for Material UI.
	 * 
	 * @return {Object}
	 */
	get InputProps() {
		return {endAdornment: this.endAdornment, ...this.props.InputProps};
	}


	/**
	 * Touch keyboard height
	 *
	 * This is used as an offset when scrolling into view with touch keyboard.
	 *
	 * The value should match the pixel height of the touch keyboard.
	 * 
	 * @type {Integer}
	 */
	static touchKeyboardHeightOffset = 240;

}

export default connect(({touchKeyboard}) => ({touchKeyboard}))(TextField);
