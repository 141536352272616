import React from "react";

/**
 * Form component
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Form extends React.Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * `ref`
		 * 
		 * @type {ReactRef}
		 */
		this.form = React.createRef();

		/**
		 * Method binds
		 */
		this.handleSubmit = this.handleSubmit.bind(this);

	}


	/**
	 * Submitted.
	 * 
	 * @param {Event} e
	 * @return {void}
	 */
	handleSubmit(e) {
		e.preventDefault();
		this.submit();
	}


	/**
	 * Submit.
	 * 
	 * @return {void}
	 */
	submit() {
		if (this.form.current?.reportValidity && this.form.current?.reportValidity()) {
			this.props.onSubmit();
		}
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<form onSubmit={this.handleSubmit} ref={this.form}>
				{this.props.children}
			</form>
		);
	}

}

export default Form;
