/**
 * Record object
 *
 * Represents a record we are creating and going to submit to the API.
 *
 * @package HOPS
 * @subpackage Records
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Record {

	/**
	 * Construct an instance.
	 *
	 * @param {Object} record Record data to submit to API
	 * @param {Registration} registration Registration object creating the record
	 * @param {String|null} username optional User name e.g. to display in submission confirmation UI
	 * @return {self}
	 */
	constructor(record, registration, username) {

		/**
		 * Record data to submit to API
		 *
		 * @type {Object}
		 */
		this.record = record;

		/**
		 * Registration object creating the record
		 *
		 * @type {Registration}
		 */
		this.registration = registration;

		/**
		 * User name (e.g. to display in submission confirmation UI)
		 *
		 * @type {String|null}
		 */
		this.username = username;

	}

}

export default Record;
