import React from "react";
import Theme from "Resources/Theme.json";
import {connect} from "react-redux";
import {ThemeProvider as ThemeProviderMui, createMuiTheme as createTheme} from "@material-ui/core";

/**
 * Theme provider
 *
 * @package HOPS
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class ThemeProvider extends React.PureComponent {

	/**
	 * State
	 * 
	 * @type {Object}
	 */
	state = {

		theme: this.theme

	};


	/**
	 * Component mounted.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		this.updateTheme();
	}


	/**
	 * Component updated.
	 *
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (prevProps.dark !== this.props.dark) {
			this.updateTheme();
		}
	}


	/**
	 * Update our cached theme.
	 * 
	 * @return {void}
	 */
	updateTheme() {

		const theme = this.theme;
		this.setState({theme: this.theme});

		document.body.style.setProperty(
			"--base-color",
			theme.palette.background.default
		);
		document.body.style.setProperty(
			"--base-color-darker",
			(this.props.dark ? theme.palette.grey[800] : theme.palette.grey[300])
		);
		document.body.style.setProperty(
			"--border-color",
			theme.palette.divider
		);
		document.body.style.setProperty(
			"--focus-color",
			theme.palette.action.hover
		);
		document.body.style.setProperty(
			"--paper-color",
			theme.palette.background.paper
		);
		document.body.style.setProperty(
			"--select-color",
			theme.palette.action.selected
		);
		document.body.style.setProperty(
			"--text-color",
			theme.palette.text.primary
		);

	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<ThemeProviderMui theme={this.state.theme}>
				{this.props.children}
			</ThemeProviderMui>
		);
	}


	/**
	 * Get current theme object.
	 *
	 * @return {Object}
	 */
	get theme() {
		const theme = {...Theme.App};
		theme.palette.type = (this.props.dark ? "dark" : "light");
		return createTheme(theme);
	}

}

export default connect(({dark}) => ({dark}))(ThemeProvider);
