import React from "react";
import {ToggleButtonGroup} from "@material-ui/lab";

/**
 * Toggler
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Toggler extends React.Component {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<ToggleButtonGroup
				color="primary"
				exclusive={true}
				onChange={this.props.onChange}
				style={this.constructor.styles}
				value={this.props.value}>
				{this.props.children}
			</ToggleButtonGroup>
		);
	}


	/**
	 * Styles.
	 * 
	 * @type {Object}
	 */
	static styles = {justifyContent: "center"};

}

export default Toggler;
