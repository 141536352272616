import React from "react";
import Flex from "Components/Flex.js";
import String from "Components/String.js";
import Strings from "Resources/Strings.json";
import TextField from "Components/TextField.js";

/**
 * User message input
 *
 * Lets users input a message at sign-in time.
 * 
 * @package HOPS
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class UserMessageInput extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Flex>
				<String
					bold={true}
					str={Strings.userMessageInput.label} />
				<TextField
					inputProps={this.constructor.inputProps}
					helperText={Strings.userMessageInput.caption}
					multiline={true}
					placeholder={Strings.userMessageInput.input}
					onChange={this.props.onChange}
					rows={3}
					shrink={true}
					value={this.props.message}
					variant="outlined" />
			</Flex>
		);
	}


	/**
	 * `inputProps`
	 * 
	 * @type {Object}
	 */
	static inputProps = {maxLength: 250};

}

export default UserMessageInput;
