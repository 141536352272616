/**
 * Permission service
 *
 * @package HOPS
 * @subpackage Services
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class PermissionService {

	/**
	 * Request a named permission in `permissions`.
	 *
	 * @param {String} permission
	 * @return {Promise}
	 */
	static request(permission) {
		return this.permissions[permission].prompt();
	}


	/**
	 * Get required permission statuses.
	 *
	 * Returns an object indexed by permission name in `permissions` 
	 * with a boolean value indicating whether we have permission.
	 *
	 * Rejects if the permissions API is not available.
	 *
	 * @return {Promise}
	 */
	static getPermissionStatuses() {
		return new Promise((resolve, reject) => {
			const perms = {};
			const promises = [];
			if (!navigator.permissions) reject();

			Object.keys(this.permissions).forEach(permission => {
				const desc = {name: this.permissions[permission].descriptor};
				promises.push(navigator.permissions.query(desc).then(s => {
					perms[permission] = (s.state === "granted");
				}).catch(() => reject()));
			});

			Promise.all(promises).then(() => resolve(perms));
		});
	}


	/**
	 * Get whether all required permissions are granted.
	 *
	 * @return {Boolean}
	 */
	static hasAllPermissions() {
		return this.getPermissionStatuses().then(perms => {
			return Object.values(perms).every(perm => (perm === true));
		});
	}


	/**
	 * Get an object describing all required permissions.
	 *
	 * Indexed by internal name. Values are objects with `descriptor`, 
	 * the name of the Permissions API descriptor for the permission, 
	 * and `prompt`, a function to run to request permission from the user.
	 *
	 * @type {Object}
	 */
	static permissions = {
		camera: {
			descriptor: "camera",
			prompt() {
				return new Promise((resolve, reject) => {
					const media = navigator.mediaDevices;
					if (!media) reject();
					media.getUserMedia({video: {}}).then(v => {

						v.getTracks().forEach(t => {
							t.stop();
						});

						resolve();

					}).catch(() => reject());
				});
			}
		},
		location: {
			descriptor: "geolocation",
			prompt() {
				return new Promise((resolve, reject) => {
					const loc = navigator.geolocation;
					if (!loc) reject();
					loc.getCurrentPosition(() => resolve(), () => reject());
				});
			}
		}
	};

}

export default PermissionService;
