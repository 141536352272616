import React from "react";
import Button from "Components/Button.js";
import Container from "Components/Container.js";
import Form from "Components/Form.js";
import CardService from "Services/CardService.js";
import RegistrationService from "Services/RegistrationService.js";
import TextField from "Components/TextField.js";
import Strings from "Resources/Strings.json";

/**
 * Card registration form
 *
 * @package HOPS
 * @subpackage Cards
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class CardRegistrationForm extends React.Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * `ref`
		 *
		 * @type {ReactRef}
		 */
		this.form = (props.formRef || React.createRef());

		/**
		 * Method binds
		 */
		this.handleId = this.handleId.bind(this);

	}


	/**
	 * ID changed.
	 *
	 * @param {String} id
	 * @return {void}
	 */
	handleId(id) {
		this.props.onChangeId(CardService.getUiCardIdInput(id));
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Form onSubmit={this.props.onSubmit} ref={this.form}>
				<Container>
					<TextField
						autoFocus
						disabled={this.props.loading}
						error={this.idConflicts}
						enableTouchKeyboard={this.props.enableTouchKeyboard}
						fullWidth={true}
						helperText={this.idHelperText}
						inputProps={this.constructor.idInputProps}
						label={Strings.welcome.homei.input}
						onChange={this.handleId}
						required={true}
						type="tel"
						value={this.props.id} />
					<TextField
						disabled={this.props.loading}
						enableTouchKeyboard={this.props.enableTouchKeyboard}
						fullWidth={true}
						helperText={Strings.welcome.homei.inputh_checkcode}
						label={Strings.welcome.homei.input_checkcode}
						onChange={this.props.onChangeCheckcode}
						required={true}
						value={this.props.checkcode} />
					{(this.props.withSubmit ? this.renderSubmit() : null)}
				</Container>
			</Form>
		);
	}


	/**
	 * Render submit button.
	 * 
	 * @return {ReactNode}
	 */
	renderSubmit() {
		return (
			<Container columns="max-content" justifyContent="center" my={1}>
				<Button disabled={this.props.loading} label="OK" type="submit" />
			</Container>
		);
	}


	/**
	 * Get whether our ID conflicts with an existing registration.
	 * 
	 * @return {Boolean}
	 */
	get idConflicts() {
		const idr = CardService.getCardIdFromUiString(this.props.id);
		return RegistrationService.isRegistered(idr);
	}


	/**
	 * Get the ID input helper text.
	 * 
	 * @return {String}
	 */
	get idHelperText() {
		if (this.idConflicts) return Strings.about.cards.alreadyRegistered;
		else return Strings.welcome.homei.inputh;
	}

	/**
	 * Length of input strings.
	 * 
	 * @type {Number}
	 */
	static inputLength = CardService.uiCardIdLength;

	/**
	 * ID input props.
	 * 
	 * @type {Object}
	 */
	static idInputProps = {
		maxLength: this.inputLength,
		minLength: this.inputLength
	};

}

export default CardRegistrationForm;
