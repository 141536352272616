import React from "react";
import Styles from "Resources/Theme.json";
import Strings from "Resources/Strings.json";
import IconButtonApp from "./IconButtonApp.js";
import IconButtonHome from "./IconButtonHome.js";
import withRegistration from "Hoc/withRegistration.js";
import {connect} from "react-redux";
import * as mui from "@material-ui/core";

/**
 * Header
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Header extends React.Component {

	/**
	 * Render the component.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<mui.AppBar position="sticky">
				<mui.Box
					maxWidth={Styles.Hops.mainWidth}
					mx="auto"
					width="100%">

					<mui.Toolbar style={this.constructor.toolbarStyles}>
						<IconButtonHome />

						<mui.Typography
							style={this.constructor.titleStyles}
							variant="h6">
							{Strings.about.app.title}
						</mui.Typography>

						<mui.Hidden xsDown>
							<mui.Typography
								style={this.constructor.textStyles}
								variant="h6">
								{this.text}
							</mui.Typography>
						</mui.Hidden>

						{this.sync}
					</mui.Toolbar>
				</mui.Box>
			</mui.AppBar>
		);
	}


	/**
	 * Get sync icon.
	 *
	 * @return {ReactNode}
	 */
	get sync() {
		return (this.props.isRegistered ? this.syncIcon : null);
	}


	/**
	 * Get sync icon element.
	 *
	 * @return {ReactNode}
	 */
	get syncIcon() {
		const {syncing, pending} = this.props.sync;
		return <IconButtonApp syncing={syncing} pending={pending} />;
	}


	/**
	 * Get header text.
	 *
	 * @return {ReactNode}
	 */
	get text() {
		if (this.props.registrations.length === 1) {
			return this.props.registrations[0].name;
		}
		else return null;
	}


	/**
	 * Text styles.
	 * 
	 * @type {Object}
	 */
	static textStyles = {
		textAlign: "right"
	};

	/**
	 * Title styles.
	 * 
	 * @type {Object}
	 */
	static titleStyles = {
		flexGrow: 1
	};

	/**
	 * Toolbar styles.
	 * 
	 * @type {Object}
	 */
	static toolbarStyles = {
		minHeight: Styles.Hops.barHeight,
		padding: 0
	};

	/**
	 * Redux state.
	 *
	 * @param {Object} state
	 * @return {Object}
	 */
	static mapStateToProps(state) {
		return {
			sync: {
				syncing: state.syncing,
				pending: ((state.sync.length > 0))
			}
		};
	}

}

export default connect(Header.mapStateToProps)(withRegistration(Header));
