import React from "react";
import Button from "Components/Button.js";
import Container from "Components/Container.js";
import Form from "Components/Form.js";
import TextField from "Components/TextField.js";
import Strings from "Resources/Strings.json";

/**
 * Device registration form
 *
 * @package HOPS
 * @subpackage Devices
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class DeviceRegistrationForm extends React.Component {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Form onSubmit={this.props.onSubmit}>
				<Container>
					<TextField
						autoFocus
						disabled={this.props.loading}
						enableTouchKeyboard={this.props.enableTouchKeyboard}
						fullWidth={true}
						label={Strings.welcome.home.input}
						onChange={this.props.onChangeId}
						required={true}
						type="password"
						value={this.props.id} />
					<Container
						columns="max-content"
						justifyContent="center"
						py={1}>
						<Button disabled={this.props.loading} label="OK" type="submit" />
					</Container>
				</Container>
			</Form>
		);
	}

}

export default DeviceRegistrationForm;
