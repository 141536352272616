import React from "react";
import AppService from "Services/AppService.js";
import withSnack from "Hoc/withSnack.js";
import {connect} from "react-redux";

/**
 * Update complete alert
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class UpdateCompleteAlert extends React.Component {

	/**
	 * Alert.
	 *
	 * @return {void}
	 */
	alert() {
		this.props.snack(
			`App updated to ${AppService.getAppVersionUi()}.`,
			"success"
		);
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return null;
	}


	/**
	 * Component mounted.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		if (this.props.updated) this.alert();
	}

}

export default connect(({updated}) => ({updated}))(withSnack(UpdateCompleteAlert));
