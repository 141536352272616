import React from "react";
import Button from "Components/Button.js";
import Flex from "Components/Flex.js";
import String from "Components/String.js";
import View from "App/View.js";

/**
 * Error gate component
 * 
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class ErrorGate extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<View notCentred={true} top={true}>
				<String
					str="Unhandled error"
					variant="h5" />
				<Flex gap={0.5}>
					<String
						color="textSecondary"
						str="We're sorry - something's gone wrong." />
					<String
						color="textSecondary"
						str="Please try what you were doing again." />
					<String
						color="textSecondary"
						str="Your device might not support HOPS Time Register if you keep seeing this." />
				</Flex>
				<div>
					<Button
						label="Restart App"
						onClick={() => window.location.reload()}
						variant="outlined" />
				</div>
			</View>
		);
	}

}

export default ErrorGate;
