import React from "react";
import AsyncStatefulComponent from "Includes/AsyncStatefulComponent.js";
import AboutSection from "./AboutSection.js";
import Admin from "Components/Admin.js";
import AppService from "Services/AppService.js";
import Button from "Components/Button.js";
import CardResetPrompt from "Components/CardResetPrompt.js";
import Container from "Components/Container.js";
import LabelTable from "Components/LabelTable.js";
import Link from "Components/Link.js";
import String from "Components/String.js";
import Strings from "Resources/Strings.json";
import clear from "Dispatchers/Clear.js";
import reset from "Dispatchers/Reset.js";
import withMobile from "Hoc/withMobile.js";
import withRegistration from "Hoc/withRegistration.js";
import withTaskStatus from "Hoc/withTaskStatus.js";
import {connect} from "react-redux";
import {TableRow, TableCell} from "@material-ui/core";

/**
 * About app section
 * 
 * @package HOPS
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class AboutApp extends AsyncStatefulComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {Object}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Admin dialogs visible
			 *
			 * @type {Boolean}
			 */
			admin: false,

			/**
			 * Render "clear sync queue" prompt
			 *
			 * @type {Boolean}
			 */
			clear: false,

			/**
			 * Render "reset device" prompt
			 *
			 * @type {Boolean}
			 */
			reset: false,

			/**
			 * Render "reset card" prompt
			 * 
			 * @type {Boolean}
			 */
			resetCard: false

		};

		/**
		 * Method binds
		 */
		this.handleClear = this.handleClear.bind(this);
		this.handleCloseDialogs = this.handleCloseDialogs.bind(this);
		this.handleReset = this.handleReset.bind(this);
		this.clear = this.clear.bind(this);
		this.reset = this.reset.bind(this);

	}


	/**
	 * Clear clicked.
	 * 
	 * @return {void}
	 */
	handleClear() {
		this.setState({admin: true, clear: true, reset: false});
	}


	/**
	 * Clear all dialogs.
	 * 
	 * @return {void}
	 */
	handleCloseDialogs() {
		this.setState({admin: false, resetCard: false});
	}


	/**
	 * Reset clicked.
	 * 
	 * @return {void} 
	 */
	handleReset() {
		if (this.props.isDevice) {
			this.setState({admin: true, clear: false, reset: true});
		}
		else this.setState({resetCard: true});
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<AboutSection label={Strings.about.app.title}>
				<TableRow>
					<LabelTable>{Strings.about.app.app}</LabelTable>
					<TableCell>
						<Container gap={0.5}>
							<String str={AppService.getAppVersionUi()} />
							{((this.props.update && !this.props.activeTasks) ? this.renderUpdate() : null)}
						</Container>
					</TableCell>
				</TableRow>
				<TableRow>
					<LabelTable>{this.strings.admin}</LabelTable>
					<TableCell>
						<Container
							columns="max-content"
							columnar={!this.props.isMobile}
							style={this.constructor.btnStyles}>
							<Button
								disabled={this.props.activeTasks}
								label={this.strings.reset}
								onClick={this.handleReset}
								variant="outlined" />
							{(this.props.isDevice ? this.renderClearButton() : null)}
						</Container>
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell colSpan={2}>
						<String str={this.strings.help} />
					</TableCell>
				</TableRow>

				<Admin
					active={this.state.admin}
					disabled={this.props.activeTasks}
					msg={(this.state.clear ? Strings.about.app.clearPrompt : Strings.about.app.resetPrompt)}
					onAuth={(this.state.clear ? this.clear : this.reset)}
					onCancel={this.handleCloseDialogs}>
				</Admin>
				<CardResetPrompt
					disabled={this.props.activeTasks}
					open={this.state.resetCard}
					onClose={this.handleCloseDialogs} />
			</AboutSection>
		);
	}


	/**
	 * Render the "clear uploads" button.
	 *
	 * @return {ReactNode}
	 */
	renderClearButton() {
		return (
			<Button
				disabled={this.props.activeTasks}
				label={Strings.about.app.clear}
				onClick={this.handleClear}
				variant="outlined" />
		);
	}


	/**
	 * Render update controls.
	 *
	 * @return {ReactNode}
	 */
	renderUpdate() {
		return (
			<Link
				label={`Update to #${this.props.update}`}
				onClick={AppService.update} />
		);
	}


	/**
	 * Clearing sync queue.
	 *
	 * @return {void}
	 */
	clear() {
		clear();
		this.handleCloseDialogs();
	}


	/**
	 * Resetting the device.
	 *
	 * @return {void}
	 */
	reset() {
		reset();
		this.handleCloseDialogs();
	}


	/**
	 * Strings.
	 * 
	 * @return {Object}
	 */
	get strings() {
		return {
			admin: (this.props.isDevice ? Strings.about.app.admin : Strings.about.app.admini),
			help: (this.props.isDevice ? Strings.about.app.help : Strings.about.app.helpi),
			reset: (this.props.isDevice ? Strings.about.app.reset : Strings.about.app.reseti)
		};
	}


	/**
	 * Button container styles.
	 * 
	 * @type {Object}
	 */
	static btnStyles = {gridAutoColumns: "max-content"};

}

export default connect(({update}) => ({update}))(withMobile(withRegistration(withTaskStatus(AboutApp))));
