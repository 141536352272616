import React from "react";
import {Typography} from "@material-ui/core";

export default props => {
	return (
		<div>
			{
				props.p.map((p, i) => {
					return (
						<Typography
							key={i}
							paragraph
							style={{textAlign: (props.centre ? "center" : null)}}
							variant={(props.variant || "body1")}>
							{p}
						</Typography>
					);
				})
			}
		</div>
	);
};
