import React from "react";
import Actions from "Resources/Actions.json";
import Divider from "@material-ui/core/Divider";
import Flex from "Components/Flex.js";
import Paper from "@material-ui/core/Paper";
import SiteScreenSlider from "./SiteScreenSlider.js";
import String from "Components/String.js";
import scss from "./SiteScreenUser.module.scss";

/**
 * User display item on the site screen
 * 
 * @package HOPS
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class SiteScreenUser extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Paper className={scss.paper} variant="outlined">
				<Flex alignItems="center" className={scss.flex} px={1} py={1}>
					<Flex gap={0.5}>
						<String
							bold={true}
							centre={true}
							str={this.strings.userName}
							title={`#${this.props.record?.User?.Id}`}
							variant="h6" />
						{(this.props.record?.Record?.Site && this.renderSite())}
					</Flex>
					{this.constructor.renderDivider()}
					{(this.props.record?.Record?.Message && this.renderMessage())}
					<SiteScreenSlider
						className={scss.slider}
						in={this.signedIn} />
				</Flex>
			</Paper>
		);
	}


	/**
	 * Render the user message text.
	 * 
	 * @return {ReactNode}
	 */
	renderMessage() {
		return (
			<>
				<String
					centre={true}
					className={scss.message}
					color="textSecondary"
					str={this.props.record?.Record?.Message.replace(/\n/g, " ")} />
				{this.constructor.renderDivider()}
			</>
		);
	}


	/**
	 * Render the site name text.
	 * 
	 * @return {ReactNode}
	 */
	renderSite() {
		return (
			<String
				centre={true}
				color={((this.props.record?.Record?.Site?.Id !== this.props.registration?.site?.id) ? "error" : undefined)}
				str={this.props.record?.Record?.Site?.Name} />
		);
	}


	/**
	 * Get whether the user is currently signed-in.
	 *
	 * (We have to be careful - the user is signed-in if the latest 
	 * record is an in, or a change location (meaning they were already in).)
	 *
	 * @return {Boolean}
	 */
	get signedIn() {
		const action = this.props.record?.Record?.Action;
		return [Actions.record.in, Actions.record.location].includes(action);
	}


	/**
	 * Get dynamic strings.
	 *
	 * @return {Object}
	 */
	get strings() {
		return {
			userName: `${this.user?.Fname} ${this.user?.Sname}`
		};
	}


	/**
	 * Get the user object from our record.
	 * 
	 * @return {Object}
	 */
	get user() {
		return this.props.record?.User;
	}


	/**
	 * Render a divider.
	 * 
	 * @return {ReactNode}
	 */
	static renderDivider() {
		return <Divider className={scss.divider} />;
	}

}

export default SiteScreenUser;
