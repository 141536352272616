/**
 * Host
 *
 * @package HOPS
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Host {

	/**
	 * App service worker
	 *
	 * Relative to app server root.
	 *
	 * @type {String}
	 */
	static sw = "/sw.js";


	/**
	 * Attempt to register the service worker.
	 * 
	 * @return {void}
	 */
	static register() {
		if ("serviceWorker" in navigator) {
			this.registerMain().catch(() => {});
		}
	}


	/**
	 * Call `navigator.serviceWorker.register` for the app.
	 *
	 * DO NOT CALL DIRECTLY - always use `register()` instead!
	 *
	 * @return {Promise}
	 */
	static registerMain() {
		return navigator.serviceWorker.register(this.sw);
	}

}

export default Host;
