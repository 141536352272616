import React from "react";
import String from "Components/String.js";
import scss from "./SiteScreenSlider.module.scss";

/**
 * In/out slider display on the site screen
 * 
 * @package HOPS
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class SiteScreenSlider extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<div className={`${scss.slider} ${this.props.className}`}>
				<div className={`${scss.side} ${scss.in} ${(this.props.in ? scss.active : "")}`}><String str="In" /></div>
				<div className={`${scss.side} ${scss.out} ${(!this.props.in ? scss.active : "")}`}><String str="Out" /></div>
			</div>
		);
	}

}

export default SiteScreenSlider;
