import React from "react";
import Strings from "Resources/Strings.json";
import Timer from "react-idle-timer";
import withRegistration from "Hoc/withRegistration.js";
import {withRouter} from "react-router-dom";
import {withSnackbar} from "notistack";

/**
 * Idle timer
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Idle extends React.Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Timeout for inactivity (ms)
		 *
		 * @type {Integer}
		 */
		this.idle = (30 * 1000);

		/**
		 * Reference to the timer instance
		 *
		 * @type {ReactRef}
		 */
		this.timer = null;

		/**
		 * Method binds
		 */
		this.handleTimerRef = this.handleTimerRef.bind(this);
		this.onIdle = this.onIdle.bind(this);

	}


	/**
	 * Component updated.
	 *
	 * We must reset the timer if the route has changed.
	 * 
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			this.timer.reset();
		}
	}


	/**
	 * Timer reference updated.
	 *
	 * @param {ReactIdleTimer} ref
	 * @return {void}
	 */
	handleTimerRef(ref) {
		this.timer = ref;
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Timer
				onIdle={this.onIdle}
				ref={this.handleTimerRef}
				timeout={this.idle} />
		);
	}


	/**
	 * We're idle!
	 *
	 * @return {void}
	 */
	onIdle() {
		if (!this.shouldIdle) return;
		this.props.history.push("/");
		this.props.enqueueSnackbar(Strings.idle);
	}


	/**
	 * Get whether we should idle.
	 *
	 * @return {void}
	 */
	get shouldIdle() {
		const conditions = [
			this.constructor.ignoreUris.includes(this.props.location.pathname),
			!this.props.isDevice
		];
		return (conditions.filter(c => c).length === 0);
	}


	/**
	 * URIs which when active should disable the idle monitor
	 *
	 * @type {Array}
	 */
	static ignoreUris = ["", "/", "/welcome", "/permissions"];

}

export default withRegistration(withRouter(withSnackbar(Idle)));
