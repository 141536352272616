import React from "react";
import AsyncStatefulComponent from "Includes/AsyncStatefulComponent.js";
import CardRegistrationForm from "Cards/CardRegistrationForm.js";
import CardService from "Services/CardService.js";
import Container from "Components/Container.js";
import DialogBase from "Components/DialogBase.js";
import RegistrationErrorDialog from "Registration/RegistrationErrorDialog.js";
import RegistrationService from "Services/RegistrationService.js";
import withSnack from "Hoc/withSnack.js";
import Strings from "Resources/Strings.json";
import {LinearProgress} from "@material-ui/core";

/**
 * Add card dialog
 *
 * @package HOPS
 * @subpackage Cards
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class AddCardDialog extends AsyncStatefulComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = this.constructor.initialState;

		/**
		 * Form `ref`
		 * 
		 * @type {ReactRef}
		 */
		this.formRef = React.createRef();

		/**
		 * Method binds
		 */
		this.handleChangeCheckcode = this.handleChangeCheckcode.bind(this);
		this.handleChangeId = this.handleChangeId.bind(this);
		this.handleCloseErrorDialog = this.handleCloseErrorDialog.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleWantsSubmit = this.handleWantsSubmit.bind(this);

	}


	/**
	 * Component updated.
	 *
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (prevProps.open !== this.props.open) {
			this.setState(this.constructor.initialState);
		}
	}


	/**
	 * Checkcode changed.
	 *
	 * @param {String} checkcode
	 * @return {void}
	 */
	handleChangeCheckcode(checkcode) {
		this.setState({checkcode});
	}


	/**
	 * ID changed.
	 *
	 * @param {String} id
	 * @return {void}
	 */
	handleChangeId(id) {
		const idr = CardService.getCardIdFromUiString(id);
		const idConflict = RegistrationService.isRegistered(idr);
		this.setState({id, idConflict});
	}


	/**
	 * Close the error dialog.
	 * 
	 * @return {void}
	 */
	handleCloseErrorDialog() {
		this.setState({errorState: false});
	}


	/**
	 * Submitting.
	 * 
	 * @return {void}
	 */
	handleSubmit() {

		/**
		 * Loading
		 */
		this.setState({loading: true});

		/**
		 * Get data
		 */
		const id = CardService.getCardIdFromUiString(this.state.id);
		const checkcode = this.state.checkcode;

		/**
		 * Make the registration
		 */
		RegistrationService.registerCard(id, checkcode).then(() => {
			this.props.snack(Strings.about.cards.added, "success");
			this.props.onClose();
		}).catch(e => {
			this.setState({error: (e?.status || true), errorState: true});
		}).finally(() => {
			this.setState({loading: false});
		});

	}


	/**
	 * Submit wanted.
	 * 
	 * @return {void}
	 */
	handleWantsSubmit() {
		if (this.formRef.current) {
			this.formRef.current.form.current.submit();
		}
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<DialogBase
				canSubmit={(this.state.idConflict !== true)}
				disabled={(this.state.loading || this.props.disabled)}
				onClose={this.props.onClose}
				onOk={this.handleWantsSubmit}
				open={this.props.open}
				title={Strings.about.cards.add}>
				<Container pb={1}>
					<CardRegistrationForm
						checkcode={this.state.checkcode}
						loading={this.state.loading}
						id={this.state.id}
						onChangeCheckcode={this.handleChangeCheckcode}
						onChangeId={this.handleChangeId}
						onSubmit={this.handleSubmit}
						ref={this.formRef} />
					{(this.state.loading ? <LinearProgress /> : null)}
					<RegistrationErrorDialog
						e401={(this.state.error === 401)}
						e404={(this.state.error === 404)}
						e409={(this.state.error === 409)}
						individualsMode={true}
						onClose={this.handleCloseErrorDialog}
						open={this.state.errorState} />
				</Container>
			</DialogBase>
		);
	}


	/**
	 * Initial state.
	 * 
	 * @type {Object}
	 */
	static initialState = {

		/**
		 * ID (UI format)
		 *
		 * @type {String}
		 */
		id: "",

		/**
		 * Checkcode
		 *
		 * @type {String}
		 */
		checkcode: "",

		/**
		 * Loading?
		 * 
		 * @type {Boolean}
		 */
		loading: false,

		/**
		 * Error type
		 * 
		 * @type {mixed} `null` = none
		 */
		error: null,

		/**
		 * We have an error state?
		 * 
		 * @type {Boolean}
		 */
		errorState: false

	};

}

export default withSnack(AddCardDialog);
