import {createStore, applyMiddleware} from "redux";
import {createStateSyncMiddleware, initMessageListener} from "redux-state-sync";
import {composeWithDevTools} from "redux-devtools-extension";
import ObjectHelper from "Helpers/Obj.js";
import Reducer from "./Reducer.js";
import State from "./State.js";
import StatePersistent from "./StatePersistent.js";
import Storage from "./Storage.js";
import StoreMigrator from "./StoreMigrator.js";

/**
 * Store
 *
 * @package HOPS
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Store {

	/**
	 * Construct store.
	 *
	 * Updates the app version in the store.
	 * 
	 * @return {void}
	 */
	static construct() {

		/**
		 * Create the store
		 */
		const store = createStore(
			Reducer,
			this.initial,
			composeWithDevTools(applyMiddleware(createStateSyncMiddleware({broadcastChannelOption: {webWorkerSupport: false}})))
		);

		/**
		 * Initialise `redux-state-sync`
		 */
		initMessageListener(store);

		/**
		 * Update the store
		 */
		this.update(store);

		/**
		 * We're done!
		 */
		return store;

	}


	/**
	 * Update the app version in the store.
	 *
	 * Dispatches the `appv` event to the store with the current 
	 * app version if the `appv` value in the store is not our version.
	 *
	 * @param {Object} store
	 * @return {void}
	 */
	static update(store) {
		if (store.getState().appv !== process.env.REACT_APP_APP) {
			store.dispatch({type: "appv", appv: process.env.REACT_APP_APP});
		}
	}


	/**
	 * Get initial state.
	 * 
	 * @return {Object}
	 */
	static get initial() {
		const initial = {...State, ...StatePersistent};
		const hydrated = {...initial, ...Storage.get()};
		return StoreMigrator.migrate(ObjectHelper.setMissingKeys(hydrated, initial));
	}

}

export default Store.construct();
