import React from "react";
import Auth from "./Auth.js";
import Container from "Components/Container.js";
import Footer from "Components/Footer.js";
import Header from "Components/Header.js";
import Idle from "Components/Idle.js";
import Scroll from "Components/Scroll.js";
import UpdateCompleteAlert from "Components/UpdateCompleteAlert.js";
import About from "Views/About.js";
import Checkpoint from "Views/Checkpoint.js";
import ErrorGate from "Views/ErrorGate.js";
import F12 from "F12/F12.js";
import F12Dialog from "F12/F12Dialog.js";
import Done from "Views/Done.js";
import Ddpl from "Views/Ddpl.js";
import Home from "Views/Home.js";
import IndividualsMode from "Views/IndividualsMode.js";
import LoneWorking from "Views/LoneWorking.js";
import Notices from "Views/Notices.js";
import Permissions from "Views/Permissions.js";
import Welcome from "Views/Welcome.js";
import history from "Shims/History.js";
import {ErrorBoundary} from "@sentry/react";
import {Route, Router, Switch} from "react-router-dom";

/**
 * UI base
 *
 * @package HOPS
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Ui extends React.Component {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Router history={history}>
				<Container centred={true} style={this.constructor.styles}>
					<Header />
					<ErrorBoundary>
						<Scroll />
					</ErrorBoundary>
					<ErrorBoundary fallback={<ErrorGate />}>
						<Switch>
							<Route path="/welcome" component={Welcome} />
							<Auth>
								<Switch>
									<Route path="/app" component={About} />
									<Route path="/checkpoint" component={Checkpoint} />
									<Route path="/ddpl" component={Ddpl} />
									<Route path="/done" component={Done} />
									<Route path="/f12" component={F12} />
									<Route path="/id/:reg" component={IndividualsMode} />
									<Route path="/lw" component={LoneWorking} />
									<Route path="/notices" component={Notices} />
									<Route path="/permissions" component={Permissions} />
									<Route path="/" component={Home} />
								</Switch>
							</Auth>
						</Switch>
					</ErrorBoundary>
					<ErrorBoundary>
						<F12Dialog />
					</ErrorBoundary>
					<ErrorBoundary>
						<Footer />
					</ErrorBoundary>
					<ErrorBoundary>
						<Idle />
					</ErrorBoundary>
					<ErrorBoundary>
						<UpdateCompleteAlert />
					</ErrorBoundary>
				</Container>
			</Router>
		);
	}


	/**
	 * Styles.
	 * 
	 * @type {Object}
	 */
	static styles = {
		alignItems: "center",
		gridTemplateRows: "max-content auto max-content",
		minHeight: "100vh"
	};

}

export default Ui;
