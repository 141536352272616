import React from "react";
import rem from "Helpers/Rem.js";
import ReactHelper from "Helpers/React.js";

/**
 * Hero button icon
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class HeroButtonIcon extends React.Component {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		if (React.isValidElement(this.props.icon)) {
			return <div style={this.styles}>{this.props.icon}</div>;
		}
		else return ReactHelper.render(this.props.icon, {style: this.styles});
	}


	/**
	 * Dimensions.
	 * 
	 * @type {String} CSS
	 */
	get dims() {
		if (this.props.large) return rem(6);
		else if (this.props.small) return rem(2);
		else return rem(3);
	}


	/**
	 * Styles.
	 *
	 * @return {Object}
	 */
	get styles() {
		return {
			alignContent: "center",
			display: "grid",
			height: this.dims,
			left: "50%",
			justifyContent: "center",
			position: "absolute",
			top: (this.props.centred ? "40%" : rem(1)),
			transform: `translateX(-50%) ${(this.props.centred ? "translateY(-50%)" : "")}`,
			width: this.dims
		};
	}

}

export default HeroButtonIcon;
