import Store from "App/Store.js";
import history from "Shims/History.js";
import dispatchRecord from "Dispatchers/Record.js";
import dispatchRecordLast from "Dispatchers/RecordLast.js";
import dispatchRecordPending from "Dispatchers/RecordPending.js";
import Actions from "Resources/Actions.json";

/**
 * Register service
 *
 * Methods for interacting with register records.
 *
 * @package HOPS
 * @subpackage Services
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited.
 */
class RegisterService {

	/**
	 * Start a new record transaction.
	 *
	 * This automatically handles making the transaction and redirecting 
	 * to the next stage in the transaction sequence - for sign-ins, we 
	 * may need to collect a DDPL submission or redirect to notices.
	 *
	 * Note: The final stage in the sequence (currently notices) should call 
	 * the `record()` directly as we have no way to check it's occurred (note 
	 * this is just specific to the current notices stage); all of this would 
	 * benefit from significant future refactoring.
	 * 
	 * @param {Record} record
	 * @return {void}
	 */
	static transact(record) {

		/**
		 * Signing in
		 */
		if (record.record.action !== Actions.record.out) {

			/**
			 * Record's pending
			 */
			dispatchRecordPending(record);

			/**
			 * We need a DDPL submission
			 */
			if (record.registration.ddpl && !record.record.ddpl) {
				history.push("/ddpl");
				return;
			}

			/**
			 * User needs to acknowledge notices
			 */
			else if (record.registration.notices && !record.record.notices) {
				history.push("/notices");
				return;
			}

			/**
			 * User needs to be asked about lone working
			 */
			else if (record.registration.loneWorking && isNaN(record.record.lone_working)) {
				history.push("/lw");
				return;
			}

		}

		/**
		 * We can transact
		 */
		this.record(record);

	}


	/**
	 * Record a new record.
	 *
	 * We reroute to the homescreen automatically.
	 * 
	 * The acknowledgement snackbar is displayed automatically.
	 *
	 * DO NOT normally call this directly - use `transact()` instead, 
	 * as this *always* records the record immediately, with no consideration 
	 * of checking whether prerequisites (e.g. DDPL/notices) are met.
	 * 
	 * @param {Record} record
	 * @return {void}
	 */
	static record(record) {

		/**
		 * Dispatch the record
		 */
		dispatchRecord(record.record);
		dispatchRecordLast(record);
		dispatchRecordPending(null);

		/**
		 * Reroute to the homescreen
		 */
		history.push("/done");

	}


	/**
	 * Get the pending record if any.
	 * 
	 * @return {Object}
	 */
	static get pendingRecord() {
		return Store.getState().recordPending;
	}


	/**
	 * Get the device ID to use when consulting APIs (e.g. DDPL/notices) 
	 * with respect to the currently pending record - this returns the 
	 * device ID when it should be used, instead of the card ID, e.g. 
	 * in scenarios where the card ID was not verified; `null` otherwise 
	 * and means the pending record's card ID should be supplied instead.
	 * 
	 * @return {String|null} Device ID to use when a `string`.
	 */
	static get pendingRecordDeviceId() {
		const device = this.pendingRecord?.record.device;
		return ((!this.pendingRecord?.record.card_confirmed && device) || null);
	}

}

export default RegisterService;
