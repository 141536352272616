import React from "react";
import AsyncStatefulComponent from "Includes/AsyncStatefulComponent.js";
import Container from "Components/Container.js";
import Button from "Components/Button.js";
import Dialog from "Components/Dialog.js";
import Navigator from "App/Navigator.js";
import Paragraph from "Components/Paragraph.js";
import PermissionsPermissionItem from "./PermissionsPermissionItem.js";
import PermissionService from "Services/PermissionService.js";
import String from "Components/String.js";
import Strings from "Resources/Strings.json";
import View from "App/View.js";
import dispatchReset from "Dispatchers/Reset.js";
import PermissionsGrantedCheckmark from "./PermissionsGrantedCheckmark.js";
import {CircularProgress, Table, TableBody} from "@material-ui/core";

/**
 * Permissions screen
 *
 * @package HOPS
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Permissions extends AsyncStatefulComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Error
			 *
			 * @type {Boolean}
			 */
			error: false,

			/**
			 * Error (loading)
			 *
			 * @type {Boolean}
			 */
			errorLoad: false,

			/**
			 * Loading
			 *
			 * @type {Boolean}
			 */
			loading: true,

			/**
			 * Permissions
			 *
			 * @type {Object}
			 */
			permissions: {

				/**
				 * Camera
				 *
				 * @type {Boolean}
				 */
				camera: false,

				/**
				 * Location
				 *
				 * @type {Boolean}
				 */
				location: false

			}

		};

		/**
		 * Method binds
		 */
		this.handleClearError = this.handleClearError.bind(this);
		this.request = this.request.bind(this);

	}


	/**
	 * Component mounted.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		PermissionService.getPermissionStatuses().then(perms => {

			const permissions = this.state.permissions;

			Object.keys(perms).forEach(p => {
				permissions[p] = perms[p];
			});

			this.setState({permissions});

		}).catch(() => {
			this.setState({errorLoad: true});
		}).finally(() => {
			this.setState({loading: false});
		});
	}


	/**
	 * Clear error state.
	 * 
	 * @return {void}
	 */
	handleClearError() {
		this.setState({error: false});
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<View label={Strings.permissions.view}>
				{this.renderContent()}
				<Dialog
					onClose={this.handleClearError}
					open={this.state.error}
					title={Strings.permissions.error.title}>
					{Strings.permissions.error.message}
				</Dialog>
				<Dialog
					onClose={dispatchReset}
					open={this.state.errorLoad}
					title={Strings.permissions.errorLoad.title}>
					{Strings.permissions.errorLoad.message}
				</Dialog>
			</View>
		);
	}


	/**
	 * Render the content.
	 *
	 * @return {ReactNode}
	 */
	renderContent() {
		if (this.state.loading) return <CircularProgress disableShrink={true} />;
		else if (!this.state.errorLoad) return this.renderMain();
		else return <String str={Strings.permissions.errorLoad.main} />;
	}


	/**
	 * Render the main content.
	 *
	 * @return {ReactNode}
	 */
	renderMain() {
		return (
			<Container centred={true}>
				<Container mt={1}>
					<Paragraph p={Strings.permissions.help} />
				</Container>

				<Table style={this.constructor.styles}>
					<TableBody>
						<PermissionsPermissionItem
							control={this.permissionBtn("camera")}
							help={Strings.permissions.camera}
							label="Camera" />
						<PermissionsPermissionItem
							control={this.permissionBtn("location")}
							help={Strings.permissions.location}
							label="Location" />
					</TableBody>
				</Table>

				<Container mt={2}>
					<Button
						disabled={!this.gotAllPermissions()}
						label="OK"
						onClick={Navigator.home} />
				</Container>
			</Container>
		);
	}


	/**
	 * Get whether we've got all permissions.
	 *
	 * @return {void}
	 */
	gotAllPermissions() {
		return Object.values(this.state.permissions).every(p => p);
	}


	/**
	 * Get the permission grant control for a named permission.
	 *
	 * @param {String} permission
	 * @return {ReactNode}
	 */
	permissionBtn(permission) {
		if (this.state.permissions[permission] !== true) {
			return this.permissionButton(permission);
		}
		else return <PermissionsGrantedCheckmark />;
	}


	/**
	 * Attempt to request a named permission.
	 *
	 * @param {String} permission
	 * @return {void}
	 */
	request(permission) {
		this.setPermissionStatus(permission, null);
		PermissionService.request(permission).then(() => {
			this.setPermissionStatus(permission, true);
		}).catch(() => {
			this.setState({error: true});
			this.setPermissionStatus(permission, false);
		});
	}


	/**
	 * Create a permission grant button for a named permission.
	 *
	 * @param {String} permission
	 * @return {ReactNode}
	 */
	permissionButton(permission) {
		return (
			<Button
				disabled={(this.state.permissions[permission] === null)}
				label="Enable"
				onClick={this.request}
				value={permission} />
		);
	}


	/**
	 * Change the status value of a permission in state.
	 *
	 * @param {String} permission
	 * @param {mixed} status
	 * @return {void}
	 */
	setPermissionStatus(permission, status) {
		const permissions = this.state.permissions;
		permissions[permission] = status;
		this.setState({permissions});
	}


	/**
	 * Styles.
	 * 
	 * @type {Object}
	 */
	static styles = {maxWidth: "45rem"};

}

export default Permissions;
