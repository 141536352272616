import React from "react";
import Button from "Components/Button.js";
import * as mui from "@material-ui/core";

/**
 * Dialog base
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class DialogBase extends React.Component {

	/**
	 * Handle closure.
	 * 
	 * @param {Event} e
	 * @param {String} reason
	 * @return {void}
	 */
	handleClose = (e, reason) => {
		if ((reason !== "backdropClick") || !this.props.disabled) {
			this.props.onClose();
		}
	};


	/**
	 * Render
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<mui.Dialog
				disableEscapeKeyDown={this.props.disabled}
				disableScrollLock={true}
				fullWidth={this.props.fullWidth}
				onClose={this.handleClose}
				open={this.props.open}
				PaperProps={this.constructor.PaperProps}>
				<mui.DialogTitle>{this.props.title}</mui.DialogTitle>
				<mui.DialogContent>{this.props.children}</mui.DialogContent>
				<mui.DialogActions>
					{(this.props.onOk ? this.renderCancel() : null)}
					<Button
						disabled={(this.props.disabled || (this.props.canSubmit === false))}
						label={(this.props.okLabel || "OK")}
						onClick={(this.props.onOk || this.props.onClose)} />
				</mui.DialogActions>
			</mui.Dialog>
		);
	}


	/**
	 * Render cancel button.
	 * 
	 * @return {ReatNode}
	 */
	renderCancel() {
		return (
			<Button
				label="Cancel"
				disabled={this.props.disabled}
				onClick={this.props.onClose}
				variant="text" />
		);
	}


	/**
	 * `PaperProps`
	 *
	 * @type {Object}
	 */
	static PaperProps = {elevation: 0};

}

export default DialogBase;
