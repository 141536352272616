import React from "react";
import InfoIcon from "@material-ui/icons/Info";
import Theme from "Resources/Theme.json";
import {SnackbarProvider as Snack} from "notistack";

const iconStyles = {marginRight: "0.8rem"};

const icons = {default: <InfoIcon style={iconStyles} />};

export default ({children}) => (
	<Snack
		anchorOrigin={Theme.Hops.snackAnchor}
		iconVariant={icons}
		maxSnack={3}>
		{children}
	</Snack>
);
