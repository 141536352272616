import React from "react";
import Alert from "@material-ui/lab/Alert";
import Flex from "Components/Flex.js";
import String from "Components/String.js";

/**
 * Banner component
 *
 * An abstraction of Material's `Alert`.
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Banner extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Alert
				icon={this.props.icon}
				severity={this.props.severity}>
				<Flex gap={0.5}>
					<String bold={true} str={this.props.title} />
					{this.props.children}
				</Flex>
			</Alert>
		);
	}


	/**
	 * Title styles.
	 * 
	 * @type {Object}
	 */
	static titleStyles = {
		fontWeight: "bold"
	};

}

export default Banner;
