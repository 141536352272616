import React from "react";
import View from "App/View.js";
import Container from "Components/Container.js";
import HeroButton from "Components/HeroButton.js";
import String from "Components/String.js";
import Strings from "Resources/Strings.json";
import HopsService from "Services/HopsService.js";
import NoticesResource from "Resources/Notices.json";
import NoticeService from "Services/NoticeService.js";
import RegisterService from "Services/RegisterService.js";
import NoticesNotice from "./NoticesNotice.js";
import {withRouter} from "react-router-dom";
import {SignIn as SignInTheme} from "App/Theme.js";
import {CircularProgress, ThemeProvider} from "@material-ui/core";
import {PersonAdd as SignOnIcon} from "@material-ui/icons";
import AsyncStatefulComponent from "Includes/AsyncStatefulComponent.js";

/**
 * Notices view
 *
 * Note: Notices are rendered from the store only!
 * 
 * Please refer to the source for details.
 * 
 * @package HOPS
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Notices extends AsyncStatefulComponent {

	/**
	 * Constructor.
	 * 
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 * 
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Read notice IDs
			 *
			 * @type {Array}
			 */
			read: [],

			/**
			 * Active notices
			 *
			 * @type {Array}
			 */
			notices: [],

			/**
			 * Loading?
			 * 
			 * @type {Boolean}
			 */
			loading: true

		};

		/**
		 * Method binds
		 */
		this.handleAcknowledge = this.handleAcknowledge.bind(this);
		this.handleDismiss = this.handleDismiss.bind(this);

	}


	/**
	 * Mounted.
	 *
	 * We only render to individuals.
	 *
	 * @return {void}
	 */
	componentDidMount() {

		/**
		 * Sign-in needed
		 */
		if (!RegisterService.pendingRecord?.record) {
			this.props.history.replace("/");
		}

		/**
		 * Card + device
		 */
		const card = RegisterService.pendingRecord?.record.card;
		const dept = RegisterService.pendingRecord?.record.department;
		const device = RegisterService.pendingRecordDeviceId;

		/**
		 * Get all notices
		 */
		HopsService.getNotices((device || card), (device !== null), dept).then(notices => {
			this.setState({notices: (notices || [])});
		}).catch(() => {
			this.setState({notices: [NoticeService.setNoticeDate(NoticesResource.NetworkError)]});
		}).finally(() => {
			this.setState({loading: false});
		});

	}


	/**
	 * Acknowledged notice.
	 *
	 * @param {Integer} id
	 * @return {void}
	 */
	handleAcknowledge(id) {
		this.setState({read: [...this.state.read, id]});
	}


	/**
	 * Dismiss the screen.
	 * 
	 * @return {void}
	 */
	handleDismiss() {

		const record = RegisterService.pendingRecord;

		if (record?.record) {
			record.record.notices = true;
			RegisterService.transact(record);
		}
		else this.props.history.replace("/");

	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<View gap={2} label={Strings.notices.label} style={this.constructor.styles}>
				{(this.hasNotices ? this.constructor.renderCaption() : null)}
				{this.renderContent()}
				{(this.done ? this.renderDone() : null)}
			</View>
		);
	}


	/**
	 * Render main content.
	 * 
	 * @return {ReactNode}
	 */
	renderContent() {
		if (this.state.loading) return <CircularProgress disableShrink={true} />;
		else if (this.hasNotices) return this.renderNotices();
		else return this.constructor.renderEmpty();
	}


	/**
	 * Render done state.
	 * 
	 * @return {ReactNode}
	 */
	renderDone() {
		return (
			<Container>
				<ThemeProvider theme={SignInTheme}>
					<HeroButton
						bold={true}
						icon={SignOnIcon}
						label="Continue"
						onClick={this.handleDismiss}
						small={true}
						suggestion={true}
						theme={SignInTheme} />
				</ThemeProvider>
			</Container>
		);
	}


	/**
	 * Render the actual notices.
	 * 
	 * @return {ReactNode}
	 */
	renderNotices() {
		return (
			<Container fullWidth={true}>
				{
					this.state.notices.map((notice, key) => {
						return (
							<NoticesNotice
								acknowledged={this.state.read.includes(notice.id)}
								key={key}
								notice={notice}
								onAcknowledge={this.handleAcknowledge} />
						);
					})
				}
			</Container>
		);
	}


	/**
	 * Get whether we've acknowledged all.
	 * 
	 * @return {Boolean}
	 */
	get done() {
		if (this.state.loading) return false;
		else return (this.state.read.length === this.state.notices.length);
	}


	/**
	 * Get whether we have notices to render.
	 * 
	 * @return {Boolean}
	 */
	get hasNotices() {
		return (this.state.notices.length > 0);
	}


	/**
	 * Render caption.
	 * 
	 * @return {ReactNode}
	 */
	static renderCaption() {
		return (
			<String
				bold={true}
				centre={true}
				str={Strings.notices.caption} />
		);
	}


	/**
	 * Render empty state.
	 * 
	 * @return {ReactNode}
	 */
	static renderEmpty() {
		return (
			<Container py={2}>
				<String center={true} str={Strings.notices.empty} />
			</Container>
		);
	}


	/**
	 * Styles
	 * 
	 * @type {Object}
	 */
	static styles = {alignSelf: "flex-start"};

}

export default withRouter(Notices);
