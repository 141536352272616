import React from "react";
import Container from "Components/Container.js";

/**
 * Flex component
 *
 * A `Container` that defaults to Flex layout.
 * 
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Flex extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Container
				{...this.props}
				display={(this.props.display || "flex")}
				style={this.style}>
				{this.props.children}
			</Container>
		);
	}


	/**
	 * Styles.
	 * 
	 * @return {Object}
	 */
	get style() {
		return {
			flexDirection: (!this.props.columnar ? "column" : "row"),
			...this.props.style
		};
	}

}

export default Flex;
