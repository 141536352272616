import api from "api.js";
import {api as uris} from "api.js";
import Actions from "Resources/Actions.json";
import Registrations from "Resources/Registrations.json";
import Store from "App/Store.js";
import dispatchDdplTree from "Dispatchers/DdplTree.js";

/**
 * HOPS service
 *
 * @package HOPS
 * @subpackage Services
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class HopsService {

	/**
	 * Get login action from a card number.
	 *
	 * @param {String|null} device Device ID
	 * @param {String} card Card number
	 * @return {Promise} Resolves to action object
	 */
	static getAction(device, card) {
		return api.main.req(uris.action, {
			body: {
				device,
				card,
				mode: (device ? Actions.mode.device : Actions.mode.card)
			}
		}).then(res => res.json);
	}


	/**
	 * Get card details from a card ID.
	 *
	 * @param {String} card Card ID
	 * @param {String} checkcode Card checkcode
	 * @param {Boolean} send_existing optional Send existing card IDs (`false`)
	 * @return {Promise} Resolves to card object
	 */
	static getCard(card, checkcode, send_existing=false) {
		const body = {card, checkcode};
		if (send_existing) {
			body.current_cards = Store.getState().registrations.filter(r => {
				return (r.kind === Registrations.card);
			}).map(r => r.id).join(",");
		}
		return api.main.req(uris.card, {body}).then(res => res.json);
	}


	/**
	 * Get device details from a device ID.
	 *
	 * @param {String} device Device ID
	 * @param {Integer} sync optional Report sync queue length
	 * @param {Integer} syncTime optional Report last sync time
	 * @return {Promise} Resolves to device object
	 */
	static getDevice(device, sync=null, syncTime=null) {
		const body = {device, sync_count: sync, sync_time: syncTime};
		return api.main.req(uris.device, {body}).then(res => res.json);
	}


	/**
	 * Get the user list data associated with a device (i.e. site mode).
	 *
	 * @param {String} device ID
	 * @return {Promise} Resolves to user list data
	 */
	static getDeviceUserList(device) {
		return api.main.req(`/api/tr/devices/${device}/users`).then(res => res.json);
	}


	/**
	 * Get the DDPL tree relevant to our current identity.
	 *
	 * This will automatically cache the tree offline for later use.
	 *
	 * The cached tree will be retrieved automatically when we can't 
	 * fetch the live tree for any reason.
	 *
	 * @param {String} id Card/device ID
	 * @param [Boolean} isDevice `id` is a device ID (`false` for card ID)
	 * @param {Boolean} cache optional Whether to cache the tree
	 * @return {Promise} Resolves to DDPL tree object
	 */
	static getDdpl(id, isDevice, cache=true) {

		/**
		 * Request body
		 */
		const body = {};
		const param = (isDevice ? "device" : "card");
		body[param] = id;

		/**
		 * Make the API call
		 */
		return new Promise((resolve, reject) => {
			api.main.req(uris.ddpl, {body}).then(res => {

				const ddpl = res.json;

				if (cache) {

					/**
					 * Suggestion must not be cached!
					 *
					 * The API returns `null` when there is no 
					 * data to show - internally, `null` in state 
					 * means "not cached" though, so we convert 
					 * to an empty object to ensure the cache is 
					 * used correctly even when the org has no 
					 * DDPL data (which is a possible scenario).
					 */
					const ddplCache = (ddpl ? {...ddpl} : {});
					if (ddpl && ddplCache) delete ddplCache.suggestion;
					dispatchDdplTree(id, ddplCache);

				}

				resolve(ddpl);

			}).catch(() => {

				let reg;
				const regs = Store.getState().registrations;

				/**
				 * Try and find registration exactly matching our ID
				 */
				reg = regs.find(reg => (reg.id === id));

				/**
				 * We couldn't match the registration
				 *
				 * This HAS to mean a card was scanned *in device mode*,
				 * as otherwise there *would* be a direct match by the 
				 * card or device ID.
				 *
				 * Hence it follows there is only one registration, it is 
				 * a device registration, and the card HAS to belong to 
				 * that device registration (no other scenario is possible) 
				 * so we find the device registration and try and use *its* 
				 * cached generic DDPL tree - user will see more options 
				 * than just theirs but is best possible option.
				 */
				if (!reg) reg = regs.find(reg => (reg.kind === Registrations.device));

				const ddpl = reg?.ddplCache;
				if (ddpl) resolve(ddpl);
				else reject();

			});
		});

	}


	/**
	 * Get organisational notices for a given card ID/device ID.
	 * 
	 * @param {String|null} id Card ID
	 * @param [Boolean} isDevice `id` is a device ID (`false` for card ID)
	 * @param {Integer} dept optional Department ID user's signing into
	 * @return {Promise} Resolves to array of notice objects (or `null`)
	 */
	static getNotices(id, isDevice, dept=null) {
		const body = {};
		if (dept) body.dept = dept;
		if (isDevice) body.device = id;
		else body.card = id;
		return api.main.req(uris.notices, {body}).then(res => res.json);
	}


	/**
	 * Initiate lone working with HOPS.
	 *
	 * Informs HOPS that the given registration object is starting 
	 * lone working and resolves with whether HOPS consents to that.
	 *
	 * @param {Registration} registration
	 * @return {Promise} Resolves with lone working record UUID when accepted
	 */
	static initLoneWorking(registration) {
		return api.main.req(
			uris.lw,
			{
				body: {
					Card: registration.id,
					Checkcode: registration.checkcode
				},
				method: "POST"
			}
		).then(res => res.body);
	}


	/**
	 * Mark a notice as read.
	 *
	 * @param {String|null} card Card ID
	 * @param {Integer} notice Notice ID
	 * @return {Promise} Resolves when complete
	 */
	static markNoticeRead(card, notice) {
		const body = {notice};
		if (card) body.card = card;
		return api.main.req(uris.noticesMarkRead, {body});
	}


	/**
	 * Record a checkpoint submission.
	 *
	 * @param {Object} record Record object
	 * @return {Promise}
	 */
	static record(record) {
		return new Promise((resolve, reject) => {
			api.main.req(uris.record, {
				body: {...record},
				method: "POST"
			}).then(res => {
				if (res.status === 200) {
					resolve(res);
				}
				else reject(res);
			}).catch(e => reject(e));
		});
	}

}

export default HopsService;
