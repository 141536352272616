import React from "react";
import ReactSimpleKeyboard from "react-simple-keyboard";
import "./TouchKeyboard.scss";
import "react-simple-keyboard/build/css/index.css";

/**
 * Touch keyboard
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class TouchKeyboard extends React.Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Keyboard reference
		 * 
		 * @type {ReactSimpleKeyboard}
		 */
		this.ref = null;

		/**
		 * Method binds
		 */
		this.handleChange = this.handleChange.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
		this.updateKeyboardRef = this.updateKeyboardRef.bind(this);

	}


	/**
	 * Component updated.
	 *
	 * We may need to update the keyboard's internal value.
	 *
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (prevProps.value !== this.props.value) {
			if (this.props.value !== this.ref?.getInput()) {
				this.setValueFromProp();
			}
		}
	}


	/**
	 * Value changed.
	 *
	 * @param {String} v
	 * @return {void}
	 */
	handleChange(v) {
		this.props.onChange(v, this.ref?.getCaretPosition());
	}


	/**
	 * Handle a keyboard key press.
	 * 
	 * @param {String} btn `ReactSimpleKeyboard` key name
	 * @return {void}
	 */
	handleKeyPress(btn) {

		/**
		 * Enter key
		 */
		if (btn === this.constructor._keys.enter) {
			if (this.props.onEnter) this.props.onEnter();
		}

	}


	/**
	 * Set the keyboard's internal value using our `value` prop.
	 *
	 * @return {void}
	 */
	setValueFromProp() {
		if (this.ref) this.ref.setInput(this.props.value);
	}


	/**
	 * Set our keyboard reference pointer to a given reference.
	 * 
	 * @param {ReactSimpleKeyboard} ref Type is not verified!
	 * @return {void}
	 */
	updateKeyboardRef(ref) {
		this.ref = ref;
		if (this.props.value) {
			this.setValueFromProp();
			this.props.onChange(this.props.value, -1);	// reset caret to end
		}
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<ReactSimpleKeyboard
				display={this.constructor.display}
				keyboardRef={this.updateKeyboardRef}
				layout={this.constructor.layoutKeys}
				layoutName={this.constructor.layout}
				mergeDisplay={true}
				onChange={this.handleChange}
				onKeyPress={this.handleKeyPress}
				preventMouseDownDefault={true} />
		);
	}


	/**
	 * `ReactSimpleKeyboard` special key definitions
	 * 
	 * @type {Object}
	 */
	static _keys = {
		bksp: "{bksp}",
		enter: "{enter}",
		space: "{space}"
	};

	/**
	 * `ReactSimpleKeyboard` `display` definition.
	 * 
	 * @type {Object}
	 */
	static display = {
		[this._keys.enter]: "enter"
	};

	/**
	 * Layout name
	 *
	 * @type {String}
	 */
	static layout = "default";

	/**
	 * `ReactSimpleKeyboard` `layout` definition.
	 *
	 * @type {Object}
	 */
	static layoutKeys = {
		[this.layout]: [
			`1 2 3 4 5 6 7 8 9 0 ${this._keys.bksp}`,
			`Q W E R T Y U I O P`,
			`A S D F G H J K L ${this._keys.enter}`,
			`Z X C V B N M`,
			`${this._keys.space}`
		]
	};

}

export default TouchKeyboard;
