import CardService from "Services/CardService.js";
import RegistrationService from "Services/RegistrationService.js";

/**
 * Store migrator
 *
 * @package HOPS
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class StoreMigrator {

	/**
	 * Migrate the store.
	 * 
	 * @param {Object} store
	 * @return {void}
	 */
	static migrate(store) {

		/**
		 * The store is pre-#123!
		 */
		if (!store.registrations.length && (store.card?.id || store.device?.id)) {

			/**
			 * Convert legacy card registration to new registration
			 */
			if (store.card.id) {

				/**
				 * Migrate card ID
				 */
				store.card.id = CardService.getCardIdFromUiString(store.card.id);

				/**
				 * Create registration
				 */
				store.registrations = [
					RegistrationService.createRegistrationUsingCardResponse(
						store.card.id,
						store.card.checkcode,
						store.card
					)
				];

			}

			/**
			 * Convert legacy device registration to new registration
			 */
			else if (store.device.id) {
				store.registrations = [
					RegistrationService.createRegistrationUsingDeviceResponse(
						store.device.id,
						store.device
					)
				];
			}
		}

		/**
		 * We're done!
		 */
		return store;

	}

}

export default StoreMigrator;
