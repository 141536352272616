import React from "react";
import AppService from "Services/AppService.js";
import Button from "Components/Button.js";
import Styles from "Resources/Theme.json";
import rem from "Helpers/Rem.js";
import withTaskStatus from "Hoc/withTaskStatus.js";
import {connect} from "react-redux";
import {Typography} from "@material-ui/core";
import {Alert} from "@material-ui/lab";

/**
 * Update alert
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class UpdateAlert extends React.Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Time duration
		 *
		 * @type {Number}
		 */
		this.time = 60;

		/**
		 * Timer ID
		 *
		 * @type {Integer}
		 */
		this.timer = null;

		/**
		 * Timer ID (seconds)
		 *
		 * @type {Integer}
		 */
		this.timerSeconds = null;

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Online
			 *
			 * @type {Boolean}
			 */
			online: navigator.onLine,

			/**
			 * Remaining seconds
			 *
			 * @type {Integer}
			 */
			seconds: this.time

		};

		/**
		 * Method binds
		 */
		this.handleOnline = this.handleOnline.bind(this);
		this.handleOffline = this.handleOffline.bind(this);
		this.elapse = this.elapse.bind(this);
		this.update = this.update.bind(this);

	}


	/**
	 * Cancel current timer.
	 *
	 * @return {void}
	 */
	cancel() {
		if (this.timer) clearTimeout(this.timer);
		if (this.timerSeconds) clearTimeout(this.timerSeconds);
	}


	/**
	 * Configure and prepare to update.
	 *
	 * @return {void}
	 */
	configure() {
		if (this.props.update) {
			this.elapse(true);
			this.timer = setTimeout(this.update, (this.time * 1000));
		}
	}


	/**
	 * Came online handler.
	 * 
	 * @return {void}
	 */
	handleOnline() {
		this.configure();
		this.setState({online: true});
	}


	/**
	 * Went offline handler.
	 * 
	 * @return {void}
	 */
	handleOffline() {
		this.cancel();
		this.setState({online: false});
	}


	/**
	 * Reset timer.
	 *
	 * @return {void}
	 */
	restart() {
		this.cancel();
		this.configure();
	}


	/**
	 * Elapse a second from the remaining time count.
	 *
	 * @param {Boolean} i
	 * @return {void}
	 */
	elapse(i=false) {
		const secs = (this.state.seconds - 1);
		this.setState({seconds: (i ? this.time : ((secs >= 0) ? secs : 0))});
		this.timerSeconds = setTimeout(this.elapse, 1000);
	}


	/**
	 * Refresh to perform the update.
	 *
	 * @return {void}
	 */
	update() {
		if (this.props.activeTasks) this.restart();
		else if (AppService.update() === false) this.restart();
	}


	/**
	 * Component mounted.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		if (this.props.update) this.configure();
		window.addEventListener("online", this.handleOnline);
		window.addEventListener("offline", this.handleOffline);
	}


	/**
	 * Component updated; is there an update?
	 *
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (this.props.update !== prevProps.update) this.configure();
	}


	/**
	 * Component unmounting.
	 * 
	 * @return {void}
	 */
	componentWillUnmount() {
		this.cancel();
		window.removeEventListener("online", this.handleOnline);
		window.removeEventListener("offline", this.handleOffline);
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Alert
				color="success"
				severity="info"
				style={this.styles}
				action={this.renderAlertAction()}>
				<Typography color="primary">
					<span>App update available; installing in</span>
					<span> {this.state.seconds} </span>
					<span>seconds</span>
					<span>...</span>
				</Typography>
			</Alert>
		);
	}


	/**
	 * Render button.
	 *
	 * @return {ReactNode}
	 */
	renderAlertAction() {
		return (
			<Button
				label="Update Now"
				onClick={this.update}
				variant="text" />
		);
	}


	/**
	 * Styles.
	 *
	 * @return {Object}
	 */
	get styles() {
		return {
			left: "50%",
			maxWidth: `calc(100% - ${rem(2)})`,
			opacity: ((this.props.update && this.state.online) ? 1 : 0),
			position: "fixed",
			top: `calc(${Styles.Hops.barHeight} + ${rem(2)})`,
			transform: "translateX(-50%)",
			transition: "opacity 0.2s ease-in-out",
			width: "60rem"
		};
	}

}

export default connect(({update}) => ({update}))(withTaskStatus(UpdateAlert));
