import React from "react";
import Button from "Components/Button.js";
import CheckCirclePop from "Components/CheckCirclePop.js";
import Container from "Components/Container.js";
import Label from "Components/Label.js";
import String from "Components/String.js";
import Strings from "Resources/Strings.json";
import withRegistration from "Hoc/withRegistration.js";
import {Table, TableBody, TableRow, TableCell} from "@material-ui/core";

/**
 * Welcome screen (registered)
 *
 * @package HOPS
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class WelcomeRegistered extends React.Component {

	/**
	 * Mounted; reset scroll position.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		window.scrollTo(0, 0);
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Container centred={true} gap={2} style={this.constructor.styles}>
				<CheckCirclePop />

				<Table>
					<TableBody>
						<TableRow>
							<TableCell>
								<Label>{this.idLabel}</Label>
							</TableCell>
							<TableCell>
								<String str={this.data.id} />
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>
								<Label>{Strings.welcome.registered.hops}</Label>
							</TableCell>
							<TableCell>
								<String str={this.data.org} />
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>

				<String str={Strings.welcome.registered.message} />
				<Button label="OK" onClick={this.props.onDone} />
			</Container>
		);
	}


	/**
	 * Data.
	 *
	 * @return {Object}
	 */
	get data() {
		return {
			id: this.props.registrations[0].name,
			org: this.props.registrations[0].organisation
		};
	}


	/**
	 * Label for the card/device identifier.
	 * 
	 * @return {String}
	 */
	get idLabel() {
		return (this.props.isDevice ? Strings.welcome.registered.device : Strings.welcome.registered.card);
	}


	/**
	 * Styles.
	 * 
	 * @type {Object}
	 */
	static styles = {
		gridTemplateColumns: "max-content",
		justifyContent: "center",
		maxWidth: "36rem",
		width: "100%"
	};

}

export default withRegistration(WelcomeRegistered);
