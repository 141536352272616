/**
 * Card service
 *
 * Provides parsing and verification functions for HOPS cards.
 *
 * @package HOPS
 * @subpackage Services
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class CardService {

	/**
	 * Group length to use when splitting card IDs for UI presentation
	 * 
	 * @type {Number}
	 */
	static hopsCardDelimiterLengths = 3;

	/**
	 * Character to use to delimit card ID components in the UI
	 * 
	 * @type {String}
	 */
	static hopsCardIdUiCharDelimiter = "-";

	/**
	 * Minimum length of HOPS card ID numbers.
	 *
	 * This excludes any dashes/UI formatting characters etc.
	 * 
	 * @return {Number}
	 */
	static hopsCardIdLength = 9;


	/**
	 * Transform a card ID into an ID string for presentation in the UI.
	 *
	 * This adds hyphens between each three digits of the card ID.
	 * 
	 * @param {String} id
	 * @return {String}
	 */
	static getUiCardId(id) {
		const regex = new RegExp(`[0-9]{1,${this.hopsCardDelimiterLengths}}`, "g");
		return (id?.match(regex)?.join(this.hopsCardIdUiCharDelimiter) || "");
	}


	/**
	 * Get card ID value transformed for use as a UI input mask.
	 *
	 * @param {String} id
	 * @return {String}
	 */
	static getUiCardIdInput(id) {
		let ui = this.getUiCardId(id);
		if (this.getCardIdFromUiString(ui).length < this.hopsCardIdLength) {
			const coms = ui.split(this.hopsCardIdUiCharDelimiter);
			const last = coms[(coms.length - 1)];
			const cutoff = (this.hopsCardIdUiCharDelimiter / this.hopsCardDelimiterLengths);
			if (last.length === cutoff) coms[(coms.length - 1)] = `${last}${this.hopsCardIdUiCharDelimiter}`;
			ui = coms.join(this.hopsCardIdUiCharDelimiter);
		}
		return ui;
	}


	/**
	 * Get an internal card ID from a UI card ID string.
	 *
	 * This is the inverse of `getUiCardId()`.
	 *
	 * @param {String} id
	 * @return {String}
	 */
	static getCardIdFromUiString(id) {
		return id.replace(new RegExp(this.hopsCardIdUiCharDelimiter, "g"), "");
	}


	/**
	 * Attempt to get the card ID from a value assumed to have been 
	 * retrieved from a card's QR data.
	 *
	 * Card IDs are currently assumed to be the last `/`-delimited 
	 * component of the card data (URL of HOPS card validity check).
	 *
	 * @param {mixed} data
	 * @return {String|null}
	 */
	static getCardIdFromCardData(data) {
		const coms = data.split("/");
		const id = coms[(coms.length - 1)];
		return (this.validCardIdLength(id) ? id : null);
	}


	/**
	 * Get whether a card ID is of a valid length.
	 *
	 * @param {String} id
	 * @return {Boolean}
	 */
	static validCardIdLength(id) {
		return (id.length === this.hopsCardIdLength);
	}


	/**
	 * Get the length of card IDs as rendered in the UI.
	 * 
	 * @return {Integer}
	 */
	static get uiCardIdLength() {
		const delims = ((this.hopsCardIdLength / this.hopsCardDelimiterLengths) - 1);
		return (this.hopsCardIdLength + ((delims >= 0) ? delims : 0));
	}

}

export default CardService;
