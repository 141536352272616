import React from "react";
import {Button as MuiButton} from "@material-ui/core";

/**
 * Button
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Button extends React.Component {

	/**
	 * Constructor.
	 * 
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Method binds
		 */
		this.handleClick = this.handleClick.bind(this);

	}


	/**
	 * Clicked.
	 * 
	 * @return {void}
	 */
	handleClick() {
		if (this.props.onClick) {
			this.props.onClick(this.props.value);
		}
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<MuiButton
				className={this.props.className}
				color={(this.props.color || "primary")}
				disabled={this.props.disabled}
				endIcon={this.props.endIcon}
				onClick={this.handleClick}
				size={this.props.size}
				startIcon={this.props.startIcon}
				style={this.style}
				type={this.props.type}
				value={this.props.value}
				variant={(this.props.variant || "contained")}>
				{this.props.label}
			</MuiButton>
		);
	}


	/**
	 * Get styles.
	 * 
	 * @return {Object}
	 */
	get style() {
		return {
			fontWeight: (this.props.bold ? "bold" : null),
			...this.props.style
		};
	}

}

export default Button;
