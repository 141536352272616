import React from "react";
import Registrations from "Resources/Registrations.json";
import {connect} from "react-redux";

/**
 * `withRegistration()` HOC wrapper
 *
 * Wraps components with props providing details about our registration.
 *
 * Refer to the source for implementation details.
 *
 * @package HOPS
 * @subpackage Hoc
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
const withRegistration = Component => {

	/**
	 * `ComponentWithRegistration` wrapper
	 *
	 * @package HOPS
	 * @subpackage Hoc
	 * @author Heron Web Ltd
	 * @copyright Heritage Operations Processing Limited
	 */
	const cwr = class ComponentWithRegistration extends React.Component {

		/**
		 * Render.
		 *
		 * @return {ReactNode}
		 */
		render() {
			return (
				<Component
					{...this.props}
					activeRegistration={this.activeRegistration}
					isDevice={this.isDevice}
					isRegistered={this.isRegistered}
					isSiteMode={!!(this.isDevice && this.activeRegistration?.site && this.activeRegistration?.ddpl)}
					registrations={this.props.registrations} />
			);
		}


		/**
		 * Get the active registration object by inference.
		 *
		 * This will always be reliable when in device mode, 
		 * or when registered with a single card.
		 *
		 * When in card mode with multiple cards registered, 
		 * we need a `activeCardId` prop to be set from the 
		 * outside, indicating the card which is contextually 
		 * relevant. We return `null` when no reg is inferable.
		 * 
		 * @return {Object|null}
		 */
		get activeRegistration() {
			if (this.props.registrations.length === 1) {
				return this.props.registrations[0];
			}
			else if (this.props.activeCardId) {
				return ((this.props.registrations.find(r => (r.id === this.props.activeCardId))) || null);
			}
			else return null;
		}


		/**
		 * Get whether we're registered as an organisational device.
		 * 
		 * @return {Boolean}
		 */
		get isDevice() {
			const regs = this.props.registrations;
			const dev = regs.find(r => (r.kind === Registrations.device));
			return !!dev;
		}


		/**
		 * Get whether we're registered.
		 * 
		 * @return {Boolean}
		 */
		get isRegistered() {
			return (this.props.registrations.length > 0);
		}

	};

	return connect(({registrations}) => ({registrations}))(cwr);

};

export default withRegistration;
