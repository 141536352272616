import React from "react";
import AsyncStatefulComponent from "Includes/AsyncStatefulComponent.js";
import Button from "Components/Button.js";
import CheckCirclePop from "Components/CheckCirclePop.js";
import Flex from "Components/Flex.js";
import LinearProgress from "@material-ui/core/LinearProgress";
import Navigator from "App/Navigator.js";
import RecordConfirmationMessage from "Includes/RecordConfirmationMessage.js";
import String from "Components/String.js";
import View from "App/View.js";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

/**
 * Done view
 *
 * Confirmation when record created.
 *
 * @package HOPS
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Done extends AsyncStatefulComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Timer value
			 *
			 * @type {Integer}
			 */
			timer: 0

		};

		/**
		 * Timer ID
		 *
		 * @type {Integer|null}
		 */
		this.timer = null;

		/**
		 * Timeout ID
		 * 
		 * @type {Integer|null}
		 */
		this.timeout = null;

		/**
		 * Method binds
		 */
		this.handleTimer = this.handleTimer.bind(this);

	}


	/**
	 * Component mounted.
	 * 
	 * @return {void}
	 */
	componentDidMount() {

		if (!this.props.recordLast) {
			this.props.history.replace("/");
		}

		this.handleTimer();
		this.timer = setInterval(this.handleTimer, this.constructor.interval);

	}


	/**
	 * Component unmounting.
	 * 
	 * @return {void}
	 */
	componentWillUnmount() {
		super.componentWillUnmount();
		if (this.timer) clearInterval(this.timer);
	}


	/**
	 * Timer incrementer.
	 *
	 * @return {void}
	 */
	handleTimer() {
		if (this.state.timer >= this.constructor.duration) {
			this.constructor.handleDone();
		}
		else this.setState({timer: this.nextTimer});
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {

		if (!this.props.recordLast) return null;

		const strings = RecordConfirmationMessage(
			this.props.recordLast.record.action,
			!!this.props.recordLast.record.lone_working_uuid,
			this.props.recordLast.username
		);

		return (
			<View gap={4}>
				<CheckCirclePop />
				<Flex gap={2}>
					<String
						bold={true}
						centre={true}
						str={strings.thanks}
						variant="h5" />
					<String
						centre={true}
						str={strings.confirmation}
						variant="h6" />
				</Flex>
				<Button label="Home" onClick={this.constructor.handleDone} />
				<LinearProgress
					style={this.constructor.progressStyles}
					value={this.progress}
					variant="determinate" />
				<String str={`Redirecting in ${(this.remainingSeconds + 1)}s...`} />
			</View>
		);

	}


	/**
	 * Get the next timer value based on our current interval value.
	 *
	 * @return {Integer} ms
	 */
	get nextTimer() {
		return (this.state.timer + this.constructor.interval);
	}


	/**
	 * Get the progress bar value from our timer value and duration value.
	 * 
	 * @return {Integer} 0 - 100
	 */
	get progress() {
		return parseInt((this.state.timer / this.constructor.duration) * 100);
	}


	/**
	 * Get remaining seconds until done based on our timer value and duration.
	 *
	 * @return {Integer} Seconds
	 */
	get remainingSeconds() {
		return ((this.constructor.duration - this.state.timer) / 1000);
	}


	/**
	 * We're done.
	 *
	 * @return {void}
	 */
	static handleDone() {
		Navigator.home();
	}


	/**
	 * Duration of this screen.
	 * 
	 * @type {Integer} ms
	 */
	static duration = 3000;

	/**
	 * Interval on which to update the timer.
	 *
	 * @type {Integer} ms
	 */
	static interval = 1000;

	/**
	 * Progress styles.
	 * 
	 * @type {Object}
	 */
	static progressStyles = {width: "100%"};

}

export default connect(({recordLast}) => ({recordLast}))(withRouter(Done));
