import React from "react";
import F12Controls from "./F12Controls.js";
import String from "Components/String.js";
import View from "App/View.js";

/**
 * F12 view
 * 
 * @package HOPS
 * @subpackage F12
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class F12 extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<View notCentred={true} top={true}>
				<String str="Developer tools" variant="h5" />
				<F12Controls />
			</View>
		);
	}

}

export default F12;
