/**
 * Registration conflict error
 *
 * @package HOPS
 * @subpackage Registration
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class RegistrationConflictError extends Error {

	/**
	 * Constructor.
	 *
	 * @param {String} id
	 * @return {self}
	 */
	constructor(id) {
		super(`"${id}" is already registered.`);

		/**
		 * Registration ID
		 *
		 * @type {String}
		 */
		this.id = id;

	}

}

export default RegistrationConflictError;
