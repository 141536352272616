import strftime from "strftime";

/**
 * Notice service
 *
 * @package HOPS
 * @subpackage Services
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class NoticeService {

	/**
	 * Set the date of a notice object to today and return it.
	 *
	 * @param {Object} notice Notice-like object
	 * @return {Object}
	 */
	static setNoticeDate(notice) {
		return {...notice, date: strftime("%Y-%m-%d")};
	}

}

export default NoticeService;
