import React from "react";

/**
 * React helper
 *
 * Utility methods for using React components.
 *
 * @package HOPS
 * @subpackage Helpers
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class ReactHelper {

	/**
	 * Render a component.
	 * 
	 * @param {ReactComponent} Component
	 * @param {Object} props optional
	 * @return {ReactNode}
	 */
	static render(Component, props=null) {
		return <Component {...props} />;
	}

}

export default ReactHelper;
