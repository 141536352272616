import StatePersistent from "./StatePersistent.js";

/**
 * Storage class for state persistence
 *
 * @package HOPS
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Storage {

	/**
	 * App key in `localStorage`
	 * 
	 * @type {String}
	 */
	static APP_STORAGE_KEY = "app";


	/**
	 * Get persisted state.
	 *
	 * @return {Object} Returns initial persisted state when not persisted
	 */
	static get() {
		const persisted = JSON.parse(window.localStorage.getItem(this.APP_STORAGE_KEY));
		return (persisted || StatePersistent);
	}


	/**
	 * Persist a state object, replacing existing persisted state.
	 *
	 * @param {Object} state
	 * @return {void}
	 */
	static save(state) {

		const persist = {};

		Object.keys(StatePersistent).forEach(k => {
			persist[k] = state[k];
		});

		window.localStorage.setItem(this.APP_STORAGE_KEY, JSON.stringify(persist));

	}

}

export default Storage;
