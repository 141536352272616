import React from "react";
import SyncIcon from "./SyncIcon.js";
import {withRouter} from "react-router-dom";
import {IconButton} from "@material-ui/core";
import {CheckCircle as CheckCircleIcon} from "@material-ui/icons";
import {SyncProblem as SyncProblemIcon} from "@material-ui/icons";

/**
 * App status icon button
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class IconButtonApp extends React.Component {

	/**
	 * Clicked.
	 * 
	 * @return {void}
	 */
	handleClick = () => this.props.history.push("/app");


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<IconButton
				color="secondary"
				onClick={this.handleClick}>
				{this.icon}
			</IconButton>
		);
	}


	/**
	 * Get the icon component to render.
	 * 
	 * @return {ReactNode}
	 */
	get icon() {
		if (this.props.syncing) return <SyncIcon />;
		else if (this.props.pending) return <SyncProblemIcon />;
		else return <CheckCircleIcon />;
	}

}

export default withRouter(IconButtonApp);
