import Theme from "Resources/Theme.json";
import {createMuiTheme as createTheme} from "@material-ui/core/styles";

const app = createTheme(Theme.App);

const SignIn = createTheme(Theme.SignIn);

const SignOut = createTheme(Theme.SignOut);

export default app;

export {app, SignIn, SignOut};
