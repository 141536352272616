import React from "react";
import Button from "Components/Button.js";
import RegistrationService from "Services/RegistrationService.js";
import Strings from "Resources/Strings.json";
import withSnack from "Hoc/withSnack.js";
import withTaskStatus from "Hoc/withTaskStatus.js";

/**
 * "Refresh now" button
 *
 * @package HOPS
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class AboutRefreshButton extends React.Component {

	/**
	 * Constructor.
	 * 
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Method binds
		 */
		this.handleRefresh = this.handleRefresh.bind(this);

	}


	/**
	 * Refresh.
	 * 
	 * @return {void}
	 */
	handleRefresh() {
		RegistrationService.refresh().then(() => {
			this.props.snack(Strings.about.refresh.done, "success");
		}).catch(() => {
			this.props.snack(Strings.about.refresh.error, "error");
		});
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Button
				disabled={this.props.activeTasks}
				label={Strings.about.refresh.control}
				onClick={this.handleRefresh}
				variant="outlined" />
		);
	}

}

export default withSnack(withTaskStatus(AboutRefreshButton));
