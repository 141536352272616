import api from "api.js";
import {api as uris} from "api.js";

/**
 * App service
 * 
 * @package HOPS
 * @subpackage Services
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class AppService {

	/**
	 * Get app version for UI.
	 * 
	 * @return {String}
	 */
	static getAppVersionUi() {
		const app = process.env.REACT_APP_APP;
		return (app ? `#${app}` : "(Undefined)");
	}


	/**
	 * Get the app version from the server.
	 *
	 * This may differ from our local version if there is an update!
	 *
	 * @return {String}
	 */
	static getServerVersion() {
		const config = {urlBase: ""};
		return api.main.req(uris.version, {}, config).then(r => r.body.trim());
	}


	/**
	 * Update by forcing refresh.
	 *
	 * Updates only occur while online.
	 *
	 * @return {Boolean} `false` if not updating because offline
	 */
	static update() {
		return (navigator.onLine ? window.history.go(0) : false);
	}

}

export default AppService;
