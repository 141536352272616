import Store from "App/Store.js";
import HopsService from "./HopsService.js";
import LocationService from "./LocationService.js";
import dispatchSynced from "Dispatchers/Synced.js";
import dispatchSyncing from "Dispatchers/Syncing.js";

/**
 * Sync service
 *
 * @package HOPS
 * @subpackage Services
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class SyncService {

	/**
	 * Attempt to sync the queue.
	 *
	 * This should only be attempted if the device is online.
	 *
	 * @return {void}
	 */
	static sync() {

		// We'll reset the queue state to this later
		const pending = [];

		// We do NOT want multiple concurrent syncs!
		if (this.syncing) return;
		else dispatchSyncing();

		// Pepare the records
		this.prepare().then(async records => {

			/*
			 * Sync each record
			 * We sync individually to avoid excessive HOPS load
			 */
			for (const record of records) {
				await HopsService.record(record).catch(() => {
					pending.push(record);
				});
			}

			// Report the sync
			dispatchSynced(pending);

		}).catch(() => dispatchSynced(this.queue));

	}


	/**
	 * Get the sync queue contents prepared for submission.
	 * 
	 * @return {Promise}
	 */
	static prepare() {
		return new Promise((resolve, reject) => {
			LocationService.get().then(loc => {
				resolve(this.prepareRecords(this.queue, loc));
			}).catch(e => reject(e));
		});
	}


	/**
	 * Prepare an individual record for syncing.
	 *
	 * The record object itself is modified and returned.
	 *
	 * @param {Object} record Record to prepare
	 * @param {Object} location Location data from `LocationService.get()`
	 * @return {Object} Record with prepared runtime fields
	 */
	static prepareRecord(record, location) {
		record.card_scanned = (record.card_scanned ? 1 : 0);
		record.card_confirmed = (record.card_confirmed ? 1 : 0);
		record.location_latitude = location.latitude;
		record.location_longitude = location.longitude;
		record.location_accuracy = location.accuracy;
		return record;
	}


	/**
	 * Prepare an array of records for syncing.
	 *
	 * Adds the given location data to each record.
	 *
	 * We clone the record to ensure app state properties aren't sent.
	 *
	 * @param {Array} records Array of records to prepare
	 * @param {Object} loc Location data from `LocationService.get()`
	 * @return {Array}
	 */
	static prepareRecords(records, loc) {
		return records.map(record => {
			return this.prepareRecord({...record}, loc);
		});
	}


	/**
	 * Get the sync queue contents.
	 *
	 * @return {Array}
	 */
	static get queue() {
		return Store.getState().sync;
	}


	/**
	 * Get whether we're currently syncing.
	 *
	 * @return {Boolean}
	 */
	static get syncing() {
		return Store.getState().syncing;
	}

}

export default SyncService;
