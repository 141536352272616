import React from "react";
import AsyncStatefulComponent from "Includes/AsyncStatefulComponent.js";
import Container from "Components/Container.js";
import Navigator from "App/Navigator.js";
import Strings from "Resources/Strings.json";
import View from "App/View.js";
import WelcomeHome from "./WelcomeHome.js";
import WelcomeRegistered from "./WelcomeRegistered.js";
import withRegistration from "Hoc/withRegistration.js";
import RegistrationService from "Services/RegistrationService.js";

/**
 * Welcome screen
 *
 * @package HOPS
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Welcome extends AsyncStatefulComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Error code
			 *
			 * @type {Integer}
			 */
			error: null,

			/**
			 * Loading
			 *
			 * @type {Boolean}
			 */
			loading: false

		};

		/**
		 * Method binds
		 */
		this.onRegister = this.onRegister.bind(this);

	}


	/**
	 * Handle registration request.
	 *
	 * Card ID is assumed when `isDevice` isn't truthy.
	 * 
	 * @param {String} id Card/device ID
	 * @param {Boolean} isDevice Device ID?
	 * @param {String} checkcode optional Checkcode (when in card mode)
	 * @return {void}
	 */
	onRegister(id, isDevice, checkcode=null) {
		if (!isDevice) {
			this.onRegisterCard(id, checkcode);
		}
		else this.onRegisterDevice(id);
	}


	/**
	 * Handle registration request with a given card ID.
	 * 
	 * @param {String} id
	 * @param {String} checkcode
	 * @return {void}
	 */
	onRegisterCard(id, checkcode) {

		/**
		 * Handle state
		 */
		this.setState({loading: true, error: null});

		/**
		 * Get card details
		 */
		RegistrationService.registerCard(id, checkcode).then(() => {
			// ...
		}).catch(res => {
			this.setState({error: res.status});
		}).finally(() => {
			this.setState({loading: false});
		});

	}


	/**
	 * Handle registration request with a given device ID.
	 *
	 * @param {String} id
	 * @return {void}
	 */
	onRegisterDevice(id) {

		/**
		 * Handle state
		 */
		this.setState({loading: true, error: null});

		/**
		 * Get device details
		 */
		RegistrationService.registerDevice(id).then(() => {
			// ...			
		}).catch(res => {
			this.setState({error: res.status});
		}).finally(() => {
			this.setState({loading: false});
		});

	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<View label={Strings.welcome.label}>
				<Container mt={1}>
					{(!this.props.isRegistered ? this.renderPre() : this.constructor.renderPost())}
				</Container>
			</View>
		);
	}


	/**
	 * Render pre-registration state.
	 * 
	 * @return {ReactNode}
	 */
	renderPre() {
		return (
			<WelcomeHome
				error={this.state.error}
				loading={this.state.loading}
				onSubmit={this.onRegister} />
		);
	}


	/**
	 * Render post-registration state.
	 * 
	 * @return {ReactNode}
	 */
	static renderPost() {
		return <WelcomeRegistered onDone={Navigator.home} />;
	}

}

export default withRegistration(Welcome);
