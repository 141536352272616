import React from "react";
import RegisterService from "Services/RegisterService.js";
import CheckpointActions from "Views/CheckpointActions.js";
import Record from "Records/Record.js";
import withMobile from "Hoc/withMobile.js";
import withRegistration from "Hoc/withRegistration.js";
import {withRouter} from "react-router-dom";

/**
 * Individuals mode view
 *
 * @package HOPS
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class IndividualsMode extends React.Component {

	/**
	 * Component mounted.
	 * 
	 * @return {void}
	 */
	componentDidMount() {
		this.checkValidRegistration();
	}


	/**
	 * Component updated.
	 * 
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (prevProps.match.params.reg !== this.props.match.params.reg) {
			this.checkValidRegistration();
		}
	}


	/**
	 * Check we've got a valid registration ID and redirect otherwise.
	 * 
	 * @return {void}
	 */
	checkValidRegistration() {
		if (!this.registration) this.props.history.replace("/");
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<CheckpointActions
				onAction={this.constructor.handleAction}
				padding={(this.props.isMobile ? 2 : null)}
				registration={this.registration}
				suggest={true} />
		);
	}


	/**
	 * Action selected!
	 *
	 * @param {Object} record Record object
	 * @param {String|null} username optional Name of user to acknowledge
	 * @return {void}
	 */
	static handleAction(record, username=null) {
		RegisterService.transact(
			new Record(
				record,
				this.registration,
				username
			)
		);
	}


	/**
	 * Get our active registration object.
	 * 
	 * @return {Object|null}
	 */
	get registration() {
		const reg = this.props.match.params.reg;
		return this.props.registrations.find(r => (r.id === reg));
	}

}

export default withRegistration(withMobile(withRouter(IndividualsMode)));
