import React from "react";
import AsyncStatefulComponent from "Includes/AsyncStatefulComponent.js";
import Dialog from "Components/DialogBase.js";
import F12Controls from "./F12Controls.js";

/**
 * F12 controls dialog
 * 
 * @package HOPS
 * @subpackage F12
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class F12Dialog extends AsyncStatefulComponent {

	/**
	 * State
	 *
	 * @type {Object}
	 */
	state = {

		/**
		 * Open?
		 *
		 * @type {Boolean}
		 */
		open: false

	};


	/**
	 * Component mounted.
	 * 
	 * @return {void}
	 */
	componentDidMount() {
		window.addEventListener("keydown", this.keyHandler);
	}


	/**
	 * Component unmounted.
	 * 
	 * @return {void}
	 */
	componentWillUnmount() {
		super.componentWillUnmount();
		window.removeEventListener("keydown", this.keyHandler);
	}


	/**
	 * Toggle visibility.
	 *
	 * @return {void}
	 */
	handleToggle = () => {
		this.setState({open: !this.state.open});
	};


	/**
	 * Key handler.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	keyHandler = e => {
		if ((e?.ctrlKey || e?.metaKey) && (e.which === 123)) {
			this.handleToggle();
		}
	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Dialog
				fullWidth={true}
				onClose={this.handleToggle}
				open={this.state.open}
				title="Developer tools">
				<F12Controls />
			</Dialog>
		);
	}

}

export default F12Dialog;
