/**
 * Object utility methods
 *
 * @package HOPS
 * @subpackage Helpers
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Obj {

	/**
	 * Get whether a given value is an object.
	 *
	 * @param {mixed} obj
	 * @return {Boolean}
	 */
	static isObject(obj) {
		return (typeof obj === "object");
	}


	/**
	 * A recursive function iterating an object `b` and setting 
	 * any keys which it has but which object `a` does not have 
	 * within `a` to the same value as their value in `b`.
	 * 
	 * @param {Object} a
	 * @param {Object} b
	 * @return {Object}
	 */
	static setMissingKeys(a, b) {
		Object.keys(b).forEach(key => {

			if (!a.hasOwnProperty(key)) {
				a[key] = b[key];
			}

			const av = a[key];
			const bv = b[key];

			if (av && bv && this.isObject(av) && this.isObject(bv)) {
				this.setMissingKeys(av, bv);
			}

		});

		return a;
	}

}

export default Obj;
