import React from "react";
import {Typography} from "@material-ui/core";

/**
 * String
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class String extends React.Component {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Typography
				className={this.props.className}
				color={this.props.color}
				style={this.styles}
				title={this.props.title}
				variant={this.props.variant}>
				{this.props.str}
			</Typography>
		);
	}


	/**
	 * Styles.
	 * 
	 * @return {Object}
	 */
	get styles() {
		return {
			display: ((this.props.lines > 1) ? "-webkit-box" : null),
			fontWeight: (this.props.bold ? "bold" : null),
			opacity: (this.props.caption ? this.constructor.opacity_caption : null),
			overflow: "hidden",
			textAlign: (this.props.centre ? "center" : null),
			textOverflow: "ellipsis",
			WebkitBoxOrient: "vertical",
			WebkitLineClamp: (this.props.lines || 1),
			whiteSpace: (this.props.preline ? "pre-line" : null),
			...this.props.style
		};
	}


	/**
	 * Get our `white-space` CSS property value.
	 * 
	 * @return {String} CSS
	 */
	get whitespace() {
		if (this.props.whiteSpace) return this.props.whiteSpace;
		else return (((this.props.lines || 1) === 1) ? "nowrap" : null);
	}


	/**
	 * Opacity when used as a caption
	 * 
	 * @type {Float}
	 */
	static opacity_caption = 0.6;

}

export default String;
