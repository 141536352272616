import React from "react";
import Container from "Components/Container.js";
import LabelTable from "Components/LabelTable.js";
import String from "Components/String.js";
import {TableCell, TableRow} from "@material-ui/core";

export default ({control, label, help}) => (
	<TableRow>
		<LabelTable>{label}</LabelTable>
		<TableCell>
			<Container justifyItems="flex-start">
				{control}
				<String str={help} />
			</Container>
		</TableCell>
	</TableRow>
);
