import Store from "./Store.js";
import Tasks from "./Tasks.js";

/**
 * Tasker
 *
 * @package HOPS
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Tasker {

	/**
	 * Active tasks
	 *
	 * @type {Object}
	 */
	static tasks = {};

	/**
	 * Run a task.
	 *
	 * This will start scheduled tasking for the task.
	 *
	 * You can run the task immediately by passing `true` to `immediate`.
	 *
	 * The task will continue to run at its scheduled interval thereafter.
	 *
	 * Calling this will always replace any existing timer for the task.
	 *
	 * Tasks are always executed using `taskRun()` - its constraints apply.
	 * 
	 * @param {String} task task name
	 * @param {Boolean} immediate optional Run immediately this time (`false`)
	 * @return {void}
	 */
	static task(task, immediate=false) {
		this.taskClear(task);
		const action = Tasks[task];
		const timeout = (immediate ? 1000 : (action.frequency || 1000));
		const timer = setTimeout(() => this.taskRun(task), timeout);
		this.tasks[task] = timer;
	}


	/**
	 * Run all tasks immediately.
	 * 
	 * @return {void}
	 */
	static taskAll() {
		Object.keys(Tasks).forEach(task => {
			this.task(task, true);
		});
	}


	/**
	 * Clear a named task's timeout.
	 *
	 * @param {String} task
	 * @return {void}
	 */
	static taskClear(task) {
		if (this.tasks[task]) clearTimeout(this.tasks[task]);
	}


	/**
	 * Clear all scheduled task runs.
	 *
	 * @return {void}
	 */
	static taskClearAll() {
		Object.keys(this.tasks).forEach(task => {
			this.taskClear(task);
		});
	}


	/**
	 * Run a task.
	 *
	 * Clears any timer for the task.
	 *
	 * Schedules the next run of the task using `task()`.
	 *
	 * This is intended to be called via `task()` for internal use only.
	 *
	 * Tasks are only executed if the device is registered and online.
	 *
	 * @param {String} task
	 * @return {void}
	 */
	static taskRun(task) {
		this.task(task);
		const action = Tasks[task];
		if ((this.registered || !action.registered) && navigator.onLine) {
			action.task();
		}
	}


	/**
	 * Get whether we're registered.
	 * 
	 * @return {Boolean}
	 */
	static get registered() {
		return (Store.getState().registrations.length > 0);
	}

}

export default Tasker;
