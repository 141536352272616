import React from "react";
import Navigator from "App/Navigator.js";
import View from "App/View.js";
import Button from "Components/Button.js";
import Container from "Components/Container.js";
import Paragraph from "Components/Paragraph.js";
import Strings from "Resources/Strings.json";
import TextForm from "Components/TextForm.js";
import {withRouter} from "react-router-dom";

/**
 * Checkpoint manual entry screen
 *
 * @package HOPS
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class CheckpointEntry extends React.Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Input value
			 *
			 * @type {String}
			 */
			value: ""

		};

		/**
		 * Method binds
		 */
		this.handleChange = this.handleChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);

	}


	/**
	 * Value changed.
	 * 
	 * @param {String} value
	 * @return {void}
	 */
	handleChange(value) {
		this.setState({value});
	}


	/**
	 * Handle submission.
	 *
	 * @param {Event|null} e optional
	 * @return {void}
	 */
	onSubmit(e=null) {
		if (e) e.preventDefault();
		this.props.onSubmit(this.state.value);
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<View label={Strings.checkpoint.entry.label}>
				<Container centred={true} fullWidth={true} my={4}>
					<TextForm
						autoFocus={true}
						label={Strings.checkpoint.entry.input}
						onChange={this.handleChange}
						onSubmit={this.onSubmit}
						required={true}
						value={this.state.value}>
					</TextForm>
				</Container>

				<Paragraph centre={true} p={Strings.checkpoint.entry.help} />

				<Container>
					<Button
						label="Cancel"
						onClick={Navigator.home}
						variant="outlined" />
				</Container>
			</View>
		);
	}

}

export default withRouter(CheckpointEntry);
