import React from "react";
import throttle from "lodash.throttle";
import Card from "Services/CardService";
import CameraService from "Services/CameraService.js";
import Button from "Components/Button.js";
import Container from "Components/Container.js";
import Navigator from "App/Navigator.js";
import Strings from "Resources/Strings.json";
import Styles from "Resources/Theme.json";
import View from "App/View.js";
import Workers from "Resources/Workers.json";
import withSnack from "Hoc/withSnack.js";
import * as Sentry from "@sentry/react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

/**
 * Checkpoint camera screen
 *
 * @package HOPS
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class CheckpointCamera extends React.Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Camera worker
		 *
		 * @type {WebWorker}
		 */
		this.cameraWorker = null;

		/**
		 * Canvas element
		 *
		 * @type {HTMLCanvasElement}
		 */
		this.canvas = null;

		/**
		 * Canvas context
		 *
		 * @type {CanvasRenderingContext2D}
		 */
		this.canvasContext = null;

		/**
		 * Animation frame ID
		 *
		 * @type {Integer}
		 */
		this.frame = null;

		/**
		 * Currently mounted
		 *
		 * @type {Boolean}
		 */
		this.mounted = false;

		/**
		 * Video reference
		 *
		 * @type {ReactRef}
		 */
		this.video = React.createRef();

		/**
		 * Throttle card error alerts
		 *
		 * @type {Function}
		 */
		this.displayCardError = throttle(this.displayCardError.bind(this), 5000);

		/**
		 * Manual entry redirect handler method bind
		 * 
		 * @type {Function}
		 */
		this.manual = this.manual.bind(this);

		/**
		 * Worker message handler method bind
		 *
		 * @type {Function}
		 */
		this.onWorkerMessage = this.onWorkerMessage.bind(this);

		/**
		 * Start QR scanning method bind
		 *
		 * @type {Function}
		 */
		this.startQrProcessing = this.startQrProcessing.bind(this);

		/**
		 * Update method bind
		 *
		 * @type {Function}
		 */
		this.update = this.update.bind(this);

	}


	/**
	 * Mounted – set up camera feed.
	 * 
	 * @return {void}
	 */
	componentDidMount() {

		/**
		 * Get ready
		 */
		this.mounted = true;

		/**
		 * Get the camera feed
		 */
		this.getCameraFeed().then(async video => {

			/**
			 * Start the feed
			 */
			if (!this.mounted) return;
			this.video.current.srcObject = video;
			await this.video.current.play();

			/**
			 * Create canvas
			 */
			this.canvas = this.createCanvas();
			this.canvasContext = this.canvas.getContext("2d", {willReadFrequently: true});

			/**
			 * Schedule QR analysis start
			 *
			 * (We defer to avoid immediately identification when re-render.)
			 */
			setTimeout(this.startQrProcessing, 50);

		}).catch(() => {
			this.props.snack(Strings.checkpoint.camera.error, "error");
			this.manual();
		});

	}


	/**
	 * Unmounting – stop the camera feed.
	 *
	 * @return {void}
	 */
	componentWillUnmount() {

		/**
		 * We're unmounting
		 */
		this.mounted = false;
		this.stopCameraWorker();

		/**
		 * Cancel frames
		 */
		if (this.frame) {
			cancelAnimationFrame(this.frame);
			this.frame = null;
		}

		/**
		 * Stop video playback
		 */
		if (this.video.current.srcObject) {

			this.video.current.srcObject.getTracks().forEach(t => {
				t.stop();
			});

			this.video.current.srcObject = null;

		}

	}


	/**
	 * Create a new canvas for the video to draw to.
	 *
	 * The canvas is used to get image data for QR scanning.
	 *
	 * @return {HTMLCanvasElement}
	 */
	createCanvas() {
		const canvas = document.createElement("canvas");
		const track = this.video.current.srcObject.getVideoTracks()[0];
		canvas.height = track.getSettings().height;
		canvas.width = track.getSettings().width;
		return canvas;
	}


	/**
	 * Display card error snackbar.
	 *
	 * @return {void}
	 */
	displayCardError() {
		this.props.snack(Strings.checkpoint.camera.invalid, "error");
	}


	/**
	 * Get the camera stream.
	 *
	 * We request the selected camera but will fallback to the default.
	 *
	 * @return {Promise}
	 */
	getCameraFeed() {
		return CameraService.getCameraStream(this.props.camera);
	}


	/**
	 * Get current image data from the video.
	 *
	 * @return {ImageData|null}
	 */
	getImageData() {

		const w = this.canvas.width;
		const h = this.canvas.height;
		const ctx = this.canvasContext;
		const video = this.video.current;

		if (ctx && video) {
			try {
				ctx.drawImage(video, 0, 0, w, h);
				return ctx.getImageData(0, 0, w, h);
			}
			catch (e) {
				Sentry.captureException(e);
				return null;
			}
		}
		else return null;

	}


	/**
	 * Card was scanned.
	 *
	 * Invokes the `onCard(...)` prop method passing the card ID.
	 *
	 * When the card is not valid, displays an error message.
	 *
	 * @param {mixed} data
	 * @return {Boolean} Card validity
	 */
	handleCard(data) {
		const id = Card.getCardIdFromCardData(data);
		const valid = (!!id);
		if (valid) this.props.onCard(id);
		return valid;
	}


	/**
	 * Manual entry navigation.
	 *
	 * @return {void}
	 */
	manual() {
		this.props.onWantsManualEntry();
	}


	/**
	 * Handle a QR scan result message received from the camera worker.
	 *
	 * When the scanned data is not a valid card, we schedule a new tick.
	 * 
	 * @param {Object} options.data QR data from `jsQR`, via the message
	 * @return {void}
	 */
	onWorkerMessage({data}) {
		let card = false;
		if (data) card = this.handleCard(data.data);
		if (!card) this.tick();
	}


	/**
	 * Camera QR scanner worker.
	 *
	 * This will replace any existing worker reference..
	 *
	 * @return {void}
	 */
	startCameraWorker() {
		this.cameraWorker = new Worker(Workers.camera);
		this.cameraWorker.addEventListener("message", this.onWorkerMessage);
	}


	/**
	 * Camera QR scanner worker termination.
	 *
	 * There will be no effect if the worker is not instantiated.
	 *
	 * @return {void}
	 */
	stopCameraWorker() {
		if (this.cameraWorker) {
			this.cameraWorker.removeEventListener("message", this.onWorkerMessage);
			this.cameraWorker.terminate();
			this.cameraWorker = null;
		}
	}


	/**
	 * Start video analysis for QR codes.
	 *
	 * There is no verification that we are in a valid state!
	 *
	 * @return {void}
	 */
	startQrProcessing() {
		this.startCameraWorker();
		this.tick();
	}


	/**
	 * Tick – get an animation frame to run the QR scan.
	 *
	 * The frame ID is stored as `this.frame` so it can be cancelled.
	 *
	 * @return {void}
	 */
	tick() {
		this.frame = requestAnimationFrame(this.update);
	}


	/**
	 * Update the active camera frame for QR scanning.
	 *
	 * The active image data is passed to the QR worker.
	 *
	 * This will have no effect if we're not mounted or there's no worker.
	 *
	 * @return {void}
	 */
	update() {
		if (!this.mounted || !this.cameraWorker || !this.cameraWorker.postMessage) return;
		else {
			const data = this.getImageData();
			if (data) this.cameraWorker.postMessage(data);
		}
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<View>
				<Container px={1} py={2} style={this.constructor.styles}>
					<video
						autoPlay={true}
						muted={true}
						playsInline={true}
						ref={this.video}
						style={this.stylesVideo}>
					</video>
				</Container>
				<Container
					gap={1}
					pb={2}
					style={this.constructor.stylesButtons}>
					<Button
						label="Cancel"
						onClick={Navigator.home}
						size="large"
						variant="outlined" />
					<Button
						label="Manual Login"
						onClick={this.manual}
						size="large" />
				</Container>
			</View>
		);
	}


	/**
	 * Background colour.
	 * 
	 * @type {String}
	 */
	static bgColor = "rgba(25,25,25)";

	/**
	 * Styles.
	 * 
	 * @type {Object}
	 */
	static styles = {
		alignItems: "center",
		background: this.bgColor,
		display: "flex",
		height: `calc(100% - ${Styles.Hops.barHeight})`,
		justifyContent: "center",
		left: 0,
		position: "fixed",
		top: Styles.Hops.barHeight,
		width: "100vw"
	};

	/**
	 * Button container styles.
	 *
	 * @type {Object}
	 */
	static stylesButtons = {
		bottom: `${((Number(Styles.App.spacing)) / 10)}rem`,
		left: "50%",
		position: "fixed",
		textAlign: "center",
		transform: "translateX(-50%)",
		zIndex: 1000
	};

	/**
	 * Video styles.
	 *
	 * @return {Object}
	 */
	get stylesVideo() {
		return {
			maxHeight: "100%",
			transform: (this.props.cameraMirror ? "scaleX(-1)" : "none"),
			width: "100%"
		};
	}

}

export default connect(({camera, cameraMirror}) => ({camera, cameraMirror}))(withRouter(withSnack(CheckpointCamera)));
