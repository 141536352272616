import State from "./State.js";
import StatePersistent from "./StatePersistent.js";
import Storage from "./Storage.js";

/**
 * Redux reducer
 *
 * @package HOPS
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Reducer {

	/**
	 * API URI updated.
	 *
	 * @param {Object} state
	 * @param {String} options.api
	 * @return {Object}
	 */
	static api(state, {api}) {
		return {...state, api};
	}


	/**
	 * App version updated.
	 *
	 * @param {Object} state
	 * @param {String} options.appv App version
	 * @return {Object}
	 */
	static appv(state, {appv}) {
		return {...state, appv, updated: true};
	}


	/**
	 * Clear sync queue reducer.
	 *
	 * @param {Object} state
	 * @return {Object}
	 */
	static clear(state) {
		return {...state, sync: []};
	}


	/**
	 * Dark mode toggle.
	 * 
	 * @param {Object} state
	 * @return {Object}
	 */
	static dark(state) {
		return {...state, dark: !state.dark};
	}


	/**
	 * DDPL tree reducer.
	 *
	 * @param {Object} state
	 * @param {String} options.id
	 * @param {Object} opions.ddpl
	 * @return {Object}
	 */
	static ddpl(state, {id, ddpl}) {
		const registrations = [...state.registrations];
		const reg = registrations.find(reg => (reg.id === id));
		if (reg) reg.ddplCache = ddpl;
		return {...state, registrations};
	}


	/**
	 * Flag toggled.
	 *
	 * @param {Object} state 
	 * @param {String} options.flag ID
	 * @return {Object}
	 */
	static flag(state, {flag}) {
		let flags = [...state.flags];
		if (!flags.includes(flag)) flags.push(flag);
		else flags = flags.filter(f => (f !== flag));
		return {...state, flags};
	}


	/**
	 * Checkpoint record reducer.
	 *
	 * @param {Object} state
	 * @param {Object} options.record
	 * @return {Object}
	 */
	static record(state, {record}) {
		const rec = {...record, timestamp: Math.floor(Date.now() / 1000)};
		return {...state, sync: state.sync.concat([rec])};
	}


	/**
	 * Last saved record changed.
	 *
	 * @param {Object} state
	 * @param {Object|null} options.record
	 * @return {Object}
	 */
	static recordLast(state, {record}) {
		return {...state, recordLast: record};
	}


	/**
	 * Pending record changed.
	 * 
	 * @param {Object} state
	 * @param {Object|null} options.record
	 * @return {Object}
	 */
	static recordPending(state, {record}) {
		return {...state, recordPending: record};
	}


	/**
	 * Refreshing device reducer.
	 *
	 * @param {Object} state
	 * @param {Boolean} refreshing Are we currently refreshing?
	 * @return {Object}
	 */
	static refreshing(state, {refreshing}) {
		return {...state, refreshing};
	}


	/**
	 * Registration reducer.
	 *
	 * @param {Object} state
	 * @param {Object} options.registration Registration object
	 * @return {Object}
	 */
	static register(state, {registration}) {
		return {...state, registrations: [...state.registrations, registration]};
	}


	/**
	 * Update the state of an existing registration object.
	 *
	 * @param {Object} state
	 * @param {String} options.id
	 * @param {Object} options.registration `undefined` values ignored
	 * @return {Object}
	 */
	static registrationUpdate(state, {id, registration}) {
		const registrations = [...state.registrations];
		const reg = registrations.find(reg => (reg.id === id));
		if (reg && registration) Object.assign(reg, registration);
		return {...state, registrations};
	}


	/**
	 * Reset state reducer – clears device.
	 * 
	 * @param {Object} state
	 * @return {Object}
	 */
	static reset(state) {
		return {
			...State,
			...StatePersistent,
			api: state.api,
			dark: state.dark,
			flags: state.flags,
			update: state.update,
			updated: state.updated
		};
	}


	/**
	 * Generic state setter.
	 *
	 * @param {Object} state Current state
	 * @param {Object} props Apply this state
	 * @return {Object}
	 */
	static set(state, props) {
		return {...state, ...props};
	}


	/**
	 * Sync finished.
	 *
	 * @param {Object} state
	 * @param {Array} options.pending Remaining sync queue contents
	 * @return {Object}
	 */
	static synced(state, {pending}) {
		const time = Math.floor((Date.now() / 1000));
		const updateTime = ((pending.length === 0) && (state.sync.length > 0));
		return {
			...state,
			sync: pending,
			syncing: false,
			syncTime: (updateTime ? time : state.syncTime)
		};
	}


	/**
	 * We're syncing!
	 *
	 * @param {Object} state
	 * @return {Object}
	 */
	static syncing(state) {
		return {...state, syncing: true};
	}


	/**
	 * Unregister a registration by its ID.
	 * 
	 * @param {Object} state
	 * @param {String} options.id
	 * @return {Object}
	 */
	static unregister(state, {id}) {
		const regs = state.registrations.filter(reg => (reg.id !== id));
		return {...state, registrations: regs};
	}


	/**
	 * Update available from server.
	 *
	 * @param {Object} state
	 * @param {String} options.update Available version string
	 * @return {Object}
	 */
	static update(state, {update}) {
		return {...state, update};
	}


	/**
	 * State reducer.
	 *
	 * @param {Object} state
	 * @param {Object} action
	 * @return {Object}
	 */
	static reduce(state, action) {
		if (Reducer.hasOwnProperty(action.type)) {
			state = Reducer[action.type](state, action);
		}
		Storage.save(state);
		return state;
	}

}

export default Reducer.reduce;
