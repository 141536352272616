/**
 * Camera service
 *
 * @package HOPS
 * @subpackage Services
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class CameraService {

	/**
	 * Get camera by device ID.
	 *
	 * Only media devices which are cameras will be matched.
	 *
	 * Resolves with an array of `MediaDeviceInfo` objects.
	 *
	 * @param {String} id Device ID
	 * @return {Promise}
	 */
	static getCamera(id) {
		return this.getCameras().then(c => c.filter(c => c.deviceId === id)[0]);
	}


	/**
	 * Get a camera stream.
	 *
	 * This attempts to get the stream from the given device ID.
	 *
	 * When no device ID is given, we try to get the stream from any 
	 * camera (this should be the first camera present on the device).
	 *
	 * @param {String|Integer} deviceId optional Device ID or camera index
	 * @return {Promise}
	 */
	static getCameraStream(deviceId=null) {
		return new Promise((resolve, reject) => {
			if (!navigator.mediaDevices) reject(null);
			this.matchCamera(deviceId).then(deviceId => {
				if (!deviceId) reject(null);
				const mtc = {video: {deviceId}};
				const media = navigator.mediaDevices.getUserMedia(mtc);
				media.then(v => resolve(v)).catch(e => reject(e));
			}).catch(e => reject(e));
		});
	}


	/**
	 * Get all cameras.
	 * 
	 * Resolves with an array of `MediaDeviceInfo` objects.
	 * 
	 * @return {Promise}
	 */
	static getCameras() {
		return new Promise((resolve, reject) => {
			if (!navigator.mediaDevices) reject(null);
			navigator.mediaDevices.enumerateDevices().then(devices => {
				const ids = [];
				const matches = [];
				const cameras = devices.filter(d => (d.kind === "videoinput"));
				cameras.forEach(camera => {
					if (!ids.includes(camera.deviceId)) {
						ids.push(camera.deviceId);
						matches.push(camera);
					}
				});
				resolve(matches);
			}).catch(e => reject(e));
		});
	}


	/**
	 * Attempt to get the `MediaDeviceInfo` object for a camera device ID.
	 *
	 * When the ID cannot be found, we use the default (first) camera instead.
	 *
	 * When the given ID is an integer, we return that camera index.
	 *
	 * @param {String|Integer} id Device ID or camera index
	 * @return {Promise}
	 */
	static matchCamera(id) {
		return new Promise((resolve, reject) => {

			let specific;
			if (!Number.isInteger(id)) {
				specific = this.getCamera(id);
			}
			else specific = this.getCameras().then(c => c[id]);

			specific.then(camera => {
				if (!camera) {
					this.getDefaultCamera().then(camera => {
						if (!camera) resolve(null);
						else resolve(camera.deviceId);
					}).catch(e => reject(e));
				}
				else resolve(camera.deviceId);
			}).catch(e => reject(e));

		});
	}


	/**
	 * Get the device's default camera.
	 *
	 * This is currently matched as the first camera on the device.
	 *
	 * This may be `undefined` if there is no camera device.
	 *
	 * @return {Promise}
	 */
	static getDefaultCamera() {
		return this.getCameras().then(cameras => cameras[0]);
	}

}

export default CameraService;
