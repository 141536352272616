import React from "react";
import Container from "Components/Container.js";
import Paragraph from "Components/Paragraph.js";
import Toggler from "Components/Toggler.js";
import CardRegistrationForm from "Cards/CardRegistrationForm.js";
import CardService from "Services/CardService.js";
import DeviceRegistrationForm from "Devices/DeviceRegistrationForm.js";
import RegistrationErrorDialog from "Registration/RegistrationErrorDialog.js";
import Registrations from "Resources/Registrations.json";
import Strings from "Resources/Strings.json";
import {ToggleButton} from "@material-ui/lab";

/**
 * Welcome home
 * 
 * @package HOPS
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class WelcomeHome extends React.Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Error code
			 *
			 * @type {Integer}
			 */
			error: null,

			/**
			 * Error state?
			 * 
			 * @type {Boolean}
			 */
			errorState: false,

			/**
			 * Device ID input
			 *
			 * @type {String}
			 */
			id: "",

			/**
			 * Card checkcode input
			 * 
			 * @type {String}
			 */
			checkcode: "",

			/**
			 * Registration mode
			 *
			 * @type {Integer} Registration code from `Registrations`
			 */
			mode: Registrations.device

		};

		/**
		 * Form reference
		 *
		 * @type {ReactRef}
		 */
		this.form = React.createRef();

		/**
		 * Method binds
		 */
		this.handleCheckcodeChanged = this.handleCheckcodeChanged.bind(this);
		this.handleErrorDialogClosed = this.handleErrorDialogClosed.bind(this);
		this.handleIdChanged = this.handleIdChanged.bind(this);
		this.handleModeChange = this.handleModeChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);

	}


	/**
	 * Component mounting.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		this.componentDidUpdate();
	}


	/**
	 * Component updating.
	 *
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (this.props !== prevProps) {
			this.setState({
				error: this.props.error,
				errorState: (this.props.error !== null)
			});
		}
	}


	/**
	 * Error dialog closed.
	 * 
	 * @return {void}
	 */
	handleErrorDialogClosed() {
		this.setState({errorState: false});
	}


	/**
	 * Handle mode change.
	 * 
	 * @param {Event} e Change event
	 * @param {String|null} mode Mode to change to (when set)
	 * @return {void}
	 */
	handleModeChange(e, mode) {
		if (mode) this.setState({id: "", mode});
	}


	/**
	 * Handle form submit.
	 *
	 * @param {Event|null} e optional
	 * @return {void}
	 */
	handleSubmit(e=null) {
		if (e) e.preventDefault();
		const id = CardService.getCardIdFromUiString(this.state.id);
		this.props.onSubmit(id, this.isDevice, this.state.checkcode);
	}


	/**
	 * Checkcode changed.
	 *
	 * @param {String} checkcode
	 * @return {void}
	 */
	handleCheckcodeChanged(checkcode) {
		this.setState({checkcode});
	}


	/**
	 * ID changed.
	 * 
	 * @param {String} id
	 * @return {void}
	 */
	handleIdChanged(id) {
		this.setState({id});
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Container gap={2}>
				<Toggler
					onChange={this.handleModeChange}
					value={this.state.mode}>
					<ToggleButton
						disabled={this.props.loading}
						value={Registrations.card}>
						{Strings.welcome.homet.card}
					</ToggleButton>
					<ToggleButton
						disabled={this.props.loading}
						value={Registrations.device}>
						{Strings.welcome.homet.device}
					</ToggleButton>
				</Toggler>

				{(this.isDevice ? this.renderDevice() : null)}
				{(!this.isDevice ? this.renderCard() : null)}

				<Paragraph p={this.strings.help} centre={true} />

				<RegistrationErrorDialog
					e401={(this.state.error === 401)}
					e404={(this.state.error === 404)}
					e409={(this.state.error === 409)}
					individualsMode={!this.isDevice}
					onClose={this.handleErrorDialogClosed}
					open={this.state.errorState} />
			</Container>
		);
	}


	/**
	 * Render card.
	 * 
	 * @return {ReactNode}
	 */
	renderCard() {
		return (
			<CardRegistrationForm
				checkcode={this.state.checkcode}
				enableTouchKeyboard={true}
				id={this.state.id}
				loading={this.props.loading}
				onChangeCheckcode={this.handleCheckcodeChanged}
				onChangeId={this.handleIdChanged}
				onSubmit={this.handleSubmit}
				withSubmit={true} />
		);
	}


	/**
	 * Render device.
	 * 
	 * @return {ReactNode}
	 */
	renderDevice() {
		return (
			<DeviceRegistrationForm
				enableTouchKeyboard={true}
				id={this.state.id}
				loading={this.props.loading}
				onChangeId={this.handleIdChanged}
				onSubmit={this.handleSubmit} />
		);
	}


	/**
	 * Get whether we're in device mode.
	 *
	 * We're in individuals mode otherwise.
	 *
	 * @return {Boolean}
	 */
	get isDevice() {
		return (this.state.mode === Registrations.device);
	}


	/**
	 * Get the strings for our current context.
	 * 
	 * @return {Object}
	 */
	get strings() {
		return (this.isDevice ? Strings.welcome.home : Strings.welcome.homei);
	}


	/**
	 * Button styles.
	 * 
	 * @type {Object}
	 */
	static btnStyles = {
		gridTemplateColumns: "max-content",
		justifyContent: "center"
	};

}

export default WelcomeHome;
