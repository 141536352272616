import React from "react";
import Container from "Components/Container.js";
import {CircularProgress} from "@material-ui/core";
import {Train as ErrorImage} from "@material-ui/icons";

/**
 * Image component
 *
 * An image with loading and error states.
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Image extends React.Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Error?
			 *
			 * @type {Boolean}
			 */
			error: false,

			/**
			 * Loading?
			 *
			 * @type {Boolean}
			 */
			loading: true

		};

		/**
		 * Method binds
		 */
		this.handleError = this.handleError.bind(this);
		this.handleLoad = this.handleLoad.bind(this);

	}


	/**
	 * Errored.
	 *
	 * @return {void}
	 */
	handleError() {
		this.setState({error: true, loading: false});
	}


	/**
	 * Loaded.
	 *
	 * @return {void}
	 */
	handleLoad() {
		this.setState({loading: false});
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (!this.state.error ? this.renderMain() : this.renderError());
	}


	/**
	 * Render error state.
	 * 
	 * @return {ReactNode}
	 */
	renderError() {
		return <ErrorImage color="primary" style={this.props.style} />;
	}


	/**
	 * Render the main content.
	 *
	 * @return {ReactNode}
	 */
	renderMain() {
		return (
			<Container justifyItems="center">
				<img
					alt={this.props.alt}
					onError={this.handleError}
					onLoad={this.handleLoad}
					src={this.props.src}
					style={this.props.style} />
				{(this.state.loading ? <div style={this.constructor.loaderStyles}><CircularProgress disableShrink={true} /></div> : null)}
			</Container>
		);
	}


	/**
	 * Loader styles.
	 * 
	 * @type {Object}
	 */
	static loaderStyles = {
		position: "absolute",
		left: "50%",
		top: "50%",
		transform: "translateX(-50%) translateY(-50%)"
	};

}

export default Image;
