import Store from "App/Store.js";
import refreshing from "Dispatchers/Refreshing.js";
import register from "Dispatchers/Register.js";
import registrationUpdate from "Dispatchers/RegistrationUpdate.js";
import ConflictError from "Registration/RegistrationConflictError.js";
import HopsService from "Services/HopsService.js";
import Registration from "Registration/Registration.js";
import Registrations from "Resources/Registrations.json";
import dayjs from "dayjs";

/**
 * Registration service
 * 
 * @package HOPS
 * @subpackage Services
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class RegistrationService {

	/**
	 * Register a card.
	 *
	 * @param {String} id Card ID
	 * @param {String} checkcode Card checkcode
	 * @return {Promise}
	 */
	static registerCard(id, checkcode) {
		return new Promise((resolve, reject) => {

			const se = (Store.getState().registrations.length > 0);

			if (this.isRegistered(id)) {
				reject(new ConflictError(id));
			}

			else {
				HopsService.getCard(id, checkcode, se).then(card => {
					register(this.createRegistrationUsingCardResponse(
						id, checkcode, card
					));
					HopsService.getDdpl(id, false).catch(() => null);
					resolve();
				}).catch(e => reject(e));
			}

		});
	}


	/**
	 * Register a device.
	 * 
	 * @param {String} id
	 * @return {Promise}
	 */
	static registerDevice(id) {
		return HopsService.getDevice(id).then(device => {
			register(this.createRegistrationUsingDeviceResponse(id, device));
			HopsService.getDdpl(id, true).catch(() => null);
		});
	}


	/**
	 * Refresh all registrations.
	 * 
	 * @return {void}
	 */
	static refresh() {

		/**
		 * Refreshing
		 */
		refreshing(true);

		/**
		 * Store calls
		 */
		const promises = [];

		/**
		 * Iterate our registrations
		 */
		Store.getState().registrations.forEach(r => {

			/**
			 * Get ready
			 */
			let p;
			const isDevice = (r.kind === Registrations.device);

			/**
			 * Make the call
			 */
			if (isDevice) {
				const {sync, syncTime} = Store.getState();
				p = HopsService.getDevice(r.id, sync.length, syncTime);
			}
			else p = HopsService.getCard(r.id, r.checkcode);

			/**
			 * Update the registration
			 */
			p.then(data => {

				let reg;

				/**
				 * Create `Registration`
				 */
				if (isDevice) {
					reg = this.createRegistrationUsingDeviceResponse(
						r.id, data
					);
				}
				else {
					reg = this.createRegistrationUsingCardResponse(
						r.id, r.checkcode, data
					);
				}

				/**
				 * Update registration in state
				 */
				registrationUpdate(r.id, reg);

			}).catch(() => null);

			/**
			 * Store the promise
			 */
			promises.push(p);

			/**
			 * Update DDPL tree
			 */
			promises.push(HopsService.getDdpl(r.id, isDevice).catch(() => null));

		});

		/**
		 * Wait to be done
		 */
		return Promise.all(promises).finally(() => refreshing(false));

	}


	/**
	 * Get whether a given registration object has expired.
	 * 
	 * @return {Boolean}
	 */
	static isExpired(reg) {
		const today = new dayjs((new dayjs()).format("YYYY-MM-DD"));
		return (reg.expiry && (new dayjs(reg.expiry) < today));
	}


	/**
	 * Get whether we already have a given ID registered.
	 * 
	 * @param {String} id
	 * @return {Boolean}
	 */
	static isRegistered(id) {
		return Store.getState().registrations.map(r => r.id).includes(id);
	}


	/**
	 * Create a `Registration` object using a card API response object.
	 *
	 * You must specify the card ID and checkcode relevant to the card object.
	 *
	 * @param {String} id
	 * @param {String} checkcode
	 * @param {Object} card
	 * @return {Registration}
	 */
	static createRegistrationUsingCardResponse(id, checkcode, card) {
		return new Registration({
			id,
			checkcode,
			name: card.user,
			organisation: card.organisation,
			organisationShort: card.organisation_short,
			expiry: card.expiry,
			logo: card.logo,
			notices: card.notices,
			ddpl: card.select_dept,
			ddplNone: card.select_dept_none,
			loneWorking: card.lone_working,
			kind: Registrations.card
		});
	}


	/**
	 * Create a `Registration` object using a device API response object.
	 *
	 * You must specify the device ID relevant to the device object.
	 *
	 * @param {String} id
	 * @param {Object} device
	 * @return {Registration}
	 */
	static createRegistrationUsingDeviceResponse(id, device) {
		return new Registration({
			id,
			checkcode: null,
			name: device.location,
			organisation: device.organisation,
			organisationShort: device.organisation_short,
			expiry: null,
			logo: device.logo,
			notices: device.notices,
			ddpl: device.select_dept,
			ddplNone: device.select_dept_none,
			site: device.site,
			kind: Registrations.device
		});
	}

}

export default RegistrationService;
