import React from "react";
import Container from "Components/Container.js";
import String from "Components/String.js";
import {List, ListSubheader} from "@material-ui/core";

export default ({heading}) => (
	<List disablePadding={true}>
		<ListSubheader>
			<Container pb={1}>
				<String
					centre={true}
					color="primary"
					str={heading}
					variant="h6" />
			</Container>
		</ListSubheader>
	</List>
);
