import React from "react";
import Button from "Components/Button.js";
import Container from "Components/Container.js";
import Strings from "Resources/Strings.json";
import TextField from "Components/TextField.js";
import withRegistration from "Hoc/withRegistration.js";
import * as mui from "@material-ui/core";

/**
 * Admin prompt for device password (device ID)
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Admin extends React.Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = this.constructor.initialState;

		/**
		 * Method binds
		 */
		this.onCancel = this.onCancel.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.update = this.update.bind(this);

	}


	/**
	 * Reset state
	 *
	 * @return {void}
	 */
	reset() {
		this.setState(this.constructor.initialState);
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<mui.Dialog
				disableEscapeKeyDown={this.props.disabled}
				disableScrollLock={true}
				onClose={this.onCancel}
				open={this.props.active}
				PaperProps={this.constructor.PaperProps}>

				<form onSubmit={this.onSubmit}>
					<mui.DialogTitle>
						{Strings.admin.label}
					</mui.DialogTitle>
					<mui.DialogContent>
						{
							[Strings.admin.message, ...(this.props.msg || [])].map((msg, key) => (
								<mui.DialogContentText key={key}>{msg}</mui.DialogContentText>
							))
						}
						<Container mb={0.25}>
							<TextField
								autoComplete={false}
								autoFocus={true}
								dense={true}
								enableTouchKeyboard={true}
								fullWidth={true}
								error={this.state.error}
								helperText={(this.state.error ? Strings.admin.error : null)}
								keyboardOffset={10}
								label={Strings.admin.label}
								onChange={this.update}
								onEnter={this.onSubmit}
								placeholderLabel={true}
								required={true}
								shrink={true}
								type="password"
								value={this.state.password} />
						</Container>
					</mui.DialogContent>
					<mui.DialogActions>
						<Button disabled={this.props.disabled} label={Strings.admin.cancel} onClick={this.onCancel} variant="text" />
						<Button disabled={this.props.disabled} label={Strings.admin.ok} type="submit" />
					</mui.DialogActions>
				</form>

			</mui.Dialog>
		);
	}


	/**
	 * Update password entry.
	 *
	 * @param {String} password
	 * @return {void}
	 */
	update(password) {
		this.setState({password: password.trim(), error: false});
	}


	/**
	 * Handle cancellation.
	 *
	 * @param {Event} e
	 * @param {String} reason
	 * @return {void}
	 */
	onCancel(e, reason) {
		if ((reason !== "backdropClick") || !this.props.disabled) {
			this.reset();
			this.props.onCancel();
		}
	}


	/**
	 * Handle submission.
	 *
	 * @param {Event|null} e optional
	 * @return {void}
	 */
	onSubmit(e=null) {
		if (e) e.preventDefault();
		if (this.state.password === this.props.registrations[0].id) {
			this.reset();
			this.props.onAuth();
		}
		else this.setState({error: true});
	}


	/**
	 * Initial state.
	 * 
	 * @type {Object}
	 */
	static initialState = {

		/**
		 * Input error
		 *
		 * @type {Boolean}
		 */
		error: false,

		/**
		 * Password input
		 *
		 * @type {String}
		 */
		password: ""

	};

	/**
	 * `PaperProps`
	 *
	 * @type {Object}
	 */
	static PaperProps = {elevation: 0};

}

export default withRegistration(Admin);
