import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import ErrorGate from "Views/ErrorGate.js";
import Host from "./Host.js";
import Snack from "./Snack.js";
import Store from "./Store.js";
import Tasker from "./Tasker.js";
import ThemeProvider from "./ThemeProvider.js";
import Ui from "./Ui.js";
import {ErrorBoundary} from "@sentry/react";
import {Provider as ReduxProvider} from "react-redux";

/**
 * App
 *
 * @package HOPS
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class App extends React.Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Online listener
		 * 
		 * @type {Function}
		 */
		this.online = () => Tasker.task("sync", true);

		/**
		 * Service worker registration
		 */
		if (this.constructor.production) Host.register();

	}


	/**
	 * Render the component.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<ErrorBoundary fallback={<ErrorGate />}>
				<ReduxProvider store={Store}>
					<ThemeProvider>
						<CssBaseline />
						<ErrorBoundary fallback={<ErrorGate />}>
							<Snack>
								<Ui />
							</Snack>
						</ErrorBoundary>
					</ThemeProvider>
				</ReduxProvider>
			</ErrorBoundary>
		);
	}


	/**
	 * Component mounted; set things up.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		Tasker.taskAll();
		window.addEventListener("online", this.online);
	}


	/**
	 * Component unmounting; perform clean-up.
	 *
	 * @return {void}
	 */
	componentWillUnmount() {
		Tasker.taskClearAll();
		window.removeEventListener("online", this.online);
	}


	/**
	 * Get whether we're in production.
	 *
	 * @return {Boolean}
	 */
	static get production() {
		return (process.env.NODE_ENV === "production");
	}

}

export default App;
