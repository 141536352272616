import React from "react";
import {connect} from "react-redux";

/**
 * `withTaskStatus()` HOC wrapper
 *
 * Wraps components with props providing details about background tasks.
 *
 * Refer to the source for implementation details.
 *
 * @package HOPS
 * @subpackage Hoc
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
const withTaskStatus = Component => {

	/**
	 * `ComponentWithTaskStatus` wrapper
	 *
	 * @package HOPS
	 * @subpackage Hoc
	 * @author Heron Web Ltd
	 * @copyright Heritage Operations Processing Limited
	 */
	const cwts = class ComponentWithTaskStatus extends React.Component {

		/**
		 * Render.
		 * 
		 * @return {ReactNode}
		 */
		render() {
			return <Component activeTasks={this.activeTasks} {...this.props} />;
		}


		/**
		 * Get whether we have any active tasks running.
		 * 
		 * @return {Boolean}
		 */
		get activeTasks() {
			return (this.props.refreshing || this.props.syncing);
		}

	};

	return connect(({refreshing, syncing}) => ({refreshing, syncing}))(cwts);

};

export default withTaskStatus;
