import React from "react";
import Button from "Components/Button.js";
import Container from "Components/Container.js";
import TextField from "Components/TextField.js";

/**
 * Text form
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class TextForm extends React.Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Input reference
		 *
		 * @type {ReactRef}
		 */
		this.input = React.createRef();

	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<form onSubmit={this.props.onSubmit} style={this.constructor.styles}>
				<Container columns="auto max-content" columnar={true}>
					<TextField
						autoComplete={false}
						autoFocus={this.props.autoFocus}
						disabled={this.props.disabled}
						enableTouchKeyboard={this.props.enableTouchKeyboard}
						fullWidth={!!this.props.children}
						helperText={this.props.helperText}
						inputRef={this.input}
						label={this.props.label}
						onChange={this.props.onChange}
						onEnter={this.props.onSubmit}
						required={this.props.required}
						style={this.stylesInput}
						type={this.props.type}
						value={this.props.value} />
					{this.props.children}
					<Button
						disabled={this.props.disabled}
						label={(this.props.labelBtn || "OK")}
						type="submit" />
				</Container>
			</form>
		);
	}


	/**
	 * Styles.
	 *
	 * @return {Object}
	 */
	static styles = {
		maxWidth: "60rem",
		width: "100%"
	};

}

export default TextForm;
