/**
 * Registration object
 *
 * @package HOPS
 * @subpackage Registration
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Registration {

	/**
	 * Constructor.
	 *
	 * @param {String|null} options.id
	 * @param {String|null} options.checkcode
	 * @param {String} options.name Registration name
	 * @param {String} options.organisation Organisation name
	 * @param {String} options.organisationShort Organisation name (short)
	 * @param {String|null} options.expiry YYYY-MM-DD expiry date
	 * @param {String|null} options.logo Absolute registration logo path
	 * @param {Boolean} options.notices Enable notices for this registration
	 * @param {Boolean} options.ddpl Enable DDPL for this registration
	 * @param {Object|null} options.ddplCache Cached DDPL tree (when `ddpl` `true`)
	 * @param {Boolean} options.ddplNone Enable DDPL department "none" option for this registration (when `ddpl` `true`)
	 * @param {Boolean} options.loneWorking Enable lone working options
	 * @param {Object|null} options.site Site object (device in site mode)
	 * @param {Integer} options.kind `Registrations` constant code
	 * @return {self}
	 */
	constructor({id, checkcode, name, organisation, organisationShort, expiry, logo, notices, ddpl, ddplCache, ddplNone, loneWorking, site, kind}) {

		/**
		 * ID
		 *
		 * @type {String|null}
		 */
		this.id = id;

		/**
		 * Checkcode
		 *
		 * @type {String|null}
		 */
		this.checkcode = checkcode;

		/**
		 * Registration name
		 *
		 * @type {String}
		 */
		this.name = name;

		/**
		 * Organisation name
		 *
		 * @type {String}
		 */
		this.organisation = organisation;

		/**
		 * Organisation name (short)
		 * 
		 * @type {String}
		 */
		this.organisationShort = organisationShort;

		/**
		 * Expiry date
		 *
		 * @type {String|null}
		 */
		this.expiry = expiry;

		/**
		 * Absolute registration logo path
		 *
		 * @type {String|null}
		 */
		this.logo = logo;

		/**
		 * Enable notices for this registration
		 * 
		 * @type {Boolean}
		 */
		this.notices = notices;

		/**
		 * Enable DDPL for this registration
		 * 
		 * @type {Boolean}
		 */
		this.ddpl = ddpl;

		/**
		 * Cached DDPL tree (only relevant when `ddpl` is `true`)
		 * 
		 * @type {Object|null}
		 */
		this.ddplCache = (ddplCache || null);

		/**
		 * Enable DDPL department `none` option (only relevant when `ddpl` is `true`)
		 *
		 * @type {Boolean}
		 */
		this.ddplNone = (ddplNone || false);

		/**
		 * Enable lone working options for this registration
		 *
		 * @type {Boolean}
		 */
		this.loneWorking = (loneWorking || false);

		/**
		 * Site object (when device in site mode)
		 *
		 * @type {Object|null}
		 */
		this.site = (site || null);

		/**
		 * `Registrations` constant code
		 * 
		 * @type {Integer}
		 */
		this.kind = kind;

	}

}

export default Registration;
