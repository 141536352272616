import React from "react";
import Theme from "App/Theme.js";
import {Box} from "@material-ui/core";

/**
 * Container
 *
 * A base grid container.
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Container extends React.Component {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Box {...this.forwards} style={this.styles}>
				{this.props.children}
			</Box>
		);
	}


	/**
	 * Get the `grid-template-columns` value to use.
	 * 
	 * @return {String} CSS
	 */
	get columns() {
		if (this.props.columns) return this.props.columns;
		else return (!this.props.columnar ? "100%" : null);
	}


	/**
	 * Get the `gap` value to use.
	 * 
	 * @return {Integer}
	 */
	get gap() {
		return ((this.props.gap !== undefined) ? this.props.gap : 1);
	}


	/**
	 * Get props to forward.
	 *
	 * @return {Object}
	 */
	get forwards() {
		const props = {};
		this.constructor.propForwards.forEach(prop => {
			if (this.props.hasOwnProperty(prop)) {
				props[prop] = this.props[prop];
			}
		});
		return props;
	}


	/**
	 * Styles.
	 * 
	 * @return {Object}
	 */
	get styles() {
		return {
			alignItems: this.props.alignItems,
			display: (this.props.display || "grid"),
			gap: Theme.spacing(this.gap),
			gridAutoFlow: (!this.props.columnar ? "row" : "column"),
			gridAutoRows: "max-content",
			gridTemplateColumns: this.columns,
			minHeight: (this.props.viewport ? "100vh" : null),
			justifyContent: this.props.justifyContent,
			justifyItems: (this.props.justifyItems || (this.props.centred ? "center" : null)),
			width: (this.props.fullWidth ? "100%" : null),
			...this.props.style
		};
	}


	/**
	 * Props we forward to our underlying `material-ui` `Box`.
	 * 
	 * @type {Array}
	 */
	static propForwards = [
		"className",
		"mb",
		"ml",
		"mr",
		"mt",
		"mx",
		"my",
		"pb",
		"pl",
		"pr",
		"pt",
		"px",
		"py"
	];

}

export default Container;
