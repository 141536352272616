import React from "react";
import rem from "Helpers/Rem.js";
import Anims from "Resources/Anims.json";
import {CheckCircle} from "@material-ui/icons";

/**
 * Check circle with animated "pop"
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class CheckCirclePop extends React.Component {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return <CheckCircle color="primary" style={this.constructor.styles} />;
	}


	/**
	 * Dimensions.
	 * 
	 * @type {String} CSS dimensions
	 */
	static dimensions = rem(5);


	/**
	 * Styles.
	 * 
	 * @type {Object}
	 */
	static styles = {
		animation: Anims.pop,
		height: this.dimensions,
		width: this.dimensions
	};

}

export default CheckCirclePop;
