import React from "react";
import CheckpointActions from "./CheckpointActions.js";
import CheckpointCamera from "./CheckpointCamera.js";
import CheckpointEntry from "./CheckpointEntry.js";
import Record from "Records/Record.js";
import RegisterService from "Services/RegisterService.js";
import Strings from "Resources/Strings.json";
import withRegistration from "Hoc/withRegistration.js";
import {withSnackbar} from "notistack";

/**
 * Checkpoint screen
 *
 * This is for device mode only.
 *
 * @package HOPS
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Checkpoint extends React.Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Card ID
			 *
			 * Manual entry if manual entry used!
			 *
			 * @type {String}
			 */
			card: null,

			/**
			 * Manual entry UI
			 * 
			 * @type {Boolean}
			 */
			manual: false,

			/**
			 * Card scanned
			 *
			 * @type {Boolean}
			 */
			scanned: false

		};

		/**
		 * Method binds
		 */
		this.onAction = this.onAction.bind(this);
		this.onActionError = this.onActionError.bind(this);
		this.onUserInput = this.onUserInput.bind(this);
		this.onUserInputManual = this.onUserInputManual.bind(this);
		this.onWantsManualEntry = this.onWantsManualEntry.bind(this);

	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		if (this.state.card) return this.renderActions();
		else if (!this.state.manual) return this.renderCamera();
		else return this.renderEntry();
	}


	/**
	 * Render actions UI.
	 * 
	 * @return {ReactNode}
	 */
	renderActions() {
		return (
			<CheckpointActions
				onAction={this.onAction}
				onError={this.onActionError}
				registration={this.props.registrations[0]}
				suggest={this.state.scanned}
				user={this.state.card} />
		);
	}


	/**
	 * Render camera UI.
	 * 
	 * @return {ReactNode}
	 */
	renderCamera() {
		return (
			<CheckpointCamera
				onCard={this.onUserInput}
				onWantsManualEntry={this.onWantsManualEntry} />
		);
	}


	/**
	 * Render manual entry UI.
	 * 
	 * @return {ReactNode}
	 */
	renderEntry() {
		return <CheckpointEntry onSubmit={this.onUserInputManual} />;
	}


	/**
	 * Actioned!
	 * 
	 * Time to start a new register transaction.
	 *
	 * @param {Object} record Record object for the API
	 * @param {String|null} username optional Name of user to acknowledge
	 * @return {void}
	 */
	onAction(record, username=null) {
		RegisterService.transact(
			new Record(
				record,
				this.props.activeRegistration,
				username
			)
		);
	}


	/**
	 * Error encountered while getting checkpoint action.
	 *
	 * The user's card is probably invalid.
	 *
	 * @return {void}
	 */
	onActionError() {
		this.props.history.push("/");
		this.props.enqueueSnackbar(Strings.checkpoint.general.cardError, {variant: "error"});
	}


	/**
	 * User ID input supplied.
	 *
	 * @param {String} card User ID, may be manually input
	 * @param {Boolean} scanned optional Whether card was scanned (`true`)
	 * @return {void}
	 */
	onUserInput(card, scanned=true) {
		this.setState({card, scanned});
	}


	/**
	 * User ID input supplied via manual entry.
	 * 
	 * @param {String} value
	 * @return {void}
	 */
	onUserInputManual(value) {
		this.onUserInput(value, false);
	}


	/**
	 * Manual entry wanted.
	 * 
	 * @return {void}
	 */
	onWantsManualEntry() {
		this.setState({manual: true});
	}

}

export default withRegistration(withSnackbar(Checkpoint));
