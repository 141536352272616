import "./index.scss";
import "array-flat-polyfill";
import App from "./App/App.js";
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";

if (process.env.REACT_APP_APP && !process.env.REACT_APP_DISABLE_SENTRY) {
	Sentry.init({
		dsn: "https://2d73017b5f266d21f81de6913517411e@o4507548472442880.ingest.de.sentry.io/4507549341319248",
		release: process.env.REACT_APP_APP
	});
}

ReactDOM.render(<App />, document.getElementById("react"));
