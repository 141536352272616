import React from "react";
import Alert from "./Alert.js";
import {sprintf} from "sprintf-js";

/**
 * "Card expired" warning banner
 * 
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class CardExpiryBanner extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Alert
				severity="error"
				str={
					sprintf(
						"Your %s%sID Card has expired. Please go to Settings by clicking the symbol in the top-right corner, de-register the card, and register your new one.",
						(this.props.hasMultipleCards ? this.props.registration.organisationShort : ""),
						(this.props.hasMultipleCards ? " " : ""),
					)
				}
				title="Card Expired" />
		);
	}

}

export default CardExpiryBanner;
