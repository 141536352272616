import React from "react";
import AsyncStatefulComponent from "Includes/AsyncStatefulComponent.js";
import Button from "Components/Button.js";
import Flex from "Components/Flex.js";
import HeroButton from "Components/HeroButton.js";
import HopsService from "Services/HopsService.js";
import RegisterService from "Services/RegisterService.js";
import String from "Components/String.js";
import Strings from "Resources/Strings.json";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import View from "App/View.js";
import WarningIcon from "@material-ui/icons/NewReleasesOutlined";
import scss from "./LoneWorking.module.scss";
import withMobile from "Hoc/withMobile.js";
import {SignIn as YesTheme, SignOut as NoTheme} from "App/Theme.js";

/**
 * Lone working view
 * 
 * @package HOPS
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class LoneWorking extends AsyncStatefulComponent {

	/**
	 * State
	 *
	 * @type {Object}
	 */
	state = {

		/**
		 * Error?
		 *
		 * @type {Boolean}
		 */
		error: false,

		/**
		 * Loading?
		 *
		 * @type {Boolean}
		 */
		loading: false

	};


	/**
	 * Component mounted.
	 *
	 * @return {void}
	 */
	componentDidMount() {

		/**
		 * Sign-in needed
		 */
		if (!RegisterService.pendingRecord?.record) {
			this.props.history.replace("/");
		}

	}


	/**
	 * User acknowledged an error scenario.
	 * 
	 * @return {void}
	 */
	handleAckError = () => this.constructor.transact((this.state.error ? 2 : 0));


	/**
	 * User answered "no".
	 *
	 * @return {void}
	 */
	handleNo = () => this.constructor.transact(1);


	/**
	 * User answered "yes".
	 *
	 * @return {void}
	 */
	handleYes = () => {

		/**
		 * Disable the UI
		 */
		this.setState({loading: true});

		/**
		 * Get the record
		 */
		const record = RegisterService.pendingRecord;

		/**
		 * Inform HOPS the user is lone working
		 */
		HopsService.initLoneWorking(record.registration).then(lwuid => {
			this.constructor.transact(2, lwuid);
		}).catch(() => {
			this.setState({error: true});
		}).finally(() => {
			this.setState({loading: false});
		});

	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {

		const online = navigator.onLine;

		return (
			<View gap={4} label={Strings.loneWorking.label}>
				{((online && !this.state.error) ? this.renderMain() : this.renderError(!this.state.error))}
			</View>
		);

	}


	/**
	 * Render the main state.
	 * 
	 * @return {ReactNode}
	 */
	renderMain() {
		return (
			<>
				<Flex columnar={true} gap={(!this.props.isMobile ? 2 : 1)}>
					<ThemeProvider theme={YesTheme}>
						<HeroButton
							disabled={this.state.loading}
							label="Yes"
							onClick={this.handleYes}
							small={true}
							theme={YesTheme} />
					</ThemeProvider>
					<ThemeProvider theme={NoTheme}>
						<HeroButton
							disabled={this.state.loading}
							label="No"
							onClick={this.handleNo}
							small={true}
							theme={NoTheme} />
					</ThemeProvider>
				</Flex>
				<String centre={true} str={Strings.loneWorking.caption} />
			</>
		);
	}


	/**
	 * Render the error state.
	 *
	 * @param {Boolean} offline optional Error due to offline state?
	 * @return {ReactNode}
	 */
	renderError(offline=false) {
		return (
			<>
				<WarningIcon
					className={scss.errorIcon}
					color="error"
					fontSize="large" />
				<String
					className={scss.error}
					centre={true}
					str={(!offline ? Strings.loneWorking.error : Strings.loneWorking.offline)} />
				<Button
					label={(offline ? "OK" : "I Understand")}
					onClick={this.handleAckError}
					size={(!offline ? "large" : undefined)} />
			</>
		);
	}


	/**
	 * Continue the record transaction with a given lone working value.
	 *
	 * @param {Integer} value
	 * @param {String} lwuid optional Lone working UUID to associate with
	 * @return {void}
	 */
	static transact(value, lwuid=undefined) {
		const record = RegisterService.pendingRecord;
		record.record.lone_working = value;
		record.record.lone_working_asked = 1;
		record.record.lone_working_uuid = (lwuid || null);
		RegisterService.transact(record);
	}

}

export default withMobile(LoneWorking);
