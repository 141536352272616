import React from "react";
import CardExpiryBanner from "Components/CardExpiryBanner.js";
import Container from "Components/Container.js";
import Divider from "@material-ui/core/Divider";
import Flex from "Components/Flex.js";
import HeroButton from "Components/HeroButton.js";
import Image from "Components/Image.js";
import SiteScreen from "./SiteScreen.js";
import String from "Components/String.js";
import Strings from "Resources/Strings.json";
import UpdateAlert from "Components/UpdateAlert.js";
import View from "App/View.js";
import PermissionService from "Services/PermissionService.js";
import RegistrationService from "Services/RegistrationService.js";
import dayjs from "dayjs";
import dRecordPending from "Dispatchers/RecordPending.js";
import uaParser from "ua-parser-js";
import withRegistration from "Hoc/withRegistration.js";
import scss from "./Home.module.scss";

/**
 * Homescreen
 *
 * @package HOPS
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Home extends React.Component {

	/**
	 * Clock update interval ID
	 *
	 * @type {Integer|null}
	 */
	clockInterval: null;

	/**
	 * State
	 * 
	 * @type {Object}
	 */
	state = {

		/**
		 * Current time
		 *
		 * @type {Integer|null}
		 */
		time: Date.now()

	};


	/**
	 * Component mounted.
	 * 
	 * @return {void}
	 */
	componentDidMount() {

		/**
		 * Clock updater
		 */
		this.clockInterval = setInterval(this.updateClock, 1000);

		/**
		 * Clear any pending record
		 *
		 * Prevents users from pressing back button to surface 
		 * any previous cancelled/time-out transaction!
		 */
		dRecordPending(null);

		/**
		 * Ensure correct URI
		 */
		this.props.history.replace("/");

		/**
		 * Collect our permissions
		 *
		 * (We disable the permissions screen on iOS due to #439 iOS 16 
		 * issues, and it doesn't work/won't appear anyway on <16 because 
		 * the permissions query API is missing from those versions.)
		 */
		if ((new uaParser(window.location.userAgent)).getOS().name !== "iOS") {
			PermissionService.hasAllPermissions().then(all => {
				if (!all) this.props.history.replace("/permissions");
			}).catch(() => {});
		}

	}


	/**
	 * Component unmounting.
	 *
	 * @return {void}
	 */
	componentWillUnmount() {
		if (this.clockInterval) {
			clearInterval(this.clockInterval);
		}
	}


	/**
	 * Clicked the start button.
	 *
	 * @param {Object} reg Registration object
	 * @return {void}
	 */
	handleStart = reg => {
		const uri = (this.props.isDevice ? "/checkpoint" : `/id/${reg.id}`);
		this.props.history.push(uri);
	};


	/**
	 * Update the clock time.
	 *
	 * @return {void}
	 */
	updateClock = () => {
		this.setState({time: Date.now()});
	};


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {

		const expiredCards = this.props.registrations.filter(reg => RegistrationService.isExpired(reg));

		return (
			<View
				fullWidth={this.props.isSiteMode}
				gap={(!this.props.isSiteMode ? 4 : 2)}
				label={this.label}
				top={this.props.isSiteMode}>
				<Flex display={(!expiredCards.length ? "none" : undefined)}>
					{
						expiredCards.map((reg, key) => {
							return (
								<CardExpiryBanner
									hasMultipleCards={(this.props.registrations.length > 1)}
									key={key}
									registration={reg} />
							);
						})
					}
				</Flex>
				<Container style={this.styles}>
					{
						this.props.registrations.map((reg, key) => {

							const isExpired = RegistrationService.isExpired(reg);

							return (
								<HeroButton
									caption={(!isExpired ? Strings.home.start.normal : Strings.home.start.cardExpired)}
									captionColor={(isExpired ? "error" : undefined)}
									disabled={isExpired}
									heroIcon={true}
									icon={this.constructor.renderLogo(reg)}
									key={key}
									onClick={this.handleStart}
									small={this.props.isSiteMode}
									value={reg} />
							);

						})
					}
				</Container>
				<String str={`Device Time: ${(new dayjs(this.state.time)).format("HH:mm:ss")}`} />
				{(this.props.isSiteMode && <Divider className={scss.divider} />)}
				{(this.props.isSiteMode && <SiteScreen />)}
				<UpdateAlert />
			</View>
		);

	}


	/**
	 * Render a registration's railway logo.
	 *
	 * @param {Object} reg Registration
	 * @return {ReactNode}
	 */
	static renderLogo(reg) {
		return <Image alt="" src={reg.logo} />;
	}


	/**
	 * Columns.
	 * 
	 * @return {Object}
	 */
	get columns() {
		if (this.props.registrations.length === 1) return undefined;
		else return "repeat(auto-fill, 30rem)";
	}


	/**
	 * Get the view label to use.
	 * 
	 * @return {String}
	 */
	get label() {
		if (this.props.isSiteMode) {
			return this.props.activeRegistration.site.name;
		}
		else if (this.props.registrations.length === 1) {
			return "Press Start to Begin";
		}
		else return "Select Organisation to Start";
	}


	/**
	 * Styles.
	 * 
	 * @return {Object}
	 */
	get styles() {
		return {
			gridTemplateColumns: this.columns,
			justifyContent: "center"
		};
	}

}

export default withRegistration(Home);
