import React from "react";
import AboutCardsTable from "./AboutCardsTable.js";
import AboutRefreshButton from "./AboutRefreshButton.js";
import AddCardDialog from "Cards/AddCardDialog.js";
import Button from "Components/Button.js";
import Container from "Components/Container.js";
import String from "Components/String.js";
import Strings from "Resources/Strings.json";
import withTaskStatus from "Hoc/withTaskStatus.js";

/**
 * About cards section
 *
 * @package HOPS
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class AboutCards extends React.Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 * 
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Add new card dialog
			 * 
			 * @type {Boolean}
			 */
			add: false

		};

		/**
		 * Methods
		 */
		this.handleAddCard = this.handleAddCard.bind(this);
		this.handleAddCardClose = this.handleAddCardClose.bind(this);

	}


	/**
	 * Adding a card.
	 * 
	 * @return {void}
	 */
	handleAddCard() {
		this.setState({add: true});
	}


	/**
	 * Close the card add dialog.
	 * 
	 * @return {void}
	 */
	handleAddCardClose() {
		this.setState({add: false});
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Container>
				<String str={Strings.about.cards.label} variant="h5" />
				<Container
					columnar={true}
					columns="repeat(2, max-content)"
					pt={1}
					px={1}>
					<Button
						disabled={this.props.activeTasks}
						label={Strings.about.cards.add}
						onClick={this.handleAddCard}
						variant="outlined" />
					<AboutRefreshButton />
				</Container>
				<Container>
					<AboutCardsTable />
				</Container>
				<AddCardDialog
					disabled={this.props.activeTasks}
					onClose={this.handleAddCardClose}
					open={this.state.add} />
			</Container>
		);
	}

}

export default withTaskStatus(AboutCards);
