import React from "react";
import {Select as MuiSelect} from "@material-ui/core";
import scss from "./Select.module.scss";

/**
 * Select component
 *
 * A wrapper around Material UI's `Select`.
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Select extends React.Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Method binds
		 */
		this.handleChange = this.handleChange.bind(this);

	}


	/**
	 * Value changed.
	 * 
	 * @param {Event} e
	 * @return {void}
	 */
	handleChange(e) {
		this.props.onChange(e.target.value);
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<MuiSelect
				classes={this.constructor.classes}
				disabled={this.props.disabled}
				MenuProps={this.constructor.MenuProps}
				onChange={this.handleChange}
				value={this.props.value}>
				{this.props.children}
			</MuiSelect>
		);
	}


	/**
	 * Classes.
	 * 
	 * @type {Object}
	 */
	static classes = {root: scss.Select};

	/**
	 * `MenuProps`.
	 * 
	 * @type {Object}
	 */
	static MenuProps = {disableScrollLock: true};

}

export default Select;
