import React from "react";
import Banner from "Components/Banner.js";
import Button from "Components/Button.js";
import QuestionIcon from "@material-ui/icons/HelpOutline";
import String from "Components/String.js";

/**
 * DDPL suggestion banner
 * 
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class DdplSuggestionBanner extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Banner
				icon={<QuestionIcon />}
				title="Are you here for this turn?">
				<div>
					<Button
						label={(this.props.buttonLabel || "Yes")}
						onClick={this.props.onSelect}
						size="large"
						variant="outlined" />
				</div>
				<String str="Suggested, based on today's roster." />
				<String str="Or select the department you're working for below." />
			</Banner>
		);
	}

}

export default DdplSuggestionBanner;
