import React from "react";
import {withSnackbar} from "notistack";

/**
 * `withError` HOC wrapper to wrap a component with `notistack`/helper methods
 *
 * @param {ReactComponent} Component Component to wrap
 * @return {ReactComponent} Wrapped component with authentication details
 */
const withSnack = Component => {

	/**
	 * Class wrapper
	 *
	 * @package HOPS
	 * @subpackage Hoc
	 * @author Heron Web Ltd
	 * @copyright Heritage Operations Processing Limited
	 */
	const cws = class ComponentWithSnack extends React.Component {

		/**
		 * Constructor.
		 *
		 * @param {Object} props
		 * @return {self}
		 */
		constructor(props) {
			super(props);

			/**
			 * Method binds
			 */
			this.snack = this.snack.bind(this);

		}


		/**
		 * Render.
		 *
		 * @return {ReactNode}
		 */
		render() {
			return <Component {...this.props} snack={this.snack} />;
		}


		/**
		 * Display a snackbar.
		 *
		 * @param {String} msg All values are automatically cast to string
		 * @param {String} variant optional See `notistack` (`default`)
		 * @param {Boolean} preventDuplicate optional See `notistack` (`true`)
		 * @return {void}
		 */
		snack(msg, variant="default", preventDuplicate=true) {
			if (msg instanceof Error) variant="error";
			this.props.enqueueSnackbar(`${msg}`, {variant, preventDuplicate});
		}

	};

	return withSnackbar(cws);

};

export default withSnack;
