import React from "react";
import Flex from "./Flex.js";
import String from "./String.js";
import {Switch as SwitchMui} from "@material-ui/core";

/**
 * Switch component
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Switch extends React.Component {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Flex alignItems="center" columnar={true}>
				<SwitchMui
					checked={this.props.checked}
					color={(this.props.color || "primary")}
					disabled={this.props.disabled}
					onClick={this.props.onChange}
					size={(this.props.size || "small")} />
				{(this.props.label && <String color="textSecondary" str={this.props.label} />)}
			</Flex>
		);
	}

}

export default Switch;
