import AppUpdateTask from "Tasks/AppUpdateTask.js";
import RefreshTask from "Tasks/RefreshTask.js";
import SyncTask from "Tasks/SyncTask.js";

export default {

	/**
	 * App updates
	 * 
	 * @type {Object}
	 */
	appv: {
		frequency: (450 * 1000),
		task: AppUpdateTask
	},

	/**
	 * Refresh device
	 * 
	 * @type {Object}
	 */
	refresh: {
		frequency: (10 * 60 * 1000),
		registered: true,
		task: RefreshTask
	},

	/**
	 * Sync the queue
	 * 
	 * @type {Object}
	 */
	sync: {
		frequency: (60 * 1000),
		registered: true,
		task: SyncTask
	}

};
